import styled from "styled-components";
import { media } from "../../../utils/mixins";
import { Link } from "react-router-dom";

export const CookiesContainer = styled.div`
 position: fixed;
 bottom: 20px;
 left: 50%;
 transform: translateX(-50%);

 display: flex;
 flex-direction: column;
 align-items: end;
 gap: 15px;

 z-index: 2;

 width: 90%;
 max-width: 1260px;
 padding: 15px;

 border: 2px solid ${(props) => props.theme.accentColor};
 border-radius: 10px;
 background-color: ${(props) => props.theme.secondaryBcg};
 box-shadow: 3px 3px 13px #605f5f;
 color: ${(props) => props.theme.color};

 & * {
  font-family: ${(props) => props.theme.secondaryFont};
 }

 ${media.laptop`
   max-width: 1260px;
   width: 1160px;
   margin: 0 auto;
 `}
`;

export const CookiesBtns = styled.div`
 display: flex;
 flex-direction: column;
 gap: 15px;
 max-width: 500px;
 width: 100%;

 ${media.laptop`
  flex-direction: row-reverse;
  justify-content: flex-end;
 `}
`;

export const CookiesLink = styled(Link)`
 text-decoration: underline;
 cursor: pointer;
`;
