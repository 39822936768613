import Notiflix from "notiflix";
import { contactUs } from "../../../utils/api/emails";
import {
 CloseIcon,
 InputBasic,
 ModalContainer,
 ModalOverflow,
} from "../../Common.styled";
import { ModalText } from "../../TryNow/TryNow.styled";
import { FooterModalBtn, FooterTextField } from "./ContactUs.styled";

function ContactUs({ close }) {
 async function sendEmail(e) {
  e.preventDefault();
  const { email, message } = e.target.elements;
  await contactUs({ email: email.value, message: message.value });
  close();
  Notiflix.Report.success("Message sent", "We will contact you soon!");
 }

 return (
  <ModalOverflow
   onClick={(e) => {
    if (e.target === e.currentTarget) close();
   }}
  >
   <ModalContainer>
    <ModalText>Contact us</ModalText>
    <form onSubmit={sendEmail}>
     <InputBasic name="email" placeholder="Email" required type="email" />
     <FooterTextField name="message" placeholder="Your message" required />
     <FooterModalBtn type="submit">Send</FooterModalBtn>
    </form>
    <CloseIcon onClick={close} />
   </ModalContainer>
  </ModalOverflow>
 );
}

export default ContactUs;
