import { useState } from "react";
import {
 HeroContainer,
 HeroTitle,
 AnimationContainer,
 HeroTitleDesktop,
 TitleAnimated,
} from "./Hero.styled";
import { useEffect } from "react";
import { BackgroundContainer } from "../Common.styled";

function Hero() {
 const [currentTitle, setCurrentTitle] = useState("");

 useEffect(() => {
  const titles = ["website", "WordPress", "WIX"];
  let title = 0;
  let letter = 0;
  let reverse = false;
  let pause = 100;
  setTitle();

  function setTitle() {
   pause = 100;
   setCurrentTitle(titles[title].slice(0, letter));
   if (reverse) {
    if (letter > 0) {
     letter -= 1;
    } else {
     pause = 600;
     reverse = false;
     if (title < titles.length - 1) {
      title += 1;
     } else {
      title = 0;
     }
    }
   } else {
    if (letter < titles[title].length) {
     letter += 1;
    } else {
     pause = 1000;
     reverse = true;
    }
   }
   setTimeout(setTitle, pause);
  }
 }, []);

 return (
  <BackgroundContainer>
   <HeroContainer>
    <AnimationContainer>
     <HeroTitle>A robot that</HeroTitle>
     <HeroTitle>generates and </HeroTitle>
     <HeroTitle>posts SEO content</HeroTitle>
     <HeroTitle>
      <TitleAnimated>to your {currentTitle} </TitleAnimated>
     </HeroTitle>
     <HeroTitleDesktop>A robot that generates </HeroTitleDesktop>
     <HeroTitleDesktop>and posts SEO content</HeroTitleDesktop>
     <HeroTitleDesktop>
      <TitleAnimated>to your {currentTitle}</TitleAnimated>
     </HeroTitleDesktop>
    </AnimationContainer>
   </HeroContainer>
  </BackgroundContainer>
 );
}

export default Hero;
