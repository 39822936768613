import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Windings';
    src: url('../assets/fonts/Windings-Regular-Font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


* {
 box-sizing: border-box;
 font-family: "IBM Plex Mono", monospace;
 padding: 0;
 margin: 0;
 transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
}

*::-webkit-scrollbar {
 width: 5px;
}

*::-webkit-scrollbar-track {
 background-color: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
 background-color: #635985;
}

*::-webkit-scrollbar-thumb:hover {
 background-color: #393053;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

input:not([type="range"]):focus,
textarea:focus,
select:focus,
button:focus {
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
 -webkit-appearance: none;
}

input[type="number"],
input[type="number"]:focus {
 -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

input[type="number"]:focus {
 -moz-appearance: textfield;
}

input[type="number"]:focus::-webkit-inner-spin-button,
input[type="number"]:focus::-webkit-outer-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
}

label {
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
 -webkit-appearance: none;
}

.notiflix-notify-failure {
 border: 2px solid red;
 justify-content: center;
}
.notiflix-notify-success {
 border: 2px solid #3dde3d;
 justify-content: center; 
}
.notiflix-notify-info {
 border: 2px solid #000dff;
 justify-content: center;
}
.notiflix-notify-warning {
 border: 2px solid orange;
 justify-content: center;
}
.notiflix-report-icon,
#NXReportInfo {
 display: none !important;
}
#NXReportButton.notiflix-report-button {
 border: 1px solid #000dff;
}

.nx-confirm-button-ok {
 border: 1px solid ${(props) => props.theme.accentColor};
}

.nx-confirm-button-cancel {
 border: 1px solid ${(props) => props.theme.outline};
}

.notiflix-confirm-head h5 {
 display: none;
}

.notiflix-confirm-buttons {
  display: flex;
}

.nx-confirm-button-ok, .nx-confirm-button-cancel {
  display: grid;
  place-items: center;
}

.nx-block-temporary-position {
 min-height: 48px !important;
 height: auto;
} 

.notiflix-report-content {
  // background-color: ${(props) => props.theme.background} !important;
  // border: 1px solid ${(props) => props.theme.outline} !important;

  border: 2px solid ${(props) => props.theme.accentColor} !important;
  background-color: ${(props) => props.theme.secondaryBcg} !important;
  box-shadow: 3px 3px 13px #605f5f;
  color: ${(props) => props.theme.color};
}

.notiflix-report-title, .notiflix-report-message {
  color: ${(props) => props.theme.color} !important;
}

@media screen and (max-width: 1280px) {
 #NotiflixNotifyWrap {
  width: 95% !important;
 }

 .desktop-only {
  display: none !important;
 }
}

@media screen and (min-width: 1280px) {
 .mobile-only {
  display: none !important;
 }
}


  .ql-toolbar * {
    color: ${(props) => props.theme.color} !important;
  }

  .ql-toolbar svg {
    fill: ${(props) => props.theme.color} !important;
  }

  .ql-snow .ql-stroke {
    stroke: ${(props) => props.theme.color} !important;
  }

  .ql-tooltip.ql-hidden {
    display: none;
  }

  .ql-toolbar + .ql-toolbar {
    display: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
 -webkit-box-shadow: 0 0 0 30px ${(props) =>
  props.theme.background} inset !important;
 box-shadow: 0 0 0 30px ${(props) => props.theme.background} inset !important;
}

input:-moz-autofill,
input:-moz-autofill-preview {
 -moz-box-shadow: 0 0 0px 1000px ${(props) => props.theme.background} inset;
 box-shadow: 0 0 0px 1000px ${(props) => props.theme.background} inset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:not([type="search"]):-webkit-autofill,
input:not([type="search"]):-webkit-autofill:hover,
input:not([type="search"]):-webkit-autofill:focus {
 -webkit-text-fill-color: ${(props) => props.theme.color};
 -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.background} inset;
 box-shadow: 0 0 0px 1000px ${(props) => props.theme.background} inset;
 transition: background-color 5000s ease-in-out 0s;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
 overflow-x: clip;
 scrollbar-width: thin;
 scrollbar-color: #18122b #f1f1f1;
 max-width: 100vw;
}

body, html {
    height: 100%;
    background-color: ${(props) => props.theme.background};
}

input:focus {
    outline-color: -webkit-focus-ring-color;
    outline: none;
}

 audio {
  background-color: #f1f3f4;
  border-radius: 30px;
  width: 300px;
  height: 40px;
  max-width: 300px;
  min-width:  300px;
 }

audio::-webkit-media-controls-panel {
  background-color: #f1f3f4;
  border-radius: 30px;
}


.react-joyride__tooltip button:focus {   outline: none; } 

`;


