import styled from "styled-components";

export const SquareCheckboxStyled = styled.input`
 appearance: none;

 &:disabled + span::after {
  cursor: not-allowed;
 }

 & + span {
  position: relative;
  display: inline-block;
  margin-right: 15px;

  &.translateX {
   transform: translateX(-4px);
  }

  &::after {
   content: "";

   position: absolute;
   top: 50%;
   left: 50%;

   display: block;

   width: 50%;
   height: 50%;

   background-color: ${(props) => props.theme.background};

   transform: translate(-50%, -55%);
   transition: opacity 200ms ease-in-out;
   cursor: pointer;
  }

  & img {
   display: block;
   width: 32px;
   height: 32px;
  }
 }

 &:checked + span::after {
  opacity: 0;
 }
`;
