import { BsDiscord, BsTwitter, BsFacebook } from "react-icons/bs";
import styled from "styled-components";
import { media } from "../../utils/mixins";
import { LoginInput } from "../../pages/ResetPassword/ResetPassword.styled";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
 display: flex;
 flex-direction: column;

 padding: 20px 18px 18px;

 color: ${(props) => props.theme.color};

 &.extra-padding {
  padding-block: 36px 56px 0;

  ${media.laptop`
  padding-block: 56px;
  padding-bottom: 10px;
  `}
 }

 ${media.laptop`
  align-items: center;
  flex-direction: row-reverse;

  max-width: 1280px;
  margin-inline: auto;
  padding-inline: 10px;
  padding-block: 56px;
  padding-bottom: 10px;
 `}
`;

export const SocialIcons = styled.div`
 display: flex;
 gap: 15px;
 align-items: center;

 ${media.laptop`
  order: 1;
  margin-right: 28px;
  gap: 29px;
 `}
`;

export const SocialMediaContainer = styled.div`
 display: flex;
 justify-items: center;
 align-items: center;
 gap: 15px;
 flex-direction: column;
 justify-content: space-between;
 margin-top: 37px;
 margin-bottom: 34px;

 ${media.mobile`
 flex-direction: row;
 `}

 ${media.laptop`
 margin: 0;
  `}
`;

export const Discord = styled(BsDiscord)`
 width: 36px;
 height: 36px;

 cursor: pointer;
 color: ${({ theme }) => theme.accentColor};
 transition: color 250ms ease-in-out;
`;

export const Twitter = styled(BsTwitter)`
 width: 36px;
 height: 36px;

 cursor: pointer;
 color: ${({ theme }) => theme.accentColor};
 transition: color 250ms ease-in-out;
`;

export const Facebook = styled(BsFacebook)`
 width: 36px;
 height: 36px;

 cursor: pointer;
 color: ${({ theme }) => theme.accentColor};
 transition: color 250ms ease-in-out;
`;

export const ProductHunt = styled.img`
 display: block;

 width: 204px;
 height: 44px;

 ${media.laptop`
    width: 194px;
    height: 48px
    `}
`;

export const FooterInput = styled(LoginInput)`
 width: 100%;
`;

export const SocialLinkIcon = styled.a`
 color: ${({ theme }) => theme.color};

 &.product-hunt {
  ${media.laptop`
  margin-right: 106px;
  `}
 }
`;

export const Copyright = styled.p`
 align-self: flex-start;

 min-width: 253px;

 color: ${(props) => props.theme.color};
 line-height: 1.6;
 font-size: 12px;

 ${media.laptop`
 align-self: center;
  margin-right: 80px;
 `}
`;

export const CopyrightLink = styled.a`
 color: ${(props) => props.theme.accentColor};
 text-decoration: underline;
`;

export const FooterBcg = styled.div`
 width: 100%;
 background-color: ${(props) => props.theme.tertiaryBcg};
`;

export const AccentText = styled(Link)`
 cursor: pointer;
 font-size: 12px;

 text-decoration: underline;
 font-family: ${({ theme }) => theme.secondaryFont};
 color: ${(props) => props.theme.color};
`;

export const DocumentsLinksContainer = styled.div`
 display: flex;
 justify-items: center;
 align-items: center;
 align-self: center;
 gap: 15px;
 flex-direction: row;
 min-width: 150px;
 padding: 5px 15px;
 flex-wrap: wrap;

 ${media.laptop`
padding: 15px 10px;
padding-bottom: 46px;
max-width: 1280px;
margin-inline: auto;
`}
`;
