import { useContext } from "react";
import {
 ChangeThemeBtnContainer,
 ChangeThemeDark,
 ChangeThemeLight,
 ChangeThemeSwitch,
} from "./ChangeThemeBtn.styled";
import { ThemeContext } from "styled-components";

function ChangeThemeBtn({ top, right, bottom, position }) {
 const { toggleTheme } = useContext(ThemeContext);
 return (
  <ChangeThemeBtnContainer
   onClick={toggleTheme}
   top={top}
   right={right}
   bottom={bottom}
   position={position}
  >
   <ChangeThemeSwitch />
   <ChangeThemeLight />
   <ChangeThemeDark />
  </ChangeThemeBtnContainer>
 );
}

export default ChangeThemeBtn;
