export function setErrorMessage(data) {
 if (data.detail) {
  if (
   data.detail.startsWith("duplicate key value violates unique constraint") ||
   data.detail.includes("Користувач з таким email вже існує.")
  ) {
   return "This email is already registered";
  } else if (data.detail.match(/string='([^']*)'/)) {
   return data.detail.match(/string='([^']*)'/)[1];
  } else {
   return data.detail;
  }
 } else if (data.message) {
  if (data.message.includes("duplicate key value violates unique constraint")) {
   return "This email is already registered";
  } else if (data.message.match(/string='([^']*)'/)) {
   return data.message.match(/string='([^']*)'/)[1];
  } else {
   return data.message;
  }
 } else {
  return "Unknown error";
 }
}
