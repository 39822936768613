import styled  from "styled-components";
import { media } from "../../utils/mixins";


export const AboutUsContainer = styled.div`
 display: grid;
 gap: 0px;
 margin-top: -5px;
 padding: 0 16px 0;
 text-align: left;

 background: ${({ theme }) => theme.background};

 ${media.laptop`
    max-width: 1280px;
    margin-inline: auto;
    padding-inline: 10px;
    padding-block: 0 61px;
    padding: 30px 16px 0;
  `}
`;

export const AboutUsTitle = styled.h1`
 width: auto;
 max-width: none;
 margin-bottom: 30px;
 margin-top: 0;
 font-size: 36px;
 line-height: 120%;
 color: ${({ theme }) => theme.color};
 margin-inline: auto;
 margin-bottom: 20px;

 font-weight: 300;
 transition: color 500ms ease-in-out;

 ${media.largeMobile`

  font-size: 18px;
  `}

 ${media.tablet`
    font-size: 20px;
  `}

 ${media.desktop`
    font-size: 24px;

text-align: center;
  `}
  ${media.laptop`
  margin-top: 30px;
  margin-inline: 0;
  font-size: 42px;
 `}
`;

export const AboutUsInfo = styled.div`
 position: relative;

 display: flex;
 flex-direction: column;
 gap: 14px;
 margin-bottom: 25px;

 background-color: ${({ theme }) => theme.background};

 color: ${({ theme }) => theme.color};
 font-size: 16px;
 line-height: 1.5;
 font-family: ${(props) => props.theme.secondaryFont};

 transition: all 0.3s ease-in-out;


 ${media.laptop`
 gap: 8px;
 line-height: 1.6;
 `}
`;


export const AboutUsTileH2 =styled.h2`
 display: flex;
 flex-direction: column;
 font-weight: 300;
 padding-top: 14px;
 padding-right: 16px;
 margin-bottom:5px;
 font-size: 26px;
 line-height: 1.3;

`;