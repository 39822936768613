import styled from "styled-components";
import { media } from "../../utils/mixins";
import { ReactComponent as Play } from "../../assets/img/lightbulb.svg";

export const VideosContainer = styled.div`
 ${media.laptop`
display: grid;
grid-template-columns: 1fr 2fr;
align-items: center;
column-gap: 5%;
 `}
`;

export const VideosDescription = styled.p`
 display: flex;
 flex-direction: column;
 gap: 8px;

 margin-bottom: 25px;

 font-family: ${(props) => props.theme.secondaryFont};
 line-height: 1.6;
 text-align: left;

 ${media.laptop`
 margin-bottom: 90px;
 `}

 & span {
  display: block;
  font-family: ${(props) => props.theme.mainFont};
  font-weight: 400;
  font-size: 26px;
  line-height: 1.3;
 }
`;

export const IFrameContainer = styled.div`
 overflow: hidden;
`;

export const Youtube = styled.iframe`
 width: 100.2%;
 margin-inline: auto;
 margin-bottom: 20px;
 transform: translate(-0.1%, -0.1%);

 aspect-ratio: 16 / 9;
 border: none;
 outline: none;
 border-radius: 12px;

 ${media.laptop`
 margin-bottom: 90px;
 `}
`;

export const PlayIcon = styled(Play)`
 width: 48px;
 height: 48px;

 color: ${(props) => props.theme.accentColor};
 stroke: ${(props) => props.theme.accentColor};
`;
