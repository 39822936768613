import Notiflix from "notiflix";
import axios from "../../utils/api/axios-config";
import {
 setPaymentMethods,
 setSecret,
 setSubscription,
 setTestAccounts,
} from "./stripeSlice";
import { testAccounts } from "../../data/testAccounts";

export const getClientSecret = (name) => async (dispatch) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 const test = testAccounts.includes(localStorage.getItem("email"));

 try {
  const response = await axios.post(
   "billing/payment/payment-intent/",
   {
    name: test ? "Test subscription" : name,
    test,
   },
   { headers }
  );
  dispatch(setPaymentMethods(response.data.payment_methods));
  dispatch(setSecret(response.data.client_secret));
 } catch (error) {
  Notiflix.Notify.failure(error.response.data.message);
 }
};

export const listPaymentMethods = () => async (dispatch) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 const test = testAccounts.includes(localStorage.getItem("email"));

 try {
  const response = await axios.post(
   "billing/payment/list-payment-methods/",
   { test },
   {
    headers,
   }
  );
  dispatch(setPaymentMethods(response.data.payment_methods));
 } catch (error) {
  if (error.response.data.message) {
   Notiflix.Notify.failure(error.response.data.message);
  }
 }
};

export const getSubscription = () => async (dispatch) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 const test = testAccounts.includes(localStorage.getItem("email"));

 try {
  const response = await axios.post(
   "billing/payment/subscription-status/",
   {
    test,
   },
   { headers }
  );
  dispatch(setSubscription(response.data));
 } catch (error) {}
};

export const getTestAccounts = () => async (dispatch) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  const response = await axios.get("users/stripe-test-accounts/", { headers });
  console.log(response);
  dispatch(setTestAccounts(response.data.map((el) => el.email)));
 } catch (error) {}
};
