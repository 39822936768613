import React, { forwardRef, useContext, useState } from "react";
import { OutlinedBtn } from "../Common.styled";
import {
 Copyright,
 CopyrightLink,
 Discord,
 FooterBcg,
 FooterContainer,
 ProductHunt,
 SocialIcons,
 SocialLinkIcon,
 SocialMediaContainer,
 Twitter,
 Facebook,
 AccentText,
 DocumentsLinksContainer,
} from "./Footer.styled";
import { useLocation } from "react-router";
import { ThemeContext } from "styled-components";
import { lightTheme } from "../../utils/themes";
import ContactUs from "../modals/ContactUs/ContactUs";

const Footer = forwardRef((_, ref) => {
 const [showModal, setShowModal] = useState(false);
 const { pathname } = useLocation();
 const { theme } = useContext(ThemeContext);

 const renderContent = () => {
  if (pathname === "/signup" && window.innerWidth < 1280) {
   return null;
  }

  return (
   <>
    <OutlinedBtn className="large" onClick={() => setShowModal(true)}>
     Contact us
    </OutlinedBtn>
    <SocialMediaContainer>
     <SocialIcons>
      <SocialLinkIcon
       href="https://discord.gg/JyStdQGzGB"
       target="_blank"
       name="discord"
       aria-label="our discord channel"
      >
       <Discord fontSize={40} />
      </SocialLinkIcon>
      <SocialLinkIcon
       href="https://twitter.com/Seoll_e"
       target="_blank"
       name="twitter"
       aria-label="our twitter account page"
      >
       <Twitter  />
      </SocialLinkIcon>
      <SocialLinkIcon
       href="https://www.facebook.com/profile.php?id=61554676048243"
       target="_blank"
       name="facebook"
       aria-label="our facebook account page"
      >
       <Facebook fontSize={40} />
      </SocialLinkIcon>
     </SocialIcons>
     
     <SocialLinkIcon
      href="https://www.producthunt.com/posts/seoll-e?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-seoll&#0045;e"
      target="_blank"
      className="product-hunt"
     >
      <ProductHunt
       src={`https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=537313&theme=${
        theme.background === lightTheme.background ? "neutral" : "dark"
       }`}
       alt="SEOLL&#0045;E - Automated&#0032;SEO&#0032;content&#0032;generation&#0032;and&#0032;publishing | Product Hunt"
       loading="lazy"
      />
     </SocialLinkIcon>
    </SocialMediaContainer>
   </>
  );
 };

 return (
  <FooterBcg>
   <FooterContainer
    ref={ref}
    className={pathname === "/signup" ? "extra-padding" : ""}
   >
    {renderContent()}

    <Copyright>
     Seoll-e, 2023
     <br />
     made in{" "}
     <CopyrightLink
      href="https://twitter.com/VMachineStudio"
      title="our twitter account"
      target="_blank"
     >
      Vending Machine Studio
     </CopyrightLink>
    </Copyright>

    {showModal && <ContactUs close={() => setShowModal(false)} />}
   </FooterContainer>
   <DocumentsLinksContainer>
    <AccentText to="/signup">Create an account</AccentText>{" "}
    <AccentText to="/terms-and-conditions">Terms of Service</AccentText>{" "}
    <AccentText to="/privacy-policy">Privacy Policy</AccentText>{" "}
    <AccentText to="/cookie-policy">Cookie Policy</AccentText>{" "}
    <AccentText to="/eula">License Agreement</AccentText>{" "}
   </DocumentsLinksContainer>
  </FooterBcg>
 );
});

export default Footer;
