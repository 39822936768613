import { useState } from "react";
import { BtnBasic, Hr, InputLabeled, Label, LabelText } from "../Common.styled";
import { validateEmail } from "../../utils/validation";
import Notiflix from "notiflix";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/user/userActions";
import {
 AppleIcon,
 GoogleIcon,
 MailIcon,
 SingInFormStyled,
} from "./Header.styled";
import { sendResetLink } from "../../utils/api/resetPassword";
import { LoginInputIconHidden, LoginInputIconShown } from "./Header.styled";
import SocialLoginBtn from "../../bricks/SocialLoginBtn";

function SignInForm({ triggerNavigate }) {
 const [showEmailSignIn, setShowEmailSignIn] = useState(false);
 const [email, setEmail] = useState("");
 const [showPassword, setShowPassword] = useState(false);
 const [password, setPassword] = useState("");
 const [showPasswordSymbols, setShowPasswordSymbols] = useState(false);

 const dispatch = useDispatch();

 function handleEmailLogin(e) {
  e.preventDefault();

  if (!email) {
   Notiflix.Notify.failure("Please enter your email");
   return;
  }

  const err = validateEmail(email);
  if (err) {
   Notiflix.Notify.failure(err);
   return;
  }

  if (!showPassword) {
   setShowPassword(true);
   return;
  }

  dispatch(loginUser({ email, password }));
  triggerNavigate();
 }

 function sendLink() {
  Notiflix.Confirm.show(
   "Reset Password",
   `Would you like to reset your password for ${email}?`,
   "Yes",
   "No",
   resetPassword
  );
 }

 async function resetPassword() {
  const linkSent = await sendResetLink(email);
  linkSent
   ? Notiflix.Report.success(
      "Password Reset",
      "Check you email for the password reset link",
      "Ok"
     )
   : Notiflix.Report.failure("Something went wrong", "Please try again", "Ok");
 }

 return (
  <>
   {showEmailSignIn ? (
    <SingInFormStyled autoComplete="on" onSubmit={handleEmailLogin}>
     <Label>
      <InputLabeled
       placeholder=" "
       autoComplete="username"
       value={email}
       onChange={(e) => setEmail(e.target.value)}
       required
       className="first-small"
       aria-label="Enter Email"
      />
      <LabelText>Email</LabelText>
     </Label>
     {showPassword && (
      <Label>
       <InputLabeled
        placeholder=" "
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        className="second-small"
        type={showPasswordSymbols ? "text" : "password"}
        aria-label="Enter Password"
        autoComplete="password"
       />
       <LabelText>Password</LabelText>
       {showPasswordSymbols ? (
        <LoginInputIconShown onClick={() => setShowPasswordSymbols(false)} />
       ) : (
        <LoginInputIconHidden onClick={() => setShowPasswordSymbols(true)} />
       )}
      </Label>
     )}
     <BtnBasic type="submit" className="large-font outlined">
      Sign in
     </BtnBasic>
     {showPassword && <BtnBasic onClick={sendLink}>Password Recovery</BtnBasic>}
     <Hr></Hr>
     <BtnBasic onClick={() => setShowEmailSignIn(false)} className="large-font">
      Go back
     </BtnBasic>
    </SingInFormStyled>
   ) : (
    <>
     <SocialLoginBtn
      icon={<GoogleIcon />}
      text="Sign in with Google"
      url="https://app.seoll-e.ai/accounts/google/login/"
      aria-label="Sign in with Google"
     />
     <SocialLoginBtn
      icon={<AppleIcon />}
      text="Sign in with Apple"
      url="https://app.seoll-e.ai/accounts/apple/login/"
      aria-label="Sign in with Apple"
     />
     <Hr></Hr>
     <BtnBasic onClick={() => setShowEmailSignIn(true)}>
      <MailIcon />
      Sign in with email
     </BtnBasic>
    </>
   )}
  </>
 );
}

export default SignInForm;
