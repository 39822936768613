import styled, { css } from "styled-components";
import { media } from "../../utils/mixins";
import { Btn, DesktopContainer, Text } from "../Common.styled";
import { Link } from "react-router-dom";
import { AiOutlineApple } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { GoMail } from "react-icons/go";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";

export const HeaderContainer = styled(DesktopContainer)`
 position: fixed;
 top: 0;
 left: 0;

 display: flex;
 align-items: center;
 justify-content: space-between;

 z-index: 11;

 width: 100%;
 padding: 12px 16px 21px;

 background-image: -webkit-gradient(
  linear,
  left top,
  left bottom,
  color-stop(70%, ${({ theme }) => theme.background}),
  to(transparent)
 );
 background-image: -webkit-linear-gradient(
  top,
  ${({ theme }) => theme.background} 70%,
  transparent
 );
 background-image: -moz-linear-gradient(
  top,
  ${({ theme }) => theme.background} 70%,
  transparent
 );
 background-image: -o-linear-gradient(
  top,
  ${({ theme }) => theme.background} 70%,
  transparent
 );
 background-image: linear-gradient(
  to bottom,
  ${({ theme }) => theme.background} 70%,
  transparent
 );

 color: ${({ theme }) => theme.color};

 font-weight: 300;

 ${media.laptop`
 position: relative;

 min-height: 80px;
 max-width: 1280px;
 margin-inline: auto;
 padding-inline: 10px;
 padding-block: 22px 12px;
 `}
`;

export const LogoIcon = styled.img`
 display: block;
 margin: 0;
 cursor: pointer;
`;

export const MobileMenuIcon = styled.img`
 width: 60px;
`;

export const MobileMenuOverflow = styled.div`
 position: fixed;
 top: 0;
 left: 0;

 width: 100%;
 height: 100%;

 transform: translateX(100%);
 transition: transform 500ms ease-in-out;

 &.show {
  transform: translateX(0);
 }

 ${media.laptop`
  display: none
 `}
`;

export const HamburgerBtn = styled.div`
 display: grid;
 place-items: center;

 width: 60px;
 height: 36px;

 cursor: pointer;

 color: ${({ theme }) => theme.color};

 ${media.laptop`
  display: none`};
`;

export const HeaderBtns = styled.div`
 display: flex;
 flex-direction: column;
 gap: 2px;

 ${media.laptop`
 flex-direction: row;
 gap: 30px;
 align-items: center;

 margin-left: auto;
 margin-right: 117px;
`}
`;

export const HeaderBtn = styled.p`
 padding: 10px;
 margin: 0;

 cursor: pointer;

 color: ${({ theme }) => theme.color};
 font-size: 20px;
 text-decoration: none;

 & a {
  text-decoration: none;
  color: ${(props) => props.theme.color};
 }
`;

export const HeaderBtnLink = styled(Link)`
 padding: 10px;
 margin: 0;

 cursor: pointer;

 color: ${({ theme }) => theme.color};
 font-size: 20px;
 text-decoration: none;
`;

export const SocialIcon = styled.img`
 width: 32px;
 height: 32px;
`;

export const AppleIcon = styled(AiOutlineApple)`
 font-size: 36px;
 color: ${(props) => props.theme.color};
 margin-inline: -2px;
`;

export const GoogleIcon = styled(FcGoogle)`
 font-size: 32px;
`;

export const MailIcon = styled(GoMail)`
 font-size: 32px;
 color: ${(props) => props.theme.color};
`;

export const HeaderSignUp = styled(Btn)`
 display: none;

 ${media.desktop`
 display: block;
 
 margin-inline: auto;
 `}
`;

export const TabletHeader = styled.div`
 display: none;

 ${media.laptop`
  display: flex;
  font-size: 18px;
 `}
`;

export const Description = styled.p`
 display: flex;
 flex-direction: column;
 gap: 20px;

 padding: 14px 0 0px;
 margin: 0;

 line-height: 1.5;
 font-size: 14px;
 font-family: ${({ theme }) => theme.secondaryFont};

 & ${Text} {
  font-family: ${({ theme }) => theme.secondaryFont};
 }

 & label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  transform: translateX(-3px);
 }

 &.full-size {
  margin-bottom: 13px;

  flex-direction: row;
  color: ${(props) => props.theme.colorSecondary};

  ${media.laptop`
    margin: 0;
  `}
 }

 ${media.laptop`
  font-size: 15px;
  padding: 0;
 `}
`;

export const AccentText = styled(Link)`
 cursor: pointer;

 text-decoration: underline;
 color: ${(props) => props.theme.color};
 font-family: ${({ theme }) => theme.secondaryFont};
`;

const LoginInputIcon = css`
 position: absolute;
 top: 25px;
 right: 25px;
 transform: translateY(-50%);
 font-size: 24px;
 cursor: pointer;
`;

export const LoginInputIconShown = styled(RxEyeOpen)`
 ${LoginInputIcon}
`;

export const LoginInputIconHidden = styled(RxEyeClosed)`
 ${LoginInputIcon}
`;

export const SingInFormStyled = styled.form`
 display: flex;
 flex-direction: column;
 gap: 20px;

 width: 100%;
`;
