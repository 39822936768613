export const languages = [
 "English",
 "French",
 "German",
 "Spanish",
 "Portuguese",
 "Italian",
];

export const allLanguages = [
 "Akan",
 "Amharic",
 "Arabic",
 "Assamese",
 "Awadhi",
 "Azerbaijani",
 "Balochi",
 "Bengali",
 "Bhojpuri",
 "Burmese",
 "Cebuano",
 "Chattisgarhi",
 "Chewa",
 "Chinese",
 "Chittagonian",
 "Czech",
 "Danish",
 "Deccan",
 "Dhundhari",
 "Dutch",
 "Eastern Min",
 "English",
 "Finnish",
 "French",
 "Fula",
 "Gan Chinese",
 "German",
 "Greek",
 "Gujarati",
 "Haitian Creole",
 "Hakka Chinese",
 "Haryanvi",
 "Hausa",
 "Hiligaynon",
 "Hindi",
 "Hmong",
 "Hungarian",
 "Igbo",
 "Ilocano",
 "Indonesian",
 "Italian",
 "Japanese",
 "Javanese",
 "Jin Chinese",
 "Kannada",
 "Kazakh",
 "Khmer",
 "Kinyarwanda",
 "Kirundi",
 "Konkani",
 "Korean",
 "Kurdish",
 "Madurese",
 "Magahi",
 "Malagasy",
 "Malay/Indonesian",
 "Malayalam",
 "Maithili",
 "Mandarin Chinese",
 "Marathi",
 "Marwari",
 "Mossi",
 "Nepali",
 "Northern Min",
 "Norwegian",
 "Odia (Oriya)",
 "Oromo",
 "Pashto",
 "Persian",
 "Polish",
 "Portuguese",
 "Punjabi",
 "Quechua",
 "Romanian",
 "Saraiki",
 "Serbo-Croatian",
 "Shona",
 "Sindhi",
 "Sinhala",
 "Somali",
 "Southern Min Chinese",
 "Spanish",
 "Sundanese",
 "Sylheti",
 "Swedish",
 "Tagalog",
 "Tamil",
 "Telugu",
 "Thai",
 "Turkish",
 "Turkmen",
 "Ukrainian",
 "Urdu",
 "Uyghur",
 "Uzbek",
 "Vietnamese",
 "Wu Chinese",
 "Xhosa",
 "Xiang Chinese",
 "Yoruba",
 "Zhuang",
 "Zulu",
];

export const languageToCountryCode = {
 Akan: "gh",
 Amharic: "et",
 Arabic: "sa",
 Assamese: "in",
 Awadhi: "in",
 Azerbaijani: "az",
 Balochi: "pk",
 Bengali: "in",
 Bhojpuri: "in",
 Burmese: "mm",
 Cebuano: "ph",
 Chattisgarhi: "in",
 Chewa: "mw",
 Chittagonian: "bd",
 ChineseSimplified: "cn",
 ChineseTraditional: "tw",
 Czech: "cz",
 Danish: "dk",
 Deccan: "in",
 Dhundhari: "in",
 Dutch: "nl",
 "Eastern Min": "cn",
 English: "us",
 Finnish: "fi",
 French: "fr",
 Fula: "ng",
 "Gan Chinese": "cn",
 German: "de",
 Greek: "gr",
 Gujarati: "in",
 "Haitian Creole": "ht",
 "Hakka Chinese": "cn",
 Haryanvi: "in",
 Hausa: "ng",
 Hiligaynon: "ph",
 Hindi: "in",
 Hmong: "cn",
 Hungarian: "hu",
 Igbo: "ng",
 Ilocano: "ph",
 Indonesian: "id",
 Italian: "it",
 Japanese: "jp",
 Javanese: "id",
 "Jin Chinese": "cn",
 Kannada: "in",
 Kazakh: "kz",
 Khmer: "kh",
 Kinyarwanda: "rw",
 Kirundi: "bi",
 Konkani: "in",
 Korean: "kr",
 Kurdish: "iq",
 Madurese: "id",
 Magahi: "in",
 Malagasy: "mg",
 "Malay/Indonesian": "id",
 Malayalam: "in",
 Maithili: "in",
 "Mandarin Chinese": "cn",
 Marathi: "in",
 Marwari: "in",
 Mossi: "bf",
 Nepali: "np",
 "Northern Min": "cn",
 Norwegian: "no",
 "Odia (Oriya)": "in",
 Oromo: "et",
 Pashto: "af",
 Persian: "ir",
 Polish: "pl",
 Portuguese: "pt",
 Punjabi: "in",
 Quechua: "pe",
 Romanian: "ro",
 Saraiki: "pk",
 "Serbo-Croatian": "rs",
 Shona: "zw",
 Sindhi: "pk",
 Sinhala: "lk",
 Somali: "so",
 "Southern Min Chinese": "cn",
 Spanish: "es",
 Sundanese: "id",
 Swedish: "se",
 Sylheti: "bd",
 Tagalog: "ph",
 Tamil: "in",
 Telugu: "in",
 Thai: "th",
 Turkish: "tr",
 Turkmen: "tm",
 Ukrainian: "ua",
 Urdu: "pk",
 Uyghur: "cn",
 Uzbek: "uz",
 Vietnamese: "vn",
 "Wu Chinese": "cn",
 Xhosa: "za",
 "Xiang Chinese": "cn",
 Yoruba: "ng",
 Zhuang: "cn",
 Zulu: "za",
};

export const commonLanguageCodes = [
 "eng",
 "spa",
 "fra",
 "deu",
 "ita",
 "nld",
 "por",
 "jpn",
 "kor",
 "ara",
 "tur",
 "swe",
 "pol",
 "ind",
 "fin",
 "dan",
 "nor",
 "ukr",
 "zho",
];

export const googleAdsLangCodes = [
 {
  languageName: "Arabic",
  languageCode: 1019,
 },
 {
  languageName: "Bengali",
  languageCode: 1056,
 },
 {
  languageName: "Bulgarian",
  languageCode: 1020,
 },
 {
  languageName: "Catalan",
  languageCode: 1038,
 },
 {
  languageName: "Chinese (simplified)",
  languageCode: 1017,
 },
 {
  languageName: "Chinese (traditional)",
  languageCode: 1018,
 },
 {
  languageName: "Croatian",
  languageCode: 1039,
 },
 {
  languageName: "Czech",
  languageCode: 1021,
 },
 {
  languageName: "Danish",
  languageCode: 1009,
 },
 {
  languageName: "Dutch",
  languageCode: 1010,
 },
 {
  languageName: "English",
  languageCode: 1000,
 },
 {
  languageName: "Estonian",
  languageCode: 1043,
 },
 {
  languageName: "Filipino",
  languageCode: 1042,
 },
 {
  languageName: "Finnish",
  languageCode: 1011,
 },
 {
  languageName: "French",
  languageCode: 1002,
 },
 {
  languageName: "German",
  languageCode: 1001,
 },
 {
  languageName: "Greek",
  languageCode: 1022,
 },
 {
  languageName: "Gujarati",
  languageCode: 1072,
 },
 {
  languageName: "Hebrew",
  languageCode: 1027,
 },
 {
  languageName: "Hindi",
  languageCode: 1023,
 },
 {
  languageName: "Hungarian",
  languageCode: 1024,
 },
 {
  languageName: "Icelandic",
  languageCode: 1026,
 },
 {
  languageName: "Indonesian",
  languageCode: 1025,
 },
 {
  languageName: "Italian",
  languageCode: 1004,
 },
 {
  languageName: "Japanese",
  languageCode: 1005,
 },
 {
  languageName: "Kannada",
  languageCode: 1086,
 },
 {
  languageName: "Korean",
  languageCode: 1012,
 },
 {
  languageName: "Latvian",
  languageCode: 1028,
 },
 {
  languageName: "Lithuanian",
  languageCode: 1029,
 },
 {
  languageName: "Malay",
  languageCode: 1102,
 },
 {
  languageName: "Malayalam",
  languageCode: 1098,
 },
 {
  languageName: "Marathi",
  languageCode: 1101,
 },
 {
  languageName: "Norwegian",
  languageCode: 1013,
 },
 {
  languageName: "Persian",
  languageCode: 1064,
 },
 {
  languageName: "Polish",
  languageCode: 1030,
 },
 {
  languageName: "Portuguese",
  languageCode: 1014,
 },
 {
  languageName: "Punjabi",
  languageCode: 1110,
 },
 {
  languageName: "Romanian",
  languageCode: 1032,
 },
 {
  languageName: "Serbian",
  languageCode: 1035,
 },
 {
  languageName: "Slovak",
  languageCode: 1033,
 },
 {
  languageName: "Slovenian",
  languageCode: 1034,
 },
 {
  languageName: "Spanish",
  languageCode: 1003,
 },
 {
  languageName: "Swedish",
  languageCode: 1015,
 },
 {
  languageName: "Tamil",
  languageCode: 1130,
 },
 {
  languageName: "Telugu",
  languageCode: 1131,
 },
 {
  languageName: "Thai",
  languageCode: 1044,
 },
 {
  languageName: "Turkish",
  languageCode: 1037,
 },
 {
  languageName: "Ukrainian",
  languageCode: 1036,
 },
 {
  languageName: "Urdu",
  languageCode: 1041,
 },
 {
  languageName: "Vietnamese",
  languageCode: 1040,
 },
];
