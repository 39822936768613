import React, { useContext, useEffect, useRef, useState } from "react";
import {
 AccentBtn,
 BackgroundContainer,
 CloseIcon,
 Copy,
 CopyContainer,
 InputLabeled,
 Label,
 LabelText,
 ModalContainer,
 ModalOverflow,
 OutlinedBtn,
} from "../Common.styled";
import {
 AIContainer,
 ArrowContainer,
 Arrow,
 BottomPostContainer,
 DefaultSetting,
 DefaultSettings,
 ExampleSettings,
 ExampleText,
 Examples,
 ExamplesRoller,
 IFrame,
 ModalText,
 OutputContainer,
 OverflownContainer,
 SeeMore,
 Semicircle,
 SemicircleContainer,
 SettingsBtn,
 SubHeader,
 Transparency,
 TryNowContainer,
 TryNowContainerDiv,
 TryNowForm,
 TryNowPost,
} from "./TryNow.styled";
import { AccentSetting } from "../InputSettings/InputSettings.styled";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { posts } from "../../data/mock";
import { CopyHTML, CopyHTMLContainer } from "../PostData/PostData.styled";
import CopyIcon from "../../assets/img/copy.svg";
import CopyIconLight from "../../assets/img/copy_light.svg";
import CopyHTMLIcon from "../../assets/img/copyHTML.svg";
import CopyHTMLIconLight from "../../assets/img/copyHTML_light.svg";
import CopyIconDesk from "../../assets/img/copyDesk.svg";
import CopyIconDeskLight from "../../assets/img/copyDesk_light.svg";
import CopyIconHTMLDesk from "../../assets/img/copyHTMLDesk.svg";
import CopyIconHTMLDeskLight from "../../assets/img/copyHTMLDesk_light.svg";
import Pagination from "../Pagination/Pagination";
import { handleScroll } from "../../pages/Home/Home";
import { ThemeContext } from "styled-components";
import { lightTheme } from "../../utils/themes";
import CopyModal from "../modals/CopyModal";
import { copyHTML, copyText } from "../../utils/copy";

const TryNow = () => {
 const navigate = useNavigate();
 const { theme } = useContext(ThemeContext);

 const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

 const [exampleNum, setExampleNum] = useState(0);
 const [showModal, setShowModal] = useState(false);
 const [showSettings, setShowSettings] = useState(false);
 const [showModalSettings, setShowModalSettings] = useState(false);
 const [showSection, setShowSection] = useState(false);
 const [showFullPost, setShowFullPost] = useState(false);

 const iframeRef = useRef(null);
 const seeMoreRef = useRef(null);

 const examplePosts = posts.slice(0, 6);

 async function generateText(e) {
  e.preventDefault();

  const { topic, keywords } = e.target.elements;
  if (topic.value) {
   localStorage.setItem(
    "demo-post",
    JSON.stringify({ topic: topic.value, keywords: keywords.value })
   );
   navigate("/signup", { state: { demo: true } });
  } else {
   navigate("/signup");
  }
 }

 function showNotification() {
  setShowModalSettings(true);
 }

 function copyPost(type, text) {
  if (!isAuthenticated) {
   setShowModal(true);
   return;
  }

  if (type === "html") {
   copyHTML(text);
   return;
  }

  if (type === "text") {
   copyText(text);
  }
 }

 useEffect(() => {
  if (iframeRef.current) {
   const originalSrc = iframeRef.current.src.split("?")[0];
   iframeRef.current.src = `${originalSrc}?theme=${theme.background}`;
  }
 }, [theme.background]);

 useEffect(() => {
  const done = localStorage.getItem("demo-post");
  if (done !== "done") {
   setShowSection(true);
  }
 }, []);

 return (
  <BackgroundContainer>
   {!isAuthenticated && showSection && (
    <TryNowContainer>
     <div>
      <TryNowForm onSubmit={generateText}>
       <Label>
        <InputLabeled
         name="topic"
         placeholder=" "
         className="first-input forever-focused"
        />
        <LabelText>Input a Topic</LabelText>
       </Label>
       <Label>
        <InputLabeled
         name="keywords"
         placeholder=" "
         className="second-input forever-focused"
        />
        <LabelText>Input Keywords and Links</LabelText>
       </Label>
       <TryNowContainerDiv>
        <AccentBtn type="submit">Try now</AccentBtn>
        <SubHeader>14-day free trial and packages from $10!</SubHeader>
       </TryNowContainerDiv>
      </TryNowForm>
      <DefaultSettings className={showSettings ? "show" : ""}>
       <SettingsBtn>
        <span>Settings</span>
        <ArrowContainer onClick={() => setShowSettings((prev) => !prev)}>
         <Arrow />
        </ArrowContainer>
       </SettingsBtn>
       <DefaultSetting>
        GPT version:{" "}
        <AccentSetting onClick={showNotification}>GPT-3.5</AccentSetting>
       </DefaultSetting>
       <DefaultSetting>
        Language:{" "}
        <AccentSetting onClick={showNotification}>English</AccentSetting>
       </DefaultSetting>
       <DefaultSetting>
        Style: <AccentSetting onClick={showNotification}>Formal</AccentSetting>
       </DefaultSetting>
       <DefaultSetting>
        AI likelihood:{" "}
        <AccentSetting onClick={showNotification}>AI-like text</AccentSetting>
       </DefaultSetting>
       <DefaultSetting>
        Post length:{" "}
        <AccentSetting onClick={showNotification}>1500 words</AccentSetting>
       </DefaultSetting>
       <DefaultSetting>
        External links:{" "}
        <AccentSetting onClick={showNotification}>Disabled</AccentSetting>
       </DefaultSetting>
       <DefaultSetting>
        Image model:{" "}
        <AccentSetting onClick={showNotification}>
         Realistic Vision 2.0
        </AccentSetting>
       </DefaultSetting>
       <DefaultSetting>
        Image style:{" "}
        <AccentSetting onClick={showNotification}>Realistic</AccentSetting>
       </DefaultSetting>
       <DefaultSetting>
        Image size:{" "}
        <AccentSetting onClick={showNotification}>1:1 (720x720)</AccentSetting>
       </DefaultSetting>
      </DefaultSettings>
     </div>
    </TryNowContainer>
   )}
   <ExampleText>Examples:</ExampleText>
   <Pagination
    total={examplePosts.length}
    activeIndex={exampleNum}
    setActiveIndex={setExampleNum}
   />
   <Examples>
    <ExamplesRoller num={exampleNum}>
     {examplePosts.map((post) => (
      <OutputContainer key={post.id}>
       <TryNowPost
        className={`${showFullPost ? "full" : ""} ${
         isAuthenticated ? "" : "disable-copy"
        }`}
        postHeight={posts[exampleNum].post_height}
        postHeightDesktop={posts[exampleNum].post_height_desktop}
       >
        <ExampleSettings>
         {post.settings.map((el) => (
          <li key={post.id + Object.keys(el)[0]}>
           {Object.keys(el)[0]}: {Object.values(el)[0]}
          </li>
         ))}
        </ExampleSettings>
        <Transparency></Transparency>
        <div onClick={() => copyPost()}>
         <IFrame
          className={isAuthenticated ? "" : "disable-copy"}
          loading="lazy"
          src={`/example/${post.id}?theme=${theme.background}`}
          title="Embedded Article"
          ref={iframeRef}
          height={
           window.innerWidth > 1280
            ? posts[exampleNum].post_height_desktop
            : posts[exampleNum].post_height * (window.innerWidth - 20)
          }
          width={window.innerWidth > 1280 ? 1200 : window.innerWidth - 40}
          scrolling="no"
          frameBorder="none"
         ></IFrame>
        </div>
        <BottomPostContainer marginTop={"80px"} className="example">
         <div>
          <CopyHTMLContainer
           onClick={() => copyPost("html", post.info)}
           className="mobile"
          >
           <CopyHTML
            src={
             theme.background === lightTheme.background
              ? CopyHTMLIcon
              : CopyHTMLIconLight
            }
            alt="Copy HTML icon, for easy duplication of SEO-friendly web content."
            loading="lazy"
            width="32"
            height="32"
           />
          </CopyHTMLContainer>
          <CopyHTMLContainer onClick={() => copyPost("html", post.info)}>
           <CopyHTML
            src={
             theme.background === lightTheme.background
              ? CopyIconHTMLDesk
              : CopyIconHTMLDeskLight
            }
            alt="Copy HTML icon, for easy duplication of SEO-friendly web content."
            loading="lazy"
            width="32"
            height="32"
           />
          </CopyHTMLContainer>
          <CopyContainer
           onClick={() => copyPost("text", post.info)}
           className="mobile"
          >
           <Copy
            src={
             theme.background === lightTheme.background
              ? CopyIcon
              : CopyIconLight
            }
            alt="Copy icon, enabling text duplication for efficient SEO content management."
            loading="lazy"
            width="32"
            height="32"
           />
          </CopyContainer>
          <CopyContainer onClick={() => copyPost("text", post.info)}>
           <Copy
            src={
             theme.background === lightTheme.background
              ? CopyIconDesk
              : CopyIconDeskLight
            }
            alt="Copy icon, enabling text duplication for efficient SEO content management."
            loading="lazy"
            width="32"
            height="32"
           />
          </CopyContainer>
         </div>
         {post.score !== undefined && (
          <>
           <AIContainer>
            AI likelihood:
            <OverflownContainer>
             <SemicircleContainer
              color={
               post.score > 80
                ? "pink"
                : post.score > 50
                ? "yellow"
                : "lightgreen"
              }
              percent={post.score / 2 + "%"}
             >
              <Semicircle>{post.score}%</Semicircle>
             </SemicircleContainer>
            </OverflownContainer>
           </AIContainer>
          </>
         )}
        </BottomPostContainer>
       </TryNowPost>
       <SeeMore
        ref={seeMoreRef}
        onClick={() => {
         setShowFullPost((prev) => !prev);
         if (showFullPost) {
          setTimeout(() => handleScroll(seeMoreRef), 300);
          setTimeout(() => handleScroll(seeMoreRef), 600);
          if (
           /iPad|iPhone|iPod/.test(navigator.userAgent) &&
           !window.MSStream
          ) {
           setTimeout(() => handleScroll(seeMoreRef), 800);
          }
         }
        }}
        className={showFullPost ? "rotate" : ""}
       >
        See {showFullPost ? "less" : "more"}
        <ArrowContainer aria-label="Show full example post">
         <Arrow />
        </ArrowContainer>
       </SeeMore>
      </OutputContainer>
     ))}
    </ExamplesRoller>
   </Examples>
   {showModal && (
    <CopyModal
     close={() => {
      setShowModal(false);
     }}
    />
   )}
   {showModalSettings && (
    <ModalOverflow
     onClick={(e) => {
      if (e.target === e.currentTarget) setShowModalSettings(false);
     }}
    >
     <ModalContainer>
      <ModalText>
       Create a free account and try adjusting settings for your needs with 200
       free credits!
      </ModalText>
      <OutlinedBtn className="small-width" onClick={() => navigate("/signup")}>
       Sign up
      </OutlinedBtn>
      <CloseIcon onClick={() => setShowModalSettings(false)} />
     </ModalContainer>
    </ModalOverflow>
   )}
  </BackgroundContainer>
 );
};

export default TryNow;
