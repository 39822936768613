import React, { useState } from "react";
import {
 PaginationWrapper,
 PageNumber,
 NumberIndicator,
 Arrow,
 ArrowHalf,
} from "./Pagination.styled";
import { useEffect } from "react";

const Pagination = ({ total, activeIndex, setActiveIndex }) => {
 const [activeHover, setActiveHover] = useState(0);

 useEffect(() => {
  setActiveHover(activeIndex);
 }, [activeIndex]);

 return (
  <PaginationWrapper>
   <NumberIndicator
    isActive={activeHover === activeIndex}
    num={activeHover + 1}
    total={total}
   />
   <Arrow
    disabled={activeIndex === 0}
    onClick={() => setActiveIndex((prev) => prev - 1)}
    aria-label="previous page"
   >
    <ArrowHalf />
    <ArrowHalf />
   </Arrow>
   {Array.from({ length: total }).map((_, i) => (
    <PageNumber
     key={i}
     isActive={i === activeIndex}
     onClick={() => setActiveIndex(i)}
     onMouseEnter={() => setActiveHover(i)}
     onMouseLeave={() => setActiveHover(activeIndex)}
    >
     {i + 1}
    </PageNumber>
   ))}
   <Arrow
    className="right"
    disabled={activeIndex === total - 1}
    onClick={() => setActiveIndex((prev) => prev + 1)}
    aria-label="previous page"
   >
    <ArrowHalf />
    <ArrowHalf />
   </Arrow>
  </PaginationWrapper>
 );
};

export default Pagination;
