import styled, { keyframes } from "styled-components";
import { media } from "../../utils/mixins";

export const HeroContainer = styled.div`
 position: relative;

 width: 100vw;
 padding-top: 100px;
 padding-bottom: 15px;
 padding-inline: 18px;

 z-index: 1;

 background-color: ${({ theme }) => theme.background};

 color: ${(props) => props.theme.color};

 transition: all 500ms ease-in-out;

 ${media.laptop`
  max-width: 1280px;
  padding-inline: 10px;
  margin-inline: auto; 
  padding-top: 73px;
 `}
`;

const blinkCursor = keyframes`
  50% { border-color: transparent; }
`;

export const AnimationContainer = styled.div`
 max-width: 840px;
 margin-bottom: 35px;
`;

export const TitleAnimated = styled.span`
 display: inline-block;

 overflow: hidden;

 border-right: 4px solid ${({ theme }) => theme.accentColor};
 padding-right: 10px;
 min-width: 150px;

 line-height: 1.3;

 animation: ${blinkCursor} 1s step-end infinite;

 ${media.laptop`
 min-width: 270px;
 `}
`;

export const HeroTitle = styled.p`
 display: flex;
 align-items: center;
 gap: 23px;

 margin: 0;

 line-height: 1.3;
 white-space: nowrap;
 color: ${({ theme }) => theme.color};
 font-size: 30px;

 ${media.mobile`
 font-size: 30px;
 `}

 ${media.laptop`
    display: none
  `}
`;

export const HeroTitleDesktop = styled(HeroTitle)`
 display: none;
 font-size: 56px;

 ${media.laptop`
    display: block
  `}
`;
