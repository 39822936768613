import { OutlinedBtn } from "../Common.styled";
import { Star, StarRating } from "../modals/Feedback/Feedback.styled";
import { Review, ReviewText, ReviewsContainer } from "./Reviews.styled";
import Stats from "./Stats";

function Reviews() {
 return (
  <ReviewsContainer>
   <Stats />
   <Review>
    <StarRating>
     {[...Array(5)].map((_, i) => (
      <Star key={i} selected>
       ★
      </Star>
     ))}
    </StarRating>
    <ReviewText>
     ... Easy 5 stars because it is much better AND cheaper than most of the
     tools out there and I think I have tried almost all of them.
    </ReviewText>
   </Review>
   <Review>
    <StarRating>
     {[...Array(3)].map((_, i) => (
      <Star key={i} selected>
       ★
      </Star>
     ))}
     {[...Array(2)].map((_, i) => (
      <Star key={i}>★</Star>
     ))}
    </StarRating>
    <ReviewText>
     ... Most of the detectors (like, openAI Classifier) consider the generated
     article to be human generated.
    </ReviewText>
   </Review>
   <Review>
    <StarRating>
     {[...Array(5)].map((_, i) => (
      <Star key={i} selected>
       ★
      </Star>
     ))}
    </StarRating>
    <ReviewText>
     Its look like wall-e 😅 but jokes apart this is nice concept.
    </ReviewText>
   </Review>
   <OutlinedBtn
    onClick={() => {
     window.open("https://www.producthunt.com/products/seoll-e/reviews/new");
    }}
   >
    Leave a review
   </OutlinedBtn>
  </ReviewsContainer>
 );
}

export default Reviews;
