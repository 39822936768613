import React, { useRef } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Info from "../../components/Info/Info";
import Pricing from "../../components/Pricing/Pricing";
import TryNow from "../../components/TryNow/TryNow";
import { HomeContainer } from "./Home.styled";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import AboutUs from "../../components/AboutUs/AboutUs";
import FAQ from "../../components/FAQ/FAQ";
import { faqGenerate } from "../../data/faq";
import { Helmet } from "react-helmet";
let infoRef, pricingRef, footerRef, faqRef;

function Home() {
 infoRef = useRef(null);
 pricingRef = useRef(null);
 footerRef = useRef(null);
 faqRef = useRef(null);

 const { state } = useLocation();
 const navigate = useNavigate();

 useEffect(() => {
  if (state?.scrollTo) {
   switch (state.scrollTo) {
    case "features":
     handleScroll(infoRef);
     break;
    case "pricing":
     handleScroll(pricingRef);
     break;
    default:
   }
   navigate("/", { state: {} });
  }
 }, [state, navigate]);

 return (
  <>
   <Helmet>
    <title>
     AI writer | Ai text generator | AI SEO web page content writing
    </title>
    <link rel="canonical" href="https://seoll-e.ai" />
    <meta
     name="description"
     content="Seoll-e is an AI writer, expert in SEO writing and AI blog post automation with image and audio generation. Automatically publish SEO optimized posts on WordPress, WIX"
    />
   </Helmet>
   <HomeContainer>
    <Header />
    <Hero />
    <TryNow />
    <div ref={infoRef}>
     <Info />
    </div>
    <div ref={pricingRef}>
     <Pricing />
    </div>
    <AboutUs />
    <div ref={faqRef}>
     <FAQ faq={faqGenerate} />
    </div>
    <div ref={footerRef}>
     <Footer />
    </div>
   </HomeContainer>
  </>
 );
}

export default Home;

export function handleScroll(ref, extra = 0) {
 const offsetTop = ref.current.offsetTop;
 window.scrollTo({
  top: offsetTop + extra,
  behavior: "smooth",
 });
}

export const scrollRefs = {
 handleScrollToInfo: () => handleScroll(infoRef),
 handleScrollToPricing: () => handleScroll(pricingRef),
 handleScrollToFooter: () => handleScroll(footerRef),
 handleScrollToFAQ: () => handleScroll(faqRef),
};
