import React, { useEffect, useRef } from "react";

export const InactivityTimer = () => {
 const timeoutIdRef = useRef(null);

 const resetTimer = () => {
  timeoutIdRef.current && window.clearTimeout(timeoutIdRef.current);

  window.removeEventListener("click", resetTimer, false);
  window.removeEventListener("keypress", resetTimer, false);
  window.removeEventListener("scroll", resetTimer, false);

  setTimeout(() => setTimer(), 10 * 60 * 1000);
 };

 const setTimer = () => {
  timeoutIdRef.current = window.setTimeout(() => {
   window.location.reload();
  }, 20 * 60 * 1000);

  window.addEventListener("click", resetTimer, false);
  window.addEventListener("keypress", resetTimer, false);
  window.addEventListener("scroll", resetTimer, false);
 };

 useEffect(() => {
  resetTimer();

  return () => {
   window.removeEventListener("click", resetTimer, false);
   window.removeEventListener("keypress", resetTimer, false);
   window.removeEventListener("scroll", resetTimer, false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return <></>;
};

export default InactivityTimer;
