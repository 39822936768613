import { loginAdmin } from "./loginAdmin";
import axios from "./axios-config";

export async function sendResetLink(email) {
 const token = await loginAdmin();

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  await axios.post(
   "users/password/reset-password-message/",
   { email },
   {
    headers,
   }
  );
  return true;
 } catch (error) {
  return false;
 }
}

export async function resetPassword({ id, email, password }) {
 const token = await loginAdmin();

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  await axios.post(
   "users/password/reset-password/",
   { email, id, password },
   {
    headers,
   }
  );
  return true;
 } catch (error) {
  return false;
 }
}
