import axios from "./axios-config";

export const checkUserAuthenticated = async () => {
 const access = localStorage.getItem("access");
 const refresh = localStorage.getItem("refresh");

 if (access) {
  const headers = {
   Authorization: `Bearer ${access}`,
   "Content-Type": "application/json",
  };

  try {
   const response = await axios.post(
    "/token/refresh/",
    { refresh },
    { headers }
   );
   localStorage.setItem("access", response.data.access);
   localStorage.setItem("refresh", response.data.refresh);
   return true;
  } catch (e) {
   localStorage.removeItem("email");
   localStorage.removeItem("access");
   localStorage.removeItem("refresh");
   localStorage.removeItem("wordpressSettings");
   return false;
  }
 }
};
