import Notiflix from "notiflix";

export function copyHTML(text) {
 navigator.clipboard
  .writeText(text)
  .then(() => {
   Notiflix.Notify.success("Post HTML copied to clipboard");
  })
  .catch((error) => {
   Notiflix.Notify.error(`Error copying to clipboard: ${error}`);
  });
}

export function copyText(text, keywords = false) {
 const tempElement = document.createElement("div");
 tempElement.innerHTML = text;
 navigator.clipboard
  .writeText(tempElement.textContent || tempElement.innerText || "")
  .then(() => {
   if (keywords) {
    Notiflix.Notify.success("Question copied to clipboard");
    return;
   }
   Notiflix.Notify.success("Post copied to clipboard");
  })
  .catch((error) => {
   Notiflix.Notify.error(`Error copying to clipboard: ${error}`);
  });
}
