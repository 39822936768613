import styled, { keyframes } from "styled-components";
import { BiErrorCircle } from "react-icons/bi";
import { TbLoader2, TbExternalLink } from "react-icons/tb";
import { media } from "../../utils/mixins";
import { BtnBasic, Button, CopyContainer } from "../Common.styled";
import { MdDone } from "react-icons/md";
import { SlOptionsVertical } from "react-icons/sl";
import { CgAdd } from "react-icons/cg";
import { CiEdit } from "react-icons/ci";
import { TfiSave } from "react-icons/tfi";
import { IoMdClose } from "react-icons/io";

export const ExpandDiv = styled.div`
 display: grid;
 grid-template-columns: 92fr 140fr 70fr;
 justify-items: start;
 align-items: center;

 width: 100%;

 text-align: left;

 ${media.laptop`
  grid-template-columns: 1fr 2.7fr 0.8fr 1.4fr;

  &.hide-int {
  grid-template-columns: 1fr 4fr 1fr 1.5fr;
  }
 `}
`;

export const PostDataContainer = styled.div`
 display: grid;
 justify-items: start;
 align-items: center;

 padding: 10px;

 font-size: 10px;
 font-family: ${(props) => props.theme.secondaryFont};

 transition: all 500ms ease-in-out;

 &:nth-of-type(4n) {
  background-color: ${(props) => props.theme.bcg4};
 }

 &:nth-last-of-type(2) {
  border-radius: 0 0 10px 10px;
 }

 ${media.mobile`
 font-size: 14px;

`}

 ${media.laptop`
 grid-template-columns: 0.4fr 5.9fr 3fr 3fr 0.3fr;
 height: 70px;
 padding-inline: 16px;

 &.hide-int {
 grid-template-columns: 0.4fr 7.5fr 3fr 0.3fr;
 }

  &:nth-of-type(4n + 2) {
  background-color: ${(props) => props.theme.background};

  & .table {
  background-color: ${(props) => props.theme.background};
  }
 }
 `}
`;

export const Keywords = styled.span`
 display: block;

 margin-top: 2px;

 font-size: 12px;
 font-style: italic;
`;

export const DeleteIcon = styled.img`
 display: none;

 margin-left: auto;
 cursor: pointer;

 transition: color 250ms ease-in-out;

 ${media.laptop`
  display: inline-block;
 `}
`;

export const DeleteIconMobile = styled(DeleteIcon)`
 display: block;
 margin-inline: auto;
`;

export const ReadyIcon = styled(MdDone)`
 font-size: 14px;
 color: ${(props) => props.theme.color};
`;

export const ReadyAll = styled.img`
 display: block;
 color: ${(props) => props.theme.color};
`;

export const GenerateTextView = styled.div`
 position: relative;

 max-height: 20000px;

 padding: 10px;
 margin-bottom: 30px;
 margin-top: 10px;
 overflow: hidden;

 border: 1px solid ${({ theme }) => theme.outline};
 border-radius: 4px;

 color: ${({ theme }) => theme.color};
 text-align: left;

 background-color: ${({ theme }) => theme.background};

 transition: background-color 500ms ease-in-out, color 500ms ease-in-out,
  border-color 500ms ease-in-out;

 &.hidden {
  max-height: 0;
  padding: 0;
  margin: 0;

  border: none;
 }

 ${media.laptop`
  padding: 30px;
 `}
`;

export const Icons = styled.div`
 display: none;

 ${media.laptop`
 display: flex;
 align-items: center;
 gap: 12px;
 justify-content: center;
 width: 100%;

 &:nth-of-type(3) {
  gap: 0;
  }

   &.gap {
  gap: 5px;
 }
 `}
`;

export const ExpandIcon = styled.img`
 width: 16px;
 height: 16px;

 cursor: pointer;

 transition: transform 200ms ease-in-out;

 &.active {
  transform: rotate(180deg);
 }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingIcon = styled(TbLoader2)`
 animation: ${spin} 1500ms linear infinite;
 color: ${(props) => props.theme.color};
`;

export const ErrorIcon = styled(BiErrorCircle)`
 color: ${(props) => props.theme.color};
`;

export const CopyHTMLContainer = styled(CopyContainer)``;

export const CopyHTML = styled.img`
 display: block;
 width: 100%;
`;

export const ReloadButton = styled(BtnBasic)`
 display: none;

 min-width: 80px;
 min-height: 27px;
 padding: 0 9px;

 font-size: 15px;
 line-height: 1.8;

 ${media.laptop`
  display: inline-block;
 `}
`;

export const ReloadButtonMobile = styled(ReloadButton)`
 display: block;
 min-width: 60px;
 margin-inline: auto;
`;

export const WPLink = styled.a`
 position: relative;

 display: none;

 margin-right: 40px;

 color: ${({ theme }) => theme.color};
 transition: all 200ms ease-in-out;

 ${media.laptop`
  display: block;
 `}

 &:hover * {
  color: ${(props) => props.theme.accentColor};
 }
`;

export const WPLinkMobile = styled(WPLink)`
 display: block;
`;

export const MobileIcons = styled.div`
 display: flex;
 align-items: center;
 gap: 12px;
 justify-content: flex-end;

 width: 70px;

 ${media.laptop`
  display: none;
 `}
`;

export const OptionsIcon = styled(SlOptionsVertical)`
 cursor: pointer;
 font-size: 14px;
`;

export const IntegrationBtn = styled.button`
 position: relative;

 display: flex;
 align-items: center;

 margin-right: 40px;

 background-color: transparent;
 border: none;
 outline: none;
 cursor: pointer;

 &:hover * {
  color: ${(props) => props.theme.accentColor};
 }
`;

export const Plus = styled(CgAdd)`
 position: absolute;
 bottom: 3px;
 right: -10px;

 background-color: ${(props) => props.theme.background};
 color: ${(props) => props.theme.color};
 border-radius: 50%;

 font-size: 16px;

 transition: color 250ms ease-in-out;
`;

export const Show = styled(TbExternalLink)`
 position: absolute;
 bottom: 3px;
 right: -10px;

 background-color: ${(props) => props.theme.background};
 color: ${(props) => props.theme.color};

 border-radius: 50%;

 font-size: 16px;

 transition: color 250ms ease-in-out;
`;

export const SaveIcon = styled(TfiSave)`
 position: absolute;
 top: 20px;
 right: 20px;
 font-size: 30px;
 cursor: pointer;
 color: ${(props) => props.theme.color};
 transition: color 250ms ease-in-out;

 &:hover {
  color: ${(props) => props.theme.accentColor};
 }
`;

export const EditIcon = styled(CiEdit)`
 position: absolute;
 top: 20px;
 right: 40px;
 width: 35px;
 height: 35px;
 font-size: 30px;
 cursor: pointer;
 color: ${(props) => props.theme.color};
 transition: color 250ms ease-in-out;

 background-color: ${(props) => props.theme.background};
 border-radius: 50%;
 padding: 5px;
 z-index: 10;

 &:hover {
  color: ${(props) => props.theme.accentColor};
 }
`;

export const CloseIcon = styled(IoMdClose)`
position: relative;
 top: 5px;
 right: -10px;
 font-size: 25px;
 cursor: pointer;
 color: ${(props) => props.theme.color};
 transition: color 250ms ease-in-out;

 ${media.laptop`
 font-size: 30px;
 `}

 &:hover {
  color: ${(props) => props.theme.accentColor};
 }
`

export const TrimText = styled.div`
 display: -webkit-box;
 -webkit-line-clamp: 2;
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
`;

export const RelatedSelect = styled.select`
 position: absolute;
 bottom: ${(props) => props.bottom};
 right: 10px;
 width: 230px;
 margin-left: 30px;
 cursor: pointer;
 min-height: 48px;
 height: 48px;
 padding: 0.65em 10px; //for Firefox
 background-color: ${({ theme }) => theme.background};
 border-radius: 4px;
 outline: none;
 border: 1px solid ${(props) => props.theme.outline};
 font-size: 16px;
 color: ${(props) => props.theme.color};

 ${media.tablet`
 position: static;
 `}
`;

export const AudioBtnContainer = styled.div`
 position: absolute;
 bottom: 70px;
 right: -20px;
 margin-left: auto;
 margin-right: 20px;

 & ${Button} {
  margin-right: 10px;
  height: 48px;
  width: 185px;
 }

 ${media.laptop`
 position: static;
 `}
`;

export const EditTitleInput = styled.input`
 color: ${(props) => props.theme.color};
 background-color: transparent;
 border: none;
 outline: none;
 border-bottom: 1px solid ${(props) => props.theme.color};
 width: 100px;

 ${media.laptop`
 width: 100%;
 `}
`;

export const PostDataText = styled.div`
 display: grid;
 grid-template-columns: 9fr 3fr;
 column-gap: 10px;
 align-items: center;

 font-family: ${(props) => props.theme.secondaryFont};
 font-size: 12px;
 overflow-wrap: break-word;

 ${media.mobile`
 font-size: 14px;
 `}

 ${media.laptop`
 font-size: 15px;
`}


& ${EditIcon} {
  position: static;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
 }

 & ${SaveIcon} {
  position: static;
  font-size: 15px;

  ${media.laptop`
  font-size: 20px;
 `}
 }

 &:hover {
  & ${EditIcon} {
   opacity: 1;
  }
 }
`;

export const IconsContainer = styled.div`
 width: 50px;
`
