import styled from "styled-components";
import { media } from "../../../utils/mixins";

export const MobileMenuStyled = styled.div`
 position: absolute;
 top: 0px;
 right: 0;

 display: flex;
 gap: 20px;
 flex-direction: column;

 width: 320px;
 max-width: 100%;
 padding: 22px 14px 14px;

 border-radius: 8px 0 0 8px;
 border: 1px solid ${({ theme }) => theme.outline};

 overflow-y: scroll;

 background-color: ${({ theme }) => theme.background};

 font-size: 18px;


 ${media.largeMobile`
  font-size: 16px;
  gap: 15px;
 `}

 ${media.laptop`
  display: none`}
`;

export const CloseMenuIcon = styled.img`
 position: absolute;
 top: 22px;
 right: 16px;

 height: 44px;

 cursor: pointer;
`;

export const HeaderMenu = styled.div`
 display: flex;
 gap: 20px;
 flex-wrap: wrap;
`;
