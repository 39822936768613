import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { lightTheme } from "../../utils/themes";
import Checkbox from "../../assets/img/checkSquare.svg";
import CheckboxLight from "../../assets/img/checkSquare_light.svg";
import { SquareCheckboxStyled } from "./SquareCheckbox.styled";

function SquareCheckbox({
 onChange,
 checked,
 defaultChecked,
 name,
 disabled = false,
}) {
 const { theme } = useContext(ThemeContext);

 const isControlled = checked !== undefined;

 return (
  <>
   <SquareCheckboxStyled
    name={name}
    type="checkbox"
    onChange={(e) => {
     onChange(e);
    }}
    checked={isControlled ? checked : undefined}
    defaultChecked={isControlled ? undefined : defaultChecked}
    disabled={disabled}
   />
   <span>
    <img
     src={theme.background === lightTheme.background ? Checkbox : CheckboxLight}
     alt="Checked checkbox indicating successful completion in a task related to SEO content writing or SEO blog post planning."
     aria-hidden="true"
    />
   </span>
  </>
 );
}

export default SquareCheckbox;
