import Notiflix from "notiflix";
import { setErrorMessage } from "../error-message";
import axios from "./axios-config";

export async function createPost({ topic, keywords }) {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  const response = await axios.post(
   "posts/generate/single/",
   {
    topic,
    keywords,
   },
   { headers }
  );
  return response.data;
 } catch (error) {
  if (error.response.data.message === "You don't have enough credits") {
   return error.response.data.message;
  }
  Notiflix.Notify.failure(error.response.data.message);
 }
}

export async function createMultiplePosts({
 theme,
 keywords,
 count,
 auto_start,
 count_per_day,
}) {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 const data = count_per_day
  ? {
     theme,
     count,
     keywords,
     auto_start,
     count_per_day,
    }
  : {
     theme,
     count,
     keywords,
     auto_start,
    };

 try {
  const response = await axios.post("posts/generate/multiple/", data, {
   headers,
  });
  return response.data;
 } catch (error) {
  return error.response.data.message;
 }
}

export async function createPostDemo({ topic, keywords, adminToken }) {
 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${adminToken}`,
 };

 try {
  const response = await axios.post(
   "posts/demo/generate/",
   {
    topic,
    keywords,
   },
   { headers }
  );
  return response.data;
 } catch (error) {
  Notiflix.Notify.failure(error.response.data.message);
 }
}

export async function getPost({ id, token }) {
 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 const response = await axios.get(`posts/demo/get-post/${id}/`, {
  headers,
 });
 return response.data;
}

export async function regeneratePost(
 id,
 text_only = false,
 status,
 new_settings
) {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 const data = text_only
  ? { post_id: id, text_only, new_settings }
  : { post_id: id, new_settings };

 if ([21, 22, 23, 24, 37, 40, 41].includes(status)) {
  try {
   await axios.post("posts/generate/single/", data, { headers });
   return true;
  } catch (error) {
   if (error.response.data.message === "You don't have enough credits") {
    return error.response.data.message;
   }
   return false;
  }
 }
 if (status === 26) {
  try {
   await axios.post("posts/generate/description/", data, { headers });
   return true;
  } catch (error) {
   if (error.response.data.message === "You don't have enough credits") {
    return error.response.data.message;
   }
   return false;
  }
 }
 if (status === 27) {
  try {
   await axios.post("posts/generate/details/", data, { headers });
   return true;
  } catch (error) {
   if (error.response.data.message === "You don't have enough credits") {
    return error.response.data.message;
   }
   return false;
  }
 }
 if (status === 28) {
  try {
   await axios.post("posts/generate/id/", data, { headers });
   return true;
  } catch (error) {
   if (error.response.data.message === "You don't have enough credits") {
    return error.response.data.message;
   }
   return false;
  }
 }
 if (status === 29) {
  try {
   await axios.post("posts/generate/alt-text/", data, { headers });
   return true;
  } catch (error) {
   if (error.response.data.message === "You don't have enough credits") {
    return error.response.data.message;
   }
   return false;
  }
 }
 if (status === 30) {
  try {
   await axios.post("posts/generate/image-prompt/", data, { headers });
   return true;
  } catch (error) {
   if (error.response.data.message === "You don't have enough credits") {
    return error.response.data.message;
   }
   return false;
  }
 }
 if (status === 31) {
  try {
   await axios.post("posts/generate/regenerate-image/", data, { headers });
   return true;
  } catch (error) {
   if (error.response.data.message === "You don't have enough credits") {
    return error.response.data.message;
   }
   return false;
  }
 }
 if ([32, 33, 34].includes(status)) {
  try {
   await axios.post("posts/generate/faq/", data, { headers });
   return true;
  } catch (error) {
   return false;
  }
 }
}

export async function regeneratePicture(id, new_settings) {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  await axios.post(
   "posts/generate/regenerate-image/",
   {
    post_id: id,
    new_settings,
   },
   { headers }
  );
  return true;
 } catch (error) {
  if (error.response.data.message === "You don't have enough credits") {
   return error.response.data.message;
  }
  return false;
 }
}

export const getAllPosts = async () => {
 const access = localStorage.getItem("access");

 const headers = {
  Authorization: `Bearer ${access}`,
  "Content-Type": "application/json",
 };

 if (access) {
  try {
   const response = await axios.get("posts/get-all-posts/", { headers });
   return response.data;
  } catch (e) {}
 }
};

export const deletePost = async (id) => {
 const access = localStorage.getItem("access");

 const headers = {
  Authorization: `Bearer ${access}`,
  "Content-Type": "application/json",
 };

 if (access) {
  try {
   const response = await axios.delete(`posts/delete-update-post/${id}/`, {
    headers,
   });
   return response.data;
  } catch (e) {
   const err = setErrorMessage(e.response.data);
   Notiflix.Notify.failure(err);
  }
 }
};

export const updatePost = async ({
 info,
 id,
 img,
 custom_img,
 topic,
 status,
 summary_part,
 comparison_part,
 faq_part,
}) => {
 const access = localStorage.getItem("access");

 const headers = {
  Authorization: `Bearer ${access}`,
  "Content-Type": "multipart/form-data",
 };

 if (access) {
  try {
   const response = await axios.put(
    `posts/delete-update-post/${id}/`,
    {
     info,
     custom_img,
     image_pk: img,
     topic,
     status,
     summary_part,
     faq_part,
     comparison_part,
    },
    {
     headers,
    }
   );
   return response.data;
  } catch (e) {
   const err = setErrorMessage(e.response.data);
   Notiflix.Notify.failure(err);
  }
 }
};

export const setNewMainPost = async (idMainPost, idselectedPost) => {
 const access = localStorage.getItem("access");

 const headers = {
  Authorization: `Bearer ${access}`,
  "Content-Type": "multipart/form-data",
 };

 if (access) {
  try {
   const response = await axios.post(
    `posts/set-new-main/`,
    { main_post_pk: idMainPost, selected_post_pk: idselectedPost },
    {
     headers,
    }
   );
   return response.data;
  } catch (e) {
   const err = setErrorMessage(e.response.data);
   Notiflix.Notify.failure(err);
  }
 }
};

export const searchPosts = async (query, config) => {
 const access = localStorage.getItem("access");

 const headers = {
  Authorization: `Bearer ${access}`,
  "Content-Type": "application/json",
 };

 const configString = Object.keys(config)
  .map((el) => config[el] && `fields=${el}`)
  .filter((el) => el)
  .join("&");

 if (access) {
  try {
   const response = await axios.get(
    `posts/search-posts/${query}?${configString}`,
    { headers }
   );
   return response.data;
  } catch (e) {
   const err = setErrorMessage(e.response.data);
   Notiflix.Notify.failure(err);
  }
 }
};

export const postToWP = async (post_pk) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  await axios.post(
   "posts/wordpress/send-post/",
   {
    post_pk,
   },
   { headers }
  );
  return true;
 } catch (error) {}
};

export const postToWIX = async (post_pk) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  await axios.post(
   "posts/wix/send-post/",
   {
    post_pk,
   },
   { headers }
  );
  return true;
 } catch (error) {}
};

export const bindPost = async (email, post_pk, token) => {
 if (!post_pk || !token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  await axios.post(
   "posts/demo/attach/",
   {
    post_pk,
    email,
   },
   { headers }
  );
  return;
 } catch (error) {}
};

export const generateContentBlock = async (post_id, block) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  await axios.post(
   `posts/generate/${block}/`,
   {
    post_id,
    only: true,
    new_settings: true,
   },
   { headers }
  );
 } catch (error) {}
};

export const getSinglePost = async (post_id) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };
 try {
  const res = await axios.get(`posts/get-post/${post_id}/`, { headers });
  return res.data;
 } catch (error) {}
};
