import { ThemeContext, useTheme } from "styled-components";
import { ReviewTitle, StatsContainer } from "./Reviews.styled";
import { lightTheme } from "../../utils/themes";

function Stats() {
 const { theme } = useTheme(ThemeContext);
 return (
  <StatsContainer>
   <ul>
    <li className="rating">
     <a
      href="https://www.producthunt.com/products/seoll-e/reviews?utm_source=badge-product_rating&utm_medium=badge&utm_souce=badge-seoll&#0045;e"
      target="_blank"
      rel="noreferrer"
     >
      <img
       src={`https://api.producthunt.com/widgets/embed-image/v1/product_rating.svg?product_id=537313&theme=${
        theme.color === lightTheme.color ? "neutral" : "dark"
       }`}
       alt="SEOLL&#0045;E - Automated&#0032;SEO&#0032;content&#0032;generation&#0032;and&#0032;publishing | Product Hunt"
       style={{ width: 200 }}
       width="242"
       height="108"
      />
     </a>
    </li>
    <li className="title">
     <ReviewTitle className={theme.color === lightTheme.color ? "invert" : ""}>
      Product Hunt Reviews
     </ReviewTitle>
    </li>
    <li className="rating">
     <a
      href="https://www.producthunt.com/posts/seoll-e?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-seoll&#0045;e"
      target="_blank"
      rel="noreferrer"
     >
      <img
       src={`https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=397734&theme=${
        theme.color === lightTheme.color ? "neutral" : "dark"
       }&period=weekly&topic_id=164`}
       alt="SEOLL&#0045;E - Automated&#0032;SEO&#0032;content&#0032;generation&#0032;and&#0032;publishing | Product Hunt"
       style={{ width: 250, height: 54 }}
       width="250"
       height="54"
      />
     </a>
    </li>
   </ul>
  </StatsContainer>
 );
}

export default Stats;
