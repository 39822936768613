import styled from "styled-components";

export const HomeContainer = styled.div``;

export const Hidden = styled.h1`
 position: absolute;
 width: 1px;
 height: 1px;
 margin: -1px;
 border: 0;
 padding: 0;

 white-space: nowrap;
 clip-path: inset(100%);
 clip: rect(0 0 0 0);
 overflow: hidden;
`;

export const Hidden2 = styled.h2`
 position: absolute;
 width: 1px;
 height: 1px;
 margin: -1px;
 border: 0;
 padding: 0;

 white-space: nowrap;
 clip-path: inset(100%);
 clip: rect(0 0 0 0);
 overflow: hidden;
`;

export const Hidden3 = styled.h3`
 position: absolute;
 width: 1px;
 height: 1px;
 margin: -1px;
 border: 0;
 padding: 0;

 white-space: nowrap;
 clip-path: inset(100%);
 clip: rect(0 0 0 0);
 overflow: hidden;
`;
