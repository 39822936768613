import styled from "styled-components";
import { InfoTitle } from "../Info/Info.styled";
import { media } from "../../utils/mixins";
import { OutlinedBtn } from "../Common.styled";
import { ReactComponent as Coins } from "../../assets/img/coins.svg";

export const PricingContainer = styled.div`
 padding: 74px 18px 75px;
 background-color: ${({ theme }) => theme.background};

 ${media.laptop`
 display: grid;
 grid-template-columns: 451px 772px;
 column-gap: 20px;

 max-width: 1280px;
 padding-top: 61px;
 padding-bottom: 61px;
 padding-inline: 10px;
 margin-inline: auto;
 `}
`;

export const PricingTitle = styled(InfoTitle)`
 margin-bottom: 12px;

 font-size: 26px;
 line-height: 1.3;
 color: ${({ theme }) => theme.color};
`;

export const PricingTile = styled.div`
 display: flex;
 flex-direction: column;

 padding: 14px;
 padding-bottom: 18px;
 padding-right: 16px;
 margin-bottom: 63px;

 border-radius: 4px;
 border: 1px solid ${(props) => props.theme.outline};

 background-color: ${({ theme }) => theme.background};

 font-size: 26px;
 line-height: 1.3;
 color: ${(props) => props.theme.color};

 transition: all 0.3s ease-in-out;

 ${media.laptop`
  margin-bottom: 0px;
 `}

 & ${OutlinedBtn} {
  display: block;
  margin-left: auto;
  margin-top: 10px;
  min-height: 30px;
  font-size: 18px;
  padding: 8px 10px 12px 10px;
  ${media.laptop`
   
   margin-top: auto;
  width: 50%;
  `}

  &.mobile-only {
   display: block;

   ${media.laptop`
    display: none;
   `}
  }
 }

 &:nth-child(3) ${OutlinedBtn} {
  margin-top: -7px;
 }
`;

export const PricingUl = styled.ul`
 margin: 0;
 margin-bottom: 15px;
 padding-left: 20px;
`;

export const PricingDesc = styled.li`
 margin: 0;

 font-size: 16px;
 line-height: 1.8;
 font-family: ${(props) => props.theme.secondaryFont};
 color: ${({ theme }) => theme.color};

 &:first-child {
  margin-top: 12px;
 }

 ${media.laptop`
  line-height: 1.6;
 `}
`;

export const PricingDescSpan = styled.p`
 display: flex;
 flex-direction: column;

 margin: 0;
 margin-bottom: 16px;
 padding-left: 12px;
 font-size: 16px;
 line-height: 1.8;
 font-family: ${(props) => props.theme.secondaryFont};
 color: ${({ theme }) => theme.color};

 &:first-child {
  margin-top: 16px;
 }

 &:last-of-type {
  ${media.laptop`
    margin-bottom: 10px;
    
  `}
 }

 ${media.laptop`
 &:nth-child(3), &:nth-child(4) {
   grid-column: 1;
  }
`}

 &.no-margin {
  margin: 0;
 }
`;

export const PricingMainTitle = styled(InfoTitle)`
 grid-column: span 2;

 margin-bottom: 45px;
 margin-inline: 0;
 margin-top: 0;

 font-size: 36px;
 color: ${({ theme }) => theme.color};
 line-height: 1.1;
 text-align: left;

 ${media.laptop`
  font-size: 56px;
  line-height: 1.2;
 `}
`;

export const SmallTiles = styled.div`
 display: flex;
 gap: 12px;
 flex-wrap: wrap;
 align-items: center;
 align-content: flex-start;
 justify-content: flex-start;
 max-width: 320px;
`;

export const SmallTile = styled.div`
 padding: 4px 16px;

 border-radius: 40px;

 background-color: ${(props) => props.theme.secondaryBcg};

 font-size: 14px;
 color: ${({ theme }) => theme.color};
 font-family: ${(props) => props.theme.secondaryFont};
 line-height: 1.8;
`;

export const PricingImg = styled(Coins)`
 display: block;
 width: 48px;
 height: 48px;
 color: ${(props) => props.theme.accentColor};
`;

export const CreditContainer = styled.div`
 display: flex;
 gap: 14px;
 flex-direction: column;
 grid-column: span 2;

 margin-top: 80px;
 margin-bottom: 14px;

 & > div {
  display: flex;
  flex-direction: column;
  gap: 13px;
 }

 ${media.laptop`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row dense;
  grid-column-gap: 13px;
  grid-row-gap: 14px;
 `}
`;

export const CreditTitle = styled(PricingMainTitle)`
 margin: 0;
 line-height: 1.3;

 ${media.laptop`
  display: flex;
  gap: 20px;
  align-items: center;
  grid-column: span 2;

  margin-bottom: 31px;
  
  line-height: 1.2;
 `}
`;

export const CreditSubtitle = styled(CreditTitle)`
 font-size: 26px;

 &.keywords {
  margin-top: 5px;
 }

 ${media.laptop`
 margin: 0;
 `}
`;
