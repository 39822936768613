import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 dateStart: {
  active: false,
  value: new Date().toDateString(),
 },
 dateEnd: {
  active: false,
  value: new Date().toDateString(),
 },
 status: {
  active: false,
  value: [21],
 },
};

const filterSlice = createSlice({
 name: "filters",
 initialState,
 reducers: {
  setFilters: (_, action) => {
   return action.payload;
  },
  resetFilters: () => {
   return {
    dateStart: {
     active: false,
     value: new Date().toDateString(),
    },
    dateEnd: {
     active: false,
     value: new Date().toDateString(),
    },
    status: {
     active: false,
     value: [21],
    },
   };
  },
 },
});

export const { setFilters, resetFilters } = filterSlice.actions;

export default filterSlice.reducer;
