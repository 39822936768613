import axios from "./axios-config";

export const loginAdmin = async () => {
 try {
  const response = await axios.post("token/", {
   email: "frontend@seo.admin",
   password: "admin12345",
  });
  return response.data.access;
 } catch (e) {}
};
