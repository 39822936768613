import { useEffect, useState } from "react";
import { BtnBasic } from "../../Common.styled";
import { CookiesBtns, CookiesContainer, CookiesLink } from "./Cookies.styled";
import { useLocation } from "react-router";

function Cookies() {
 const [askForCookies, setAskForCookies] = useState(false);
 const { pathname } = useLocation();

 function savePreference(preference) {
  localStorage.setItem("allow-cookies", JSON.stringify(preference));
 }

 function injectScripts() {
  if (window.location.hostname !== "seoll-e.ai") return;

  // const gtmScript = document.createElement("script");
  // gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=G-85TRFCJL55";
  // document.body.appendChild(gtmScript);
  // window.dataLayer = window.dataLayer || [];
  // window.dataLayer.push({ js: new Date() });
  // window.dataLayer.push({ config: "G-85TRFCJL55" });

  const twtScript = document.createElement("script");
  twtScript.src = "https://static.ads-twitter.com/uwt.js";
  document.body.appendChild(twtScript);
  window.twq =
   window.twq ||
   function () {
    window.twq.queue.push(arguments);
   };
  window.twq.version = "1.1";
  window.twq.queue = [];
  window.twq("config", "ofkfv");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
   event: "activateGA",
  });
 }

 function handleAccept() {
  setAskForCookies(false);
  savePreference(true);
  injectScripts();
 }

 function handleDecline() {
  setAskForCookies(false);
  savePreference(false);
 }

 useEffect(() => {
  const cookiesPreference = localStorage.getItem("allow-cookies");
  if (cookiesPreference) {
   const parsedPreference = JSON.parse(cookiesPreference);
   setAskForCookies(false);
   if (parsedPreference) injectScripts();
  } else {
   setAskForCookies(true);
  }
 }, []);

 if (askForCookies && !pathname.includes("example")) {
  return (
   <CookiesContainer>
    <span>
     We use cookies on our website to provide you with the best possible user
     experience. By continuing to use our website or services, you agree to
     their use. To learn more about how we use cookies and how you can change
     your settings, please review our{" "}
     <CookiesLink to="/cookie-policy">Cookie Policy</CookiesLink>.
    </span>
    <CookiesBtns>
     <BtnBasic className="outlined centered" onClick={handleAccept}>
      Accept All
     </BtnBasic>
     <BtnBasic className="centered" onClick={handleDecline}>
      Decline
     </BtnBasic>
    </CookiesBtns>
   </CookiesContainer>
  );
 }
}

export default Cookies;
