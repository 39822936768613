import styled from "styled-components";
import { BsMoonFill, BsSunFill } from "react-icons/bs";
import { media } from "../../utils/mixins";

export const ChangeThemeBtnContainer = styled.div`
 position: ${(props) => (props.position ? "static" : "absolute")};
 margin-left: ${(props) => (props.position ? "auto" : "0")};
 top: ${(props) => props.top};
 right: ${(props) => props.right};
 bottom: ${(props) => props.bottom};
 z-index: 4;
 overflow: hidden;

 display: flex;
 justify-content: center;
 gap: 17px;
 align-items: center;

 width: 85px;
 transform: scale(0.8);

 border-radius: 50px;
 box-shadow: 2px 2px 10px lightgray;
 border: 5px solid ${({ theme }) => theme.background};

 cursor: pointer;

 background-color: ${({ theme }) => theme.background};

 transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out;

 ${media.laptop`
 `}
`;

export const ChangeThemeSwitch = styled.div`
 position: absolute;
 left: 0;
 top: 0;
 background-color: ${(props) => props.theme.accentColor};
 width: 100%;
 height: 100%;
 border-radius: 50px;
 transition: transform 250ms ease-in-out;
 transform: ${({ theme }) => theme.switchPosition};
`;

export const ChangeThemeLight = styled(BsSunFill)`
 position: relative;
 font-size: 22px;
 z-index: 1;
 margin-block: 5px;
 color: ${({ theme }) => theme.sunColor};
 transition-duration: 250ms;
 transition-timing-function: ease-in-out;
 transition-property: color, transform;
 transform: ${({ theme }) => theme.iconAnimation};
`;

export const ChangeThemeDark = styled(BsMoonFill)`
 position: relative;
 font-size: 20px;
 z-index: 1;
 color: lightgray;
 margin-block: 5px;
 color: ${({ theme }) => theme.moonColor};
 transition-duration: 500ms;
 transition-timing-function: ease-in-out;
 transition-property: color, transform;
 transform: ${({ theme }) => theme.iconAnimation};
`;
