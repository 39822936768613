import styled from "styled-components";
import { OutlinedBtn } from "../../Common.styled";

export const FooterModalBtn = styled(OutlinedBtn)`
 display: block;

 margin-inline: auto;
 margin-top: 10px;

 font-size: 18px;
`;

export const FooterTextField = styled.textarea`
 width: 100%;
 resize: none;

 padding: 10px 14px;
 margin-top: 12px;
 min-height: 100px;
 margin-inline: auto;

 border-radius: 4px;
 outline: none;
 border: 1px solid ${({ theme }) => theme.outline};

 color: ${({ theme }) => theme.color};
 font-size: 16px;
 font-family: ${(props) => props.theme.secondaryFont};

 transition: box-shadow 250ms ease-in-out, background-color 500ms ease-in-out,
  color 500ms ease-in-out, border 500ms ease-in-out;

 &::placeholder {
  color: ${({ theme }) => theme.color};
  font-size: 16px;
 }
`;
