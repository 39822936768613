import { useNavigate } from "react-router";
import React, { useRef } from "react";
import {
  BackgroundContainer,
  Container,
  OutlinedBtn,
  Title,
} from "../../components/Common.styled";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { handleScroll } from "../Home/Home";
import { Helmet } from "react-helmet";

function SuccessfulPayment() {
  const navigate = useNavigate();

  const footerRef = useRef(null);
  const handleScrollToFooter = () => {
    handleScroll(footerRef);
  };

  return (
    <>
      <BackgroundContainer>
        <Helmet>
          <link rel="canonical" href="https://seoll-e.ai/completion" />
        </Helmet>
        <Header handleScrollToFooter={handleScrollToFooter} />
        <Container style={{ minHeight: "calc(100vh - 240px)" }}>
          <Title>Payment Successful!</Title>
          <OutlinedBtn
            onClick={() => navigate("/generate")}
            className="small-width"
          >
            Back to posts
          </OutlinedBtn>
        </Container>
      </BackgroundContainer>
      <div ref={footerRef}>
        <Footer />
      </div>
    </>
  );
}

export default SuccessfulPayment;
