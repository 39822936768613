import styled from "styled-components";

export const PaginationWrapper = styled.div`
 position: relative;

 display: flex;
 flex-direction: row;

 max-width: 1280px;
 margin-inline: auto;
`;

export const PageNumber = styled.button`
 color: ${(props) => props.theme.accentColor};
 opacity: ${(props) => (props.isActive ? 1 : 0.5)};
 padding: 16px;
 cursor: pointer;
 background-color: transparent;
 border: none;
 outline: none;
 font-size: 20px;
 transition: color 250ms ease-in-out, opacity 250ms ease-in-out;

 &:hover {
  color: ${(props) => props.theme.accentColor};
  opacity: 1;
 }
`;

export const NumberIndicator = styled.div`
 position: absolute;
 height: 2px;
 width: 16px;
 background-color: ${(props) => props.theme.accentColor};
 opacity: ${(props) => (props.isActive ? 1 : 0.2)};
 bottom: 0;
 left: ${(props) => props.num * 43 + 14}px;
 transition: all 250ms ease-in-out;
`;

export const ArrowHalf = styled.div`
 width: 10px;
 height: 2px;
 border-radius: 1px;
 background-color: ${(props) => props.theme.accentColor};
 display: inline-block;
 position: absolute;
 transform-origin: 0px;
 opacity: 1;
 transition: transform 0.1s ease-in-out, opacity 0.2s ease-in-out;
 &:first-child {
  transform: translateY(0.5px) rotate(-45deg);
 }
 &:last-child {
  transform: translateY(-0.5px) rotate(45deg);
 }
`;

export const Arrow = styled.button`
 border: none;
 background-color: transparent;
 cursor: pointer;
 padding: 16px;
 width: 44px;

 &:disabled {
  cursor: not-allowed;
  opacity: 0.2;
 }

 &.right {
  transform: scaleX(-1);
 }

 &:not(:disabled):not(:active):hover ${ArrowHalf}:first-child {
  transform: rotate(-30deg) translateY(0.3px);
 }
 &:not(:disabled):not(:active):hover ${ArrowHalf}:last-child {
  transform: rotate(30deg) translateY(-0.3px);
 }

 &:active ${ArrowHalf}, &:disabled ${ArrowHalf} {
  transform: translateY(0) rotate(0);
 }
`;
