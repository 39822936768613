import styled from "styled-components";
import { TbLogout } from "react-icons/tb";

export const LogoutIcon = styled(TbLogout)`
 color: ${({ theme }) => theme.color};
 font-size: 18px;
 transition: color 500ms ease-in-out;
`;

export const TextOverflow = styled.span`
 display: block;
 text-overflow: ellipsis;
 overflow: hidden;
 white-space: nowrap;
`;

export const User = styled.div`
 position: relative;

 display: grid;
 place-items: center;
 align-items: center;

 width: 40px;
 height: 40px;

 border-radius: 50%;
 background-color: ${(props) => props.theme.accentColor};
 border: 1px solid ${(props) => props.theme.accentColor};
 cursor: pointer;

 color: ${(props) => props.theme.background};
 font-size: 22px;
 font-weight: 500;

 transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

 &:hover {
  background-color: ${(props) => props.theme.background};

  color: ${(props) => props.theme.accentColor};
 }
`;

export const UserMenu = styled.div`
 position: absolute;
 top: 80px;
 right: 0;

 display: flex;
 flex-direction: column;
 gap: 10px;

 width: 335px;
 padding: 20px;

 transform: translateX(100%);

 background-color: ${(props) => props.theme.background};
 border: 1px solid ${(props) => props.theme.outline};
 border-radius: 4px;

 transition: transform 250ms ease-in-out;

 &.active {
  transform: translateX(0);
 }
`;

export const UserMenuBtn = styled.div`
 display: flex;
 align-items: center;
 gap: 15px;

 color: ${(props) => props.theme.color};
 padding: 10px;
 font-weight: 400;
 transition: color 250ms ease-in-out;
 cursor: pointer;

 &:hover {
  color: ${(props) => props.theme.accentColor};
 }
`;

export const UserContainer = styled.div`
 position: absolute;
 overflow: hidden;
 top: 0;
 left: 0;
 width: 100%;
 height: 100vh;
 pointer-events: none;

 &.active {
  pointer-events: all;
 }
`;

export const UserName = styled.div`
 display: grid;
 grid-template-columns: 4fr 1fr;
 align-items: center;
 gap: 20px;
 margin-bottom: 10px;
 padding-inline: 10px;
`;
