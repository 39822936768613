export const videos = [
 {
  link: "https://www.youtube.com/embed/IdyVE_ySbdQ?si=C5530OzdXnXybxTp",
  title: "Master SEO with Seoll\u2011e's AI-Powered Keyword Generation Tool",
  description:
   "SEO optimization is now easier than ever with Seoll\u2011e's AI Keyword Generation tool! Learn how to discover high-impact keywords, analyze keyword difficulty, and tailor your SEO strategy for maximum reach. This video guides you through the process of generating relevant keywords for any topic, enhancing your content's visibility and search engine ranking. Start optimizing your content today with Seoll\u2011e!",
 },
 {
  link: "https://www.youtube.com/embed/pekXH-sm4is?si=N9HEjZB6t8RWPTkh",
  title: "Effortless AI Blog Writing with Seoll\u2011e",
  description: `Step into the future of blogging with Seoll\u2011e's AI Post Generation feature. This video unveils the power of AI in crafting high-quality, SEO-optimized blog posts in a matter of minutes. Covering a wide range of styles, Seoll\u2011e offers the flexibility to generate content in over 100 languages, ensuring your blog's global reach. Whether you're battling writer's block or looking to scale up your content production, discover how Seoll\u2011e can transform your blogging process, delivering engaging and varied content that resonates with your audience. Join us as we demonstrate how you can streamline your content creation and keep your blog thriving with minimal effort.`,
 },
 {
  link: "https://www.youtube.com/embed/bH2zaoYrUe4?si=A3Xm6ZHzdtClTZBk",
  title:
   "Create Stunning Images for Your Blog with Seoll\u2011e's AI Image Generator",
  description:
   "Elevate your blog posts with visually captivating images created by Seoll\u2011e's AI Image Generation tool. This video showcases how to generate custom images in various styles and sizes, including options with or without people. Perfect for bloggers and content creators seeking to add a visual edge to their posts. Discover the power of AI in bringing your creative visions to life with Seoll\u2011e!",
 },
 {
  link: "https://www.youtube.com/embed/xypVP7iHTpQ?si=8Pue2gLBP2rCAo49",
  title:
   "Automate Your Blogging Schedule with Seoll\u2011e's Postponed Posts Generation",
  description:
   "Stay ahead of your blogging schedule with Seoll\u2011e's Postponed Posts Generation feature. In this video, learn how to set up automatic content generation and scheduling for your blog. Ideal for maintaining a consistent online presence and keeping your site fresh with regular updates. Say goodbye to last-minute content creation and hello to streamlined, efficient blogging with Seoll\u2011e!",
 },
 {
  link: "https://www.youtube.com/embed/mrzbT2-g_HM?si=cnBbu3d9F7mw3rd7",
  title:
   "Seamless WIX & WordPress Integration with Seoll\u2011e for Effortless Content Management",
  description:
   "Streamline your content management with Seoll\u2011e's seamless integration into WIX and WordPress. This video walks you through the simple steps to connect your Seoll\u2011e account with your website, enabling one-click post transfers and automated updates. Enhance your content publishing workflow and manage your website more efficiently with Seoll\u2011e's integration capabilities.",
 },
 {
  link: "https://www.youtube.com/embed/zxQDxmre_9U?si=y19X8RAbE-bN4Pa0",
  title:
   "Enrich Your Blog Posts with Seoll\u2011e's Dynamic Content Blocks Generation",
  description:
   "Discover how to enhance your blog posts with Seoll\u2011e's Content Blocks Generation feature. This video demonstrates adding FAQ sections, comparison tables, and book summaries to your posts. Perfect for bloggers and content creators looking to diversify their content and engage readers more effectively. Unlock the potential of your blog posts with the versatility of Seoll\u2011e's content blocks!",
 },
 {
  link: "https://www.youtube.com/embed/ywlDTpMmIdA?si=b2UBrArJmr8gpvKI",
  title:
   "Transform Your Blog Posts into Audio with Seoll\u2011e's AI Audio Generation",
  description:
   "Dive into the world of AI-driven audio content with Seoll\u2011e! In this video, we explore how you can effortlessly convert your written blog posts into engaging audio formats. Perfect for on-the-go audiences and enhancing accessibility, Seoll\u2011e's audio generation feature supports multiple languages and voice styles. Discover how to give your content a voice and reach a wider audience with just a few clicks.",
 },
];
