import { loginAdmin } from "../../utils/api/loginAdmin";
import axios from "../../utils/api/axios-config";
import { setErrorMessage } from "../../utils/error-message";
import {
 checkAuthenticated,
 checkAuthenticatedSuccess,
 failSetCredits,
 login,
 loginSuccess,
 registrationUser,
 registrationUserSuccess,
 setCredits,
 setEmailMe,
 setFreeTrial,
 setName,
 userFailure,
} from "./userSlice";
import { checkUserAuthenticated } from "../../utils/api/checkAuth";
import Notiflix from "notiflix";
import { bindPost } from "../../utils/api/getPost";

export const getUserInfo = () => async (dispatch) => {
 const token = localStorage.getItem("access");

 if (!token) {
  return;
 }

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  const response = await axios.get("users/get-user-info/", { headers });
  dispatch(setCredits(response.data.credits));
  dispatch(setName(response.data.full_name));
  dispatch(setEmailMe(response.data.email_me));
  dispatch(setFreeTrial(response.data.free_trial));
 } catch (error) {
  const err = setErrorMessage(error);
  dispatch(failSetCredits(err));
 }
};

export const putEmailMe =
 ({ email_me }) =>
 async (dispatch) => {
  const token = localStorage.getItem("access");

  if (!token) {
   return;
  }

  const headers = {
   "Content-Type": "application/json",
   Authorization: `Bearer ${token}`,
  };

  try {
   await axios.put("users/get-user-info/", { email_me }, { headers });
   dispatch(setEmailMe(email_me));
  } catch (error) {
   const err = setErrorMessage(error);
   dispatch(failSetCredits(err));
  }
 };

export const checkAuth = (showMessage) => async (dispatch) => {
 dispatch(checkAuthenticated());
 const success = await checkUserAuthenticated();
 if (success) {
  dispatch(checkAuthenticatedSuccess());
 } else {
  if (showMessage) dispatch(userFailure("Please log in or create an account"));
 }
};

export const loginUser =
 ({ email, password }) =>
 async (dispatch) => {
  dispatch(login());
  try {
   const userCheck = await axios.post("users/check/", { email, password });
   if (userCheck.data.success) {
    const response = await axios.post("token/", { email, password });
    localStorage.setItem("access", response.data.access);
    localStorage.setItem("refresh", response.data.refresh);
    localStorage.setItem("email", email);
    dispatch(loginSuccess());
   } else {
    dispatch(userFailure(userCheck.data.message));
   }
  } catch (e) {
   const err = setErrorMessage(e.response.data);
   dispatch(userFailure(err));
  }
 };

export const activateAccount =
 ({ id, email }, token) =>
 async (dispatch) => {
  dispatch(login());
  try {
   const response = await axios.post(
    "users/registration/account-activation/",
    { id, email },
    { headers: { Authorization: `Bearer ${token}` } }
   );
   localStorage.setItem("access", response.data.access_token);
   localStorage.setItem("refresh", response.data.refresh_token);
   localStorage.setItem("email", email);

   dispatch(loginSuccess());
  } catch (e) {
   const err = setErrorMessage(e.response.data);
   dispatch(userFailure(err));
  }
 };

export const loginSocial =
 ({ email, id }, token) =>
 async (dispatch) => {
  dispatch(login());
  try {
   const response = await axios.post(
    "users/social/get-token/",
    {
     email,
     id,
    },
    {
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
     },
    }
   );
   localStorage.setItem("access", response.data.access_token);
   localStorage.setItem("refresh", response.data.refresh_token);
   localStorage.setItem("email", email);
   dispatch(loginSuccess());
  } catch (e) {
   const err = setErrorMessage(e.response.data);
   dispatch(userFailure(err));
  }
 };

export const registrationNewUser =
 ({ email, password, name }) =>
 async (dispatch) => {
  dispatch(registrationUser());
  try {
   const token = await loginAdmin();
   await axios.post(
    "users/registration/",
    {
     email,
     password,
     first_name: name,
    },
    {
     headers: {
      Authorization: `Bearer ${token}`,
     },
    }
   );
   await bindPost(email);
   dispatch(registrationUserSuccess());
   Notiflix.Report.success(
    "Registration Successful",
    "Registration is successful! Please confirm your email address using the link we sent you to the email address you provided!"
   );
  } catch (e) {
   const err = setErrorMessage(e.response.data);
   dispatch(userFailure(err));
  }
 };
