import { useEffect, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import { darkTheme, lightTheme } from "./utils/themes";
import { ThemeContext, ThemeProvider } from "styled-components";
import { usePersistedTheme } from "./hooks/useTheme";
import Loading from "./pages/Loading";
import { checkAuth, getUserInfo } from "./store/user/userActions";
import {
 getAudioSettings,
 getImgSettings,
 getSitemapSettings,
 getTextSettings,
 getWixSettings,
 getWordpressSettings,
} from "./store/settings/settingsActions";
import Feedback from "./components/modals/Feedback/Feedback";
import Notiflix from "notiflix";
import { clearError, logoutUser } from "./store/user/userSlice";
import SuccessfulPayment from "./pages/SuccessfulPayment/SuccessfulPayment";
import InactivityTimer from "./pages/Timer";
import Cookies from "./components/modals/Cookies/Cookies";
import { GlobalStyles } from "./components/Global.styled";
import NotiflixInit from "./components/NotiflixInit";
import { restrictedPages } from "./data/restrictedPages";
import { checkUserAuthenticated } from "./utils/api/checkAuth";
import {
 getSubscription,
 getTestAccounts,
 listPaymentMethods,
} from "./store/stripe/stripeActions";
import { adClick } from "./utils/api/ad-click";

const WordpressIntegration = lazy(() => import("./pages/WordpressIntegration"));
const Generate = lazy(() => import("./pages/Generate/Generate"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const ConfirmRegistration = lazy(() => import("./pages/ConfirmRegistration"));
const ConfirmEmail = lazy(() => import("./pages/ConfirmEmail"));
const Transactions = lazy(() => import("./pages/Transactions/Transactions"));
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));
const Home = lazy(() => import("./pages/Home/Home"));
const SignUp = lazy(() => import("./pages/SignUp/SignUp"));
const Pay = lazy(() => import("./pages/Pay/Pay"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy/CookiePolicy"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const EULA = lazy(() => import("./pages/EULA/EULA"));
const Terms = lazy(() => import("./pages/Terms/Terms"));
const TryAgain = lazy(() => import("./pages/TryAgain/TryAgain"));
const Example = lazy(() => import("./pages/Example"));
const Keywords = lazy(() => import("./pages/Keywords/Keywords"));
const PDFView = lazy(() => import("./pages/PDFView"));
const KeywordsHistory = lazy(() =>
 import("./pages/KeywordsHistory/KeywordsHistory")
);
const FreeTrial = lazy(() => import("./pages/FreeTrial/FreeTrial"));
const AddCardPage = lazy(() => import("./pages/AddCardPage/AddCardPage"));

function App() {
 const [theme, setTheme] = usePersistedTheme("theme", lightTheme);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
 const error = useSelector((state) => state.user.error);

 //  useEffect(() => {
 //   const currentUrl = window.location.href;
 //   const targetUrl = "https://seoll-e.ai/";

 //   if (currentUrl.startsWith(targetUrl)) {
 //    const canonicalLink = document.createElement("link");
 //    canonicalLink.setAttribute("rel", "canonical");
 //    canonicalLink.setAttribute("href", targetUrl);
 //    document.head.appendChild(canonicalLink);
 //   }
 //  }, []);

 useEffect(() => {
  const fetchAdClickId = async () => {
   let referrer = "";
   const isFirstLoad = !sessionStorage.getItem("hasLoadedBefore");
   if (isFirstLoad) {
    referrer = document.referrer;
    sessionStorage.setItem("hasLoadedBefore", "true");
   }

   if (
    window.location.href.includes("seoll-e.ai/?") ||
    (!referrer.includes("seoll-e-test.web.app") &&
     referrer !== "" &&
     !referrer.includes("seoll-e.ai") &&
     !referrer.includes("seo-tool-5f475.web.app") &&
     !referrer.includes("seoll-e-test2.web.app"))
   ) {
    const data = referrer
     ? window.location.href + `&referrer=${referrer}`
     : window.location.href;
    const adClickId = await adClick(data, "transition");
    localStorage.setItem("adClickId", adClickId.id);
   }
  };
  fetchAdClickId();
 }, []);

 useEffect(() => {
  if (window.location.hostname === "localhost") {
   return;
  }

  async function checkUserOnLoad() {
   const auth = await checkUserAuthenticated();
   if (!auth) {
    dispatch(logoutUser());
    window.location.search.includes("blog")
     ? navigate("/", { state: { openLogin: true } })
     : navigate("/");
   }
  }

  if (restrictedPages.includes(window.location.pathname)) {
   checkUserOnLoad();
   const interval = setInterval(() => checkUserOnLoad(), 300000);

   return () => clearInterval(interval);
  }
 }, [dispatch, navigate]);

 useEffect(() => {
  document.addEventListener("touchstart", function () {}, true);
 }, []);

 useEffect(() => {
  window.history.scrollRestoration = "manual";
 }, []);

 const toggleTheme = () => {
  setTheme(theme === lightTheme ? darkTheme : lightTheme);
  const { pathname } = window.location;
  if (pathname !== "generate") window.scrollBy(0, 1);
 };

 useEffect(() => {
  if (error) {
   if (error === "Наданий токен не відповідає жодному типу ключа") {
    Notiflix.Report.info("Session expired", "Please log in", "Okay", () =>
     navigate("/")
    );
    return;
   }
   Notiflix.Notify.failure(error);
   dispatch(clearError());
  }
 }, [error, navigate, dispatch]);

 useEffect(() => {
  dispatch(checkAuth());
 }, [dispatch]);

 useEffect(() => {
  if (isAuthenticated) {
   dispatch(getUserInfo());
   dispatch(getImgSettings());
   dispatch(getAudioSettings());
   dispatch(getTextSettings());
   dispatch(getWordpressSettings());
   dispatch(getSitemapSettings());
   dispatch(getWixSettings());
   dispatch(getTestAccounts());
  }
 }, [isAuthenticated, dispatch]);

 useEffect(() => {
  function connectStripe() {
   if (isAuthenticated && localStorage.getItem("email")) {
    dispatch(getSubscription());
    dispatch(listPaymentMethods());
    return;
   }
   setTimeout(connectStripe, 200);
  }

  connectStripe();
 }, [dispatch, isAuthenticated]);

 return (
  <ThemeContext.Provider value={{ theme, toggleTheme }}>
   <ThemeProvider theme={theme}>
    <GlobalStyles />
    <NotiflixInit />
    <Suspense fallback={<Loading />}>
     <Routes>
      <Route path="/settings" element={<Settings />} />
      <Route path="/free-trial" element={<FreeTrial />} />
      <Route path="/add-card" element={<AddCardPage />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/generate" element={<Generate />} />
      <Route path="/wordpress_integration" element={<WordpressIntegration />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/completion" element={<SuccessfulPayment />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/eula" element={<EULA />} />
      <Route path="/terms-and-conditions" element={<Terms />} />
      <Route path="/try-again" element={<TryAgain />} />
      <Route path="/keywords-generator" element={<Keywords />} />
      <Route
       path="/keywords-generator/history/"
       element={<KeywordsHistory />}
      />
      <Route path="/pdf" element={<PDFView />} />

      <Route path="/payment/:id" element={<Pay />} />
      <Route path="/confirm/:email" element={<ConfirmEmail />} />
      <Route path="/reset/:email" element={<ResetPassword />} />
      <Route path="/example/:exampleId" element={<Example />} />
      <Route path="/:email" element={<ConfirmRegistration />} />

      <Route path="/" element={<Home />} />
     </Routes>
    </Suspense>
    <Feedback />
    <InactivityTimer />
    <Cookies />
   </ThemeProvider>
  </ThemeContext.Provider>
 );
}

export default App;
