import { media } from "../../utils/mixins";
import styled from "styled-components";

export const FAQTitle = styled.h2`
 max-width: none;
 padding-top: 14px;
 margin-bottom: 10px;

 font-size: 26px;
 text-align: left;
 font-weight: 600;
`;

export const FAQContainer = styled.div`
 display: flex;
 gap: 15px;
 flex-direction: column;
 padding-bottom: 30px;
 padding-inline: 16px;

 & h3 {
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 600;
 }

 & p,
 ul,
 li,
 a {
  font-family: ${(props) => props.theme.secondaryFont};
 }

 & p {
  margin-bottom: 20px;
 }

 & ul {
  padding-left: 20px;
 }

 ${media.laptop`
    max-width: 1280px;
    margin-inline: auto;
    padding-inline: 10px;
  `}
`;
