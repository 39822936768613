import {
 BtnBasic,
 Label,
 LabelText,
 Text,
 InputLabeled,
} from "../../Common.styled";
import {
 AccentText,
 AppleIcon,
 Description,
 GoogleIcon,
 LoginInputIconHidden,
 LoginInputIconShown,
} from "../Header.styled";
import { useNavigate } from "react-router";
import { useRef, useState } from "react";
import Notiflix from "notiflix";
import { validateEmail } from "../../../utils/validation";
import { loginUser } from "../../../store/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
 LoginHeaderOverflow,
 LoginHeaderStyled,
 LoginText,
} from "./LoginHeader.styled";
import { sendResetLink } from "../../../utils/api/resetPassword";
import ChangeThemeBtn from "../../ChangeThemeBtn/ChangeThemeBtn";
import SocialLoginBtn from "../../../bricks/SocialLoginBtn";

function LoginHeader({ close, wrapperRef }) {
 const navigate = useNavigate();
 const dispatch = useDispatch();

 const passwordInput = useRef(null);

 const [showPassword, setShowPassword] = useState(false);
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [showPasswordSymbols, setShowPasswordSymbols] = useState(false);

 const isAuthenticated = useSelector((state) => state.user.isAuthenticated);


 useEffect(() => {
  if (isAuthenticated) {
   navigate("/generate");
  }
 }, [isAuthenticated, navigate]);

 function loginEmail(e) {
  e.preventDefault();

  if (!email) {
   Notiflix.Notify.failure("Please enter your email");
   return;
  }

  const err = validateEmail(email);
  if (err) {
   Notiflix.Notify.failure(err);
   return;
  }

  if (!showPassword) {
   setShowPassword(true);
   setTimeout(() => {
    if (passwordInput.current) {
     passwordInput.current.focus();
    }
   }, 0);
   return;
  }

  dispatch(loginUser({ email, password }));
 }

 function sendLink() {
  Notiflix.Confirm.show(
   "Reset Password",
   `Would you like to reset your password for ${email}?`,
   "Yes",
   "No",
   resetPassword
  );
 }

 async function resetPassword() {
  const linkSent = await sendResetLink(email);
  linkSent
   ? Notiflix.Report.success(
      "Password Reset",
      "Check you email for the password reset link",
      "Ok"
     )
   : Notiflix.Report.failure("Something went wrong", "Please try again", "Ok");
 }

 useEffect(() => {
  function handleEnter(e) {
   if (e.code === "Enter" && email) {
    loginEmail();
    setTimeout(() => {
     if (passwordInput.current) {
      passwordInput.current.focus();
     }
    }, 0);
   }
  }
  window.addEventListener("keydown", handleEnter);

  return () => window.removeEventListener("keydown", handleEnter);
 });

 return (
  <LoginHeaderOverflow
   role="dialog"
   aria-modal="true"
   onClick={(e) => {
    if (e.target === e.currentTarget) {
     close();
    }
   }}
  >
   <LoginHeaderStyled
    aria-labelledby="login-title"
    autoComplete="on"
    onSubmit={loginEmail}
    ref={wrapperRef}
    >
    <LoginText>Log in</LoginText>
    <SocialLoginBtn
     icon={<GoogleIcon />}
     text="Sign in with Google"
     url="https://app.seoll-e.ai/accounts/google/login/"
     aria-label="Sign in with Google"
    />
    <SocialLoginBtn
     icon={<AppleIcon />}
     text="Sign in with Apple"
     url="https://app.seoll-e.ai/accounts/apple/login/"
     aria-label="Sign in with Apple"
    />
    <LoginText className="extra-margin">or</LoginText>
    <Label>
     <InputLabeled
      placeholder=" "
      type="email"
      autoComplete="username"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="third-small"
      aria-label="Email address"
     />
     <LabelText>Email</LabelText>
    </Label>
    {showPassword && (
     <Label>
      <InputLabeled
       autoComplete="password"
       placeholder=" "
       value={password}
       onChange={(e) => setPassword(e.target.value)}
       required
       type={showPasswordSymbols ? "text" : "password"}
       className="fourth-small"
       aria-label="Password"
       ref={passwordInput}
      />
      <LabelText>Password</LabelText>
      {showPasswordSymbols ? (
       <LoginInputIconShown
        onClick={() => setShowPasswordSymbols(false)}
        aria-label="Hide password"
       />
      ) : (
       <LoginInputIconHidden
        onClick={() => setShowPasswordSymbols(true)}
        aria-label="Show password"
       />
      )}
     </Label>
    )}
    <BtnBasic
     type="submit"
     className="outlined"
     aria-label="Sign in with email"
    >
     Sign in with email
    </BtnBasic>
    {showPassword && (
     <BtnBasic onClick={sendLink} aria-label="Password Recovery" type="button">
      Password Recovery
     </BtnBasic>
    )}
    <Description>
     <Text>
      By signing in to your account you agree to our{" "}
      <AccentText to="/terms-and-conditions">Terms of Service</AccentText> and
      our <AccentText to="/privacy-policy">Privacy Policy</AccentText>
     </Text>
     <Text>
      Don't have an account yet?
      <AccentText to="/signup"> Sign up</AccentText>
     </Text>
    </Description>
    <ChangeThemeBtn top="12px" right="15px" type="button" />
  
   </LoginHeaderStyled>
  </LoginHeaderOverflow>
 );
}

export default LoginHeader;
