import styled, { css } from "styled-components";
import { Title } from "../Common.styled";
import { media } from "../../utils/mixins";
import { StyledLi } from "../../pages/Settings/Settings.styled";
import { ReactComponent as Star } from "../../assets/img/star.svg";
import { ReactComponent as Flex } from "../../assets/img/flex.svg";
import { ReactComponent as Heart } from "../../assets/img/heart.svg";
import { ReactComponent as Models } from "../../assets/img/models.svg";
import { ReactComponent as Translate } from "../../assets/img/translate.svg";
import { ReactComponent as WP } from "../../assets/img/wp.svg";

const Icon = css`
 color: ${(props) => props.theme.accentColor};
`;

export const StarIcon = styled(Star)`
 ${Icon}
`;
export const FlexIcon = styled(Flex)`
 ${Icon}
`;
export const HeartIcon = styled(Heart)`
 ${Icon}
`;
export const ModelsIcon = styled(Models)`
 ${Icon}
`;
export const TranslateIcon = styled(Translate)`
 ${Icon}
`;
export const WPIcon = styled(WP)`
 ${Icon}
`;

export const InfoContainer = styled.div`
 display: grid;
 gap: 0px;

 padding: 30px 16px 0;

 background: ${({ theme }) => theme.background};

 ${media.laptop`
    max-width: 1280px;
    margin-inline: auto;
    padding-inline: 10px;
    padding-block: 0 61px;
  `}
`;

export const InfoTitle = styled(Title)`
 width: auto;
 max-width: none;
 margin-bottom: 30px;
 margin-top: 0;

 font-size: 36px;
 line-height: 120%;
 color: ${({ theme }) => theme.color};

 ${media.laptop`
  max-width: 686px;
  margin-top: 157px;
  margin-inline: 0;
  margin-bottom: 0;

  font-size: 56px;
 `}
`;

export const InfoTile = styled.div`
 position: relative;

 display: flex;
 flex-direction: column;
 gap: 14px;

 background-color: ${({ theme }) => theme.background};

 color: ${({ theme }) => theme.color};
 font-size: 16px;
 line-height: 1.5;
 font-family: ${(props) => props.theme.secondaryFont};

 transition: all 0.3s ease-in-out;

 &:not(:last-child) {
  margin-bottom: 30px;
 }

 & img {
  display: block;

  width: 48px;
  height: 48px;
 }

 ${media.laptop`
 gap: 8px;
 max-width: 343px;
 line-height: 1.6;
 `}
`;

export const InfoTileAccent = styled.p`
 margin: 0;

 font-size: 26px;
 line-height: 1.3;
 font-family: ${(props) => props.theme.primaryFont};
`;

export const InfoImage = styled.img`
 display: block;
 width: 100%;
 filter: drop-shadow(0px 4px 6px black);

 ${media.laptop`
  width: 407px; 
`};
`;

export const InfoRobotContainer = styled.div`
 ${media.laptop`
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 45px;
  `}

 & ${StyledLi} {
  ${media.laptop`
  max-width: 80%;

  `}
 }
`;

export const InfoTilesContainer = styled.div`
 ${media.laptop`
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 column-gap: 84px;
 row-gap: 64px;
`}
`;
