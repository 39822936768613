import React, { forwardRef } from "react";
import {
 CreditContainer,
 CreditSubtitle,
 CreditTitle,
 PricingContainer,
 PricingDesc,
 PricingDescSpan,
 PricingImg,
 PricingMainTitle,
 PricingTile,
 PricingUl,
 SmallTile,
 SmallTiles,
} from "./Pricing.styled";
import {
 BackgroundContainer,
 Bold,
 Light,
 OutlinedBtn,
} from "../Common.styled";
import Coins from "../../assets/img/coins.svg";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const Pricing = forwardRef((_, ref) => {
 const navigate = useNavigate();
 const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

 return (
  <BackgroundContainer>
   <PricingContainer ref={ref}>
    <PricingMainTitle>Pricing</PricingMainTitle>
    <PricingTile>
     Free trial
     <PricingUl>
      <PricingDesc>Free 14-day trial</PricingDesc>
      <PricingDesc>500 credits to try</PricingDesc>
     </PricingUl>
     {isAuthenticated || (
      <OutlinedBtn onClick={() => navigate("/signup")}>Try now</OutlinedBtn>
     )}
    </PricingTile>
    <PricingTile>
     Paid packages
     <PricingDescSpan>
      <Bold>Budget</Bold>
      <Light>$10/month for 1.000 credits</Light>
     </PricingDescSpan>
     <PricingDescSpan>
      <Bold>Growth</Bold>
      <Light>$30/month for 5.000 credits</Light>
     </PricingDescSpan>
     <PricingDescSpan>
      <Bold>Pro</Bold>
      <Light>$100/month for 20.000 credits</Light>
     </PricingDescSpan>
     {isAuthenticated || (
      <OutlinedBtn onClick={() => navigate("/signup")} className="mt58">
       Register to purchase
      </OutlinedBtn>
     )}
    </PricingTile>
    <CreditContainer>
     <CreditTitle>
      <PricingImg
       src={Coins}
       alt="Coins image, representing credit usage in the pricing section."
       loading="lazy"
      />
      Credit usage
     </CreditTitle>
     <div>
      <CreditSubtitle>Text</CreditSubtitle>
      <PricingDescSpan className="no-margin">
       <Bold>10 credits</Bold>
       <Light>GPT-3.5 powered post, 3000 words max</Light>
      </PricingDescSpan>
      <PricingDescSpan className="no-margin">
       <Bold>50 credits</Bold>
       <Light>GPT-4 based post, 3000 words max</Light>
      </PricingDescSpan>
     </div>
     <div>
      <CreditSubtitle>Image</CreditSubtitle>
      <PricingDescSpan className="no-margin">
       <Bold>5 credits / image</Bold>
       <Light>10 models, including:</Light>
      </PricingDescSpan>
      <SmallTiles>
       <SmallTile>OpenJourney v4</SmallTile>
       <SmallTile>Future Diffusion</SmallTile>
       <SmallTile>StableDiffusion XL</SmallTile>
       <SmallTile>RealisticVision v4.0</SmallTile>
      </SmallTiles>
     </div>
     <div>
      <CreditSubtitle style={{ marginTop: "5px" }}>Content elements</CreditSubtitle>
      <PricingDescSpan>
       <Bold>5 credits / element</Bold>
      </PricingDescSpan>
      <SmallTiles style={{ marginTop: "-10px" }}>
       <SmallTile>Audio version</SmallTile>
       <SmallTile>Comparison table</SmallTile>
       <SmallTile>Book references</SmallTile>
       <SmallTile>FAQ section</SmallTile>
      </SmallTiles>
     </div>
     <div>
      <CreditSubtitle className="keywords">Keyword Difficulty</CreditSubtitle>
      <PricingDescSpan className="no-margin">
       <Bold>5 credits / search</Bold>
       <Light>Valuable insight for SEO strategy</Light>
      </PricingDescSpan>
     </div>
    </CreditContainer>
   </PricingContainer>
  </BackgroundContainer>
 );
});

export default Pricing;
