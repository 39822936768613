import styled from "styled-components";
import { StarRating } from "../modals/Feedback/Feedback.styled";
import { OutlinedBtn } from "../Common.styled";
import { media } from "../../utils/mixins";

export const ReviewsContainer = styled.div`
 display: grid;
 grid-template-columns: 1fr;
 column-gap: 30px;
 row-gap: 20px;
 margin-top: 20px;
 padding-inline: 10px;

 & ${OutlinedBtn} {
  margin-top: 20px;
 }

 ${media.laptop`
 grid-template-columns: repeat(3, 1fr);
 margin-top: 50px;
 row-gap: 50px;


  & ${OutlinedBtn} {
  grid-column: 2;
 }
 `}
`;

export const Review = styled.div`
 display: flex;
 flex-direction: column;
 gap: 15px;

 & ${StarRating} {
  margin-bottom: 0;
 }
`;

export const ReviewText = styled.p``;

export const ReviewTitle = styled.h3`
 font-weight: normal;
 text-align: center;
 font-size: 36px;

 background-color: ${(props) => props.theme.color};
 color: ${(props) => props.theme.background};
 border-radius: 4px;
 padding: 4px;

 &.invert {
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
 }

 ${media.laptop`
  grid-column: span 3;
 `}
`;

export const StatsContainer = styled.div`
 & ul {
  display: grid;
  align-items: center;

  list-style: none;

  font-size: 20px;
  text-align: center;

  & li.title {
   order: -1;
   margin-bottom: 20px;
  }

  & li.rating {
   display: none;
  }

  ${media.laptop`
    grid-template-columns: repeat(3, 1fr);


    & li.title {
   order: 0;
   margin-bottom: 20px;
  }

  & li.rating {
   display: block;
  }
 `}
 }

 ${media.laptop`
  grid-column: span 3;
 `}
`;
