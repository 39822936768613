import styled from "styled-components";
import { Btn, InputBasic } from "../../components/Common.styled";
import { media } from "../../utils/mixins";

export const Setting = styled.p`
 margin-bottom: 10px;
 color: ${(props) => props.theme.color};
`;

export const SettingsTab = styled.div`
 &.desktop-only {
  display: none;

  ${media.laptop`
  display: block;
  padding: 10px 20px;
  text-align: left;
  `}
 }
`;

export const MobileText = styled.p`
 ${media.laptop`
    display: none;
  `}
`;

export const TabBtn = styled.button`
 position: relative;

 padding: 5px 20px;
 width: 100%;

 background-color: ${({ theme }) => theme.background};
 border: 2px solid ${({ theme }) => theme.accentColor};
 border-radius: 4px;

 overflow: hidden;

 color: ${({ theme }) => theme.color};
 font-size: 18px;

 cursor: pointer;

 transition: all 0.3s ease;

 &:before {
  content: "";

  position: absolute;
  top: -120%;
  left: -120%;

  width: 150%;
  height: 150%;

  background-color: rgba(255, 255, 255, 0);
  background-image: -webkit-gradient(
   linear,
   left top,
   right bottom,
   color-stop(0, #fff),
   color-stop(70%, rgba(255, 255, 255, 0))
  );
  background-image: -webkit-linear-gradient(
   45deg,
   #fff 0%,
   rgba(255, 255, 255, 0) 70%
  );
  background-image: -moz-linear-gradient(
   45deg,
   #fff 0%,
   rgba(255, 255, 255, 0) 70%
  );
  background-image: -o-linear-gradient(
   45deg,
   #fff 0%,
   rgba(255, 255, 255, 0) 70%
  );
  background-image: linear-gradient(45deg, #fff 0%, rgba(255, 255, 255, 0) 70%);

  transform: rotate(45deg);

  transition: all 0.3s ease;
 }

 &:hover,
 &.active {
  background-color: ${({ theme }) => theme.accentColor};
  color: white;
 }

 &:hover:before,
 &.active:before {
  top: -30%;
  left: -30%;
 }
`;

export const TabBtns = styled.div`
 &.desktop-only {
  display: none;

  ${media.laptop`
  display: flex;
  width: 100%;
  `}
 }

 display: flex;
 gap: 10px;
 min-width: 70px;
 margin-bottom: 20px;
 width: 100%;

 ${media.laptop`
 width: 30%;
 `}
`;

export const Button = styled(Btn)`
 display: block;
 margin: 20px;
 margin-inline: auto;
`;

export const SettingsInput = styled(InputBasic)`
 padding-inline: 10px;

 &::placeholder {
  color: ${(props) => props.theme.colorSecondary};
 }
`;
export const SettingsSelect = styled.select`
 cursor: pointer;
 min-height: 48px;
 height: 48px;
 padding: 0.65em 10px; //for Firefox
 background-color: ${({ theme }) => theme.background};
 border-radius: 4px;
 outline: none;
 border: 1px solid ${(props) => props.theme.outline};
 font-size: 16px;
 color: ${(props) => props.theme.color};
`;

export const SettingsOption = styled.option`
 background-color: ${({ theme }) => theme.background};
 color: ${({ theme }) => theme.color};

 &:disabled {
  background-color: ${({ theme }) => theme.bcg4};
 }
`;

export const SettingsLabel = styled.label`
 display: grid;
 grid-template-columns: 1fr 1fr;
 column-gap: 10px;
 row-gap: 10px;
 align-items: center;

 width: 100%;
 margin-bottom: 10px;
`;

export const InstructionContainer = styled.div`
 margin-top: 2rem;
 padding: 2rem;

 border-radius: 10px;

 line-height: 1.5;
 color: ${(props) => props.theme.color};
 font-size: 1.1rem;
`;

export const StyledOl = styled.ol`
 list-style: none;
 width: 100%;
`;

export const StyledLi = styled.li`
 position: relative;

 list-style: none;

 display: grid;
 align-items: start;
 grid-template-columns: 30px 1fr;
 column-gap: 20px;

 margin-bottom: 1em;
 padding: 5px 10px;

 overflow: hidden;
 cursor: default;

 font-size: 16px;
 font-family: ${(props) => props.theme.secondaryFont};
 color: ${(props) => props.theme.color};

 transition: all 0.3s ease;

 ${media.laptop`
  font-size: 26px;
`}
`;

export const Text = styled.p`
 margin-block: 15px;

 font-size: 20px;
 text-align: center;
`;

export const SettingsBtns = styled.div`
 display: flex;
 gap: 15px;
 flex-direction: column;
 align-items: center;
 margin-block: 50px;

 ${media.laptop`
    flex-direction: row;
  `}
`;

export const OlTitle = styled.p`
 font-size: 26px;
 margin-bottom: 25px;
`;
