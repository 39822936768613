import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./user/userSlice";
import settingsSlice from "./settings/settingsSlice";
import filtersSlice from "./filters/filtersSlice";
import searchSlice from "./search/searchSlice";
import feedbackSlice from "./feedback/feedbackSlice";
import stripeSlice from "./stripe/stripeSlice";

const rootReducer = combineReducers({
 user: userSlice,
 settings: settingsSlice,
 filters: filtersSlice,
 search: searchSlice,
 feedback: feedbackSlice,
 stripe: stripeSlice,
});

export const setupStore = () => {
 return configureStore({
  reducer: rootReducer,
 });
};
