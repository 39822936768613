import styled from "styled-components";

export const LoginHeaderStyled = styled.form`
 position: absolute;
 top: 106px;
 right: 2px;

 display: flex;
 flex-direction: column;
 gap: 20px;

 width: 330px;
 padding: 21px;

 overflow: visible;

 border-radius: 8px;
 border: 1px solid ${(props) => props.theme.outline};
 background-color: ${(props) => props.theme.background};
`;

export const LoginHeaderOverflow = styled.div`
 position: absolute;
 top: 0;
 left: 0;

 width: 100%;
 height: 100vh;
`;

export const LoginText = styled.p`
 color: ${(props) => props.theme.color};
 font-size: 20px;
 line-height: 100%;

 &.extra-margin {
  margin-block: 7px;
 }
`;
