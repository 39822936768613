import { loginAdmin } from "./loginAdmin";
import axios from "./axios-config";

export const contactUs = async ({ email, message }) => {
 const token = await loginAdmin();

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  const response = await axios.post(
   "users/communication/contact-us/",
   {
    email,
    message,
   },
   { headers }
  );
  return response.data;
 } catch (error) {}
};

export const sendFeedback = async ({ message, rating }) => {
 const token = localStorage.getItem("access");

 const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
 };

 try {
  const response = await axios.post(
   "users/communication/feedback/",
   {
    message,
    rating,
   },
   { headers }
  );
  return response.data;
 } catch (error) {}
};
