import styled from "styled-components";
import { media } from "../utils/mixins";
import { AiFillCloseCircle } from "react-icons/ai";
import { ReactComponent as Check } from "../assets/img/checkCircle.svg";

export const Hr = styled.span`
 display: block;

 width: 100%;
 height: 1px;

 background-color: ${(props) => props.theme.outline};

 transition: background-color 250ms ease-in-out;

 &.user-menu {
  width: 120%;
  transform: translateX(-20px);
 }
`;

export const Btn = styled.button`
 align-items: center;
 justify-content: center;

 width: 150px;

 padding: 10px 14px;

 border: 1px solid ${({ theme }) => theme.lightestBcg};
 border-radius: 10px;

 outline: none;
 cursor: pointer;

 font-size: 12px;
 font-weight: 500;
 text-decoration: none;
 color: ${({ theme }) => theme.color};

 background-color: ${({ theme }) => theme.background};

 transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out,
  background-color 500ms ease-in-out, color 500ms ease-in-out,
  border 500ms ease-in-out;

 &:hover {
  box-shadow: 2px 2px 2px ${({ theme }) => theme.lightestBcg};
  transform: translateY(-2px);
 }

 &:active {
  box-shadow: 1px 1px 1px ${({ theme }) => theme.lightestBcg};
  transform: translateY(-1px);
 }

 &:disabled {
  color: ${({ theme }) => theme.disabledText};
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
  background-color: ${({ theme }) => theme.disabled};
 }

 &:disabled:hover,
 &:disabled:active {
  box-shadow: none;
  transform: none;
 }

 ${media.tablet`
    font-size: 14px;
  `}
`;

export const Copy = styled.img`
 display: block;
 width: 100%;
`;

export const CopyContainer = styled.div`
 display: none;
 place-items: center;

 width: 32px;
 height: 32px;

 cursor: pointer;

 background-color: ${({ theme }) => theme.background};

 transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out,
  background-color 500ms ease-in-out, color 200ms ease-in-out;

 &.mobile {
  display: grid;
 }

 ${media.laptop`
    display: grid;
    right: 20;
  &.mobile {
    display: none;
    }
  `}
`;

export const Title = styled.p`
 max-width: 150px;
 margin-inline: auto;
 margin-bottom: 20px;

 font-size: 16px;
 color: ${({ theme }) => theme.color};
 font-weight: 300;
 text-align: center;

 transition: color 500ms ease-in-out;

 ${media.largeMobile`
 max-width: 250px;
  font-size: 18px;
  `}

 ${media.tablet`
    font-size: 20px;
  `}

 ${media.desktop`
    font-size: 24px;
    max-width: 350px;
text-align: center;
  `}
`;

export const Container = styled.div`
 position: relative;

 width: 100vw;
 min-height: calc(100vh - 254.6px);

 padding: 70px 16px 10px;

 overflow-x: hidden;

 background-color: ${({ theme }) => theme.background};
 color: ${({ theme }) => theme.color};

 text-align: center;

 transition: background-color 500ms ease-in-out;

 ${media.laptop`
    padding-inline: 10px;
    max-width: 1280px;
    margin-inline: auto;
  `}
`;

export const CloseIcon = styled(AiFillCloseCircle)`
 position: absolute;
 top: 5px;
 right: 5px;
 z-index: 400;

 padding: 5px;

 font-size: 40px;

 cursor: pointer;
 color: ${(props) => props.theme.color};

 transition: color 250ms ease-in-out;
`;

export const ModalOverflow = styled.div`
 position: fixed;
 left: 0;
 top: 0;

 display: grid;
 place-items: center;

 z-index: 20;
 width: 100%;
 height: 100%;

 overflow: auto;

 background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContainer = styled.div`
 position: relative;

 display: flex;
 flex-direction: column;
 gap: 20px;
 align-items: center;

 max-width: 600px;
 width: 95%;
 padding: 50px 20px;
 margin-inline: auto;

 border: 2px solid ${({ theme }) => theme.outline};
 border-radius: 4px;
 background-color: ${({ theme }) => theme.background};

 color: ${({ theme }) => theme.color};
 font-size: 14px;

 ${media.tablet`
 width: 70%;
 padding: 40px;

 font-size: 16px;
 
 `}
`;

export const BtnBasic = styled.button`
 display: flex;
 justify-content: flex-start;
 align-items: center;
 gap: 10px;

 width: 100%;
 min-height: 48px;
 padding: 4px 12px;

 border-radius: 4px;
 border: 1px solid ${({ theme }) => theme.outline};
 background-color: ${({ theme }) => theme.background};
 cursor: pointer;
 outline: none;

 font-weight: 400;
 font-size: 16px;
 font-family: ${({ theme }) => theme.secondaryFont};
 color: ${(props) => props.theme.color};

 transition: all 250ms ease-in-out;

 &:hover {
  border-color: ${(props) => props.theme.outline};
 }

 &.large-font {
  justify-content: center;

  font-size: 20px;
  line-height: 1.8;
  font-family: ${(props) => props.theme.mainFont};
 }

 &.outlined {
  border-color: ${(props) => props.theme.accentColor};
 }

 &.h72 {
  min-height: 72px;
  border-width: 2px;
 }

 &.sick-border {
  justify-content: space-between;
  min-width: 193px;
  border-width: 2px;
 }

 &.centered {
  justify-content: center;
 }

 &:disabled {
  cursor: not-allowed;
  color: ${({ theme }) => theme.outline};
  &:hover {
   border-color: ${(props) => props.theme.accentColor};
  }
 }

 &.max-width {
  max-width: 220px;
 }
`;

export const Text = styled.span`
 margin: 0;
`;

export const InputBasic = styled.input`
 width: 100%;
 padding: 4px 12px;
 min-height: 48px;

 border-radius: 4px;
 border: 1px solid ${({ theme }) => theme.outline};
 background-color: ${({ theme }) => theme.background};

 font-size: 16px;
 font-family: ${({ theme }) => theme.secondaryFont};
 color: ${(props) => props.theme.color};

 cursor: pointer;

 &.gray {
  background-color: ${(props) => props.theme.bcg4};

  font-size: 18px;
  line-height: 1.8;
 }

 &::placeholder {
  font-size: 16px;
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.secondaryFont};
 }

 &:disabled {
  background-color: ${(props) => props.theme.bcg4};
 }
`;

export const Label = styled.label`
 position: relative;
 width: 100%;
 overflow: visible;
`;

export const LabelText = styled.span`
 position: absolute;
 top: 14px;
 left: 12px;

 display: block;
 padding-inline: 10px;

 background-color: ${({ theme }) => theme.background};

 font-size: 26px;

 transition: all 500ms ease-in-out;

 ${media.laptop`
 top: 22px;
 left: 18px;
 line-height: 100%;
 `}
`;

export const AccentBtn = styled.button`
 width: 100%;
 min-height: 72px;
 cursor: pointer;

 background-color: ${({ theme }) => theme.accentColor};
 border-radius: 4px;
 border: none;
 outline: none;

 font-size: 28px;
 line-height: 1;
 color: ${({ theme }) => theme.background};
 font-weight: 400;
`;

export const OutlinedBtn = styled.button`
 width: 100%;

 background: transparent;
 outline: none;
 border: 2px solid ${(props) => props.theme.accentColor};
 border-radius: 4px;
 cursor: pointer;
 padding: 16px;

 color: ${(props) => props.theme.color};
 text-align: center;
 font-size: 22px;
 line-height: 100%;
 font-weight: 400;
 transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

 &:hover {
  background-color: ${(props) => props.theme.accentColor};
  color: ${(props) => props.theme.background};
 }

 &.package {
  width: 130px;
  background-color: ${(props) => props.theme.accentColor};
  color: ${(props) => props.theme.background};
  font-size: 18px;
  padding-block: 0;
 }

 &.mt58 {
  margin-top: 8px;
 }

 &.small {
  font-size: 16px;
 }

 &.large {
  font-size: 28px;

  ${media.laptop`
  min-width: 281px;
  min-height: 48px;
 
  font-size: 20px;
  `}
 }

 &:disabled {
  border: 2px solid ${(props) => props.theme.outline};
  color: ${(props) => props.theme.outline};
  font-size: 16px;
  cursor: not-allowed;
 }

 ${media.laptop`
 min-width: 193px;
 padding: 0;
 height: 40px;
 
 font-size: 20px;
 `}

 &.mobile-only {
  ${media.laptop`
    display: none;
  `}
 }

 &.small-width {
  font-size: 18px;
  max-width: 300px;
  margin-inline: auto;
  display: block;
 }

 &.mb40 {
  margin-bottom: 40px;
 }
`;

export const Bold = styled.span`
 font-family: ${(props) => props.theme.secondaryFont};
 font-weight: 500;
`;

export const Light = styled.span`
 color: ${(props) => props.theme.colorSecondary};
 font-family: ${(props) => props.theme.secondaryFont};
`;

export const SmallBtn = styled(BtnBasic)`
 gap: 6px;

 min-height: 60px;
 padding-inline: 7px;

 font-size: 12px;
 text-align: left;
 line-height: 1.3;
 font-weight: 400;

 cursor: pointer;

 ${media.mobile`
 font-size: 15px;
 `}

 &.full-size {
  ${media.laptop`
  gap: 20px;
  min-height: 60px;
  padding-block: 15px;
  `}
  gap: 10px;

  min-height: 48px;
  padding-block: 8px;
  padding-inline: 12px;

  font-size: 16px;
 }
`;

export const LabelBasic = styled.label`
 color: ${(props) => props.theme.color};
 font-size: 14px;
 font-family: ${(props) => props.theme.secondaryFont};
 line-height: 180%;

 &.full-size {
  font-size: 16px;
  text-transform: capitalize;
 }
`;

export const DesktopContainer = styled.div`
 ${media.laptop`
 max-width: 1280px;
  padding-inline: 10px;
  margin-inline: auto;
`}
`;

export const BackgroundContainer = styled.div`
 color: ${(props) => props.theme.color};
 background: ${(props) => props.theme.background};
`;
export const Button = styled.button`
 min-height: 48px;
 min-width: 185px;

 border: 1px solid ${(props) => props.theme.accentColor};
 background: ${(props) => props.theme.background};
 border-radius: 4px;
 cursor: pointer;

 font-family: ${(props) => props.theme.secondaryFont};
 line-height: 1;
 color: ${(props) => props.theme.color};
 font-size: 16px;

 ${media.laptop`
 line-height: 1.8;
 `}
`;

export const InputLabeled = styled(InputBasic)`
 padding: 16px 12px;

 outline: none;

 color: ${({ theme }) => theme.color};
 font-size: 26px;

 &.first-input {
  &:focus
   + ${LabelText},
   &:not(:placeholder-shown)
   + ${LabelText},
   &.forever-focused
   + ${LabelText} {
   top: -17px;
   left: -40px;

   scale: 0.54;
   color: ${({ theme }) => theme.secondaryColor};

   ${media.laptop`
    top: -13px;
   `}
  }
 }

 &.second-input {
  &:focus
   + ${LabelText},
   &:not(:placeholder-shown)
   + ${LabelText},
   &.forever-focused
   + ${LabelText} {
   top: -17px;
   left: -82px;
   width: 396px;

   padding-inline: 10px;

   scale: 0.54;

   color: ${({ theme }) => theme.secondaryColor};

   ${media.laptop`
    top: -13px;
    left: -80px;
   `}
  }
 }

 &.first-small {
  padding: 4px 12px;

  font-size: 16px;

  & + ${LabelText} {
   top: 13px;

   font-size: 16px;
  }

  &:focus + ${LabelText}, &:not(:placeholder-shown) + ${LabelText} {
   top: -11px;
   left: -5px;

   padding-inline: 10px;

   scale: 0.7;

   color: ${({ theme }) => theme.secondaryColor};

   ${media.laptop`
    top: -13px;
   `}
  }
 }

 &.second-small {
  padding: 4px 12px;
  font-size: 16px;

  & + ${LabelText} {
   top: 13px;
   font-size: 16px;
  }

  &:focus + ${LabelText}, &:not(:placeholder-shown) + ${LabelText} {
   top: -11px;
   left: -9px;

   padding-inline: 10px;

   scale: 0.7;

   color: ${({ theme }) => theme.secondaryColor};

   ${media.laptop`
    top: -13px;
   `}
  }
 }

 &.third-small {
  padding: 4px 12px;
  font-size: 16px;

  & + ${LabelText} {
   top: 15px;
   font-size: 16px;
  }

  &:focus + ${LabelText}, &:not(:placeholder-shown) + ${LabelText} {
   top: -8px;
   left: -5px;

   padding-inline: 10px;

   scale: 0.7;

   color: ${({ theme }) => theme.secondaryColor};
  }
 }

 &.fourth-small {
  padding: 4px 12px;

  font-size: 16px;

  & + ${LabelText} {
   top: 13px;

   font-size: 16px;
  }

  &:focus + ${LabelText}, &:not(:placeholder-shown) + ${LabelText} {
   top: -8px;
   left: -9px;

   padding-inline: 10px;

   scale: 0.7;

   color: ${({ theme }) => theme.secondaryColor};
  }
 }

 ${media.laptop`
  padding: 19px 18px;
 `}
`;

export const CheckboxCircle = styled(Check)`
 .circle-path {
  fill: ${(props) => props.theme.accentColor};
 }
 .check-path {
  stroke: ${(props) => props.theme.background};
 }
`;
