import { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import {
 HeaderBtn,
 HeaderContainer,
 LogoIcon,
 TabletHeader,
 MobileMenuOverflow,
 MobileMenuIcon,
 HamburgerBtn,
 HeaderBtns,
 HeaderBtnLink,
} from "./Header.styled";
import { useSelector } from "react-redux";
import MenuIcon from "../../assets/img/menu.svg";
import MenuIconLight from "../../assets/img/menu_light.svg";
import MobileMenu from "./MobileMenu/MobileMenu";
import { BackgroundContainer, OutlinedBtn } from "../Common.styled";
import LoginHeader from "./LoginHeader/LoginHeader";
import { scrollRefs } from "../../pages/Home/Home";
import Logout from "../Logout/Logout";
import Logo from "../../assets/img/logo.svg";
import LogoLight from "../../assets/img/logo_light.svg";
import { ThemeContext } from "styled-components";
import { lightTheme } from "../../utils/themes";
import { useClickOutside } from "../../utils/useClickOutside";
import { Link } from "react-router-dom";

const Header = ({ activeSection, handleScrollToFooter, openLogin = false }) => {
 const navigate = useNavigate();
 const [isOpen, setIsOpen] = useState(false);
 const [showLogin, setShowLogin] = useState(false);
 const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
 const { pathname, state } = useLocation();
 const { theme } = useContext(ThemeContext);
 const wrapperRef = useRef(null);

 useEffect(() => {
  if (state?.openLogin) {
   setIsOpen(true);
   setShowLogin(true);
   navigate("/", { state: {} });
  }
 }, [state, navigate, openLogin]);

 useEffect(() => {
  if (openLogin) {
   setIsOpen(true);
   setShowLogin(true);
   return;
  }

  if (!openLogin && !state?.openLogin) {
   setIsOpen(false);
   setShowLogin(false);
  }
 }, [openLogin, state?.openLogin]);

 useClickOutside(wrapperRef, () => setShowLogin(false));

 return (
  <BackgroundContainer>
   <HeaderContainer>
    <LogoIcon
     src={theme.background === lightTheme.background ? Logo : LogoLight}
     alt="Company logo, symbolizing professional SEO content and web optimization services."
     title="logo"
     onClick={() => {
      navigate("/");
      window.scrollTo({ top: 0, behavior: "smooth" });
     }}
     width="130"
     height="20"
     aria-label="Home"
    />
    <HamburgerBtn onClick={() => setIsOpen(!isOpen)}>
     <MobileMenuIcon
      src={
       theme.background === lightTheme.background ? MenuIcon : MenuIconLight
      }
      alt="Mobile menu icon for easy navigation in SEO and content writing services website."
      width="60"
      height="36"
      aria-hidden="true"
     />
    </HamburgerBtn>
    <MobileMenuOverflow
     onClick={(e) => {
      if (e.target === e.currentTarget) setIsOpen(false);
     }}
     className={isOpen ? "show" : ""}
     
    >
     <MobileMenu
      close={() => setIsOpen(false)}
      handleScrollToFooter={handleScrollToFooter}
    />
    </MobileMenuOverflow>
    {pathname === "/signup" || (
     <TabletHeader>
      <HeaderBtns>
       {isAuthenticated && (
        <HeaderBtnLink to="/generate">Generate</HeaderBtnLink>
       )}
       <HeaderBtnLink
        to={`https://blog.seoll-e.ai?theme=${
         theme.background === lightTheme.background ? "light" : "dark"
        }`}
       >
        Blog
       </HeaderBtnLink>
       <HeaderBtn>
        <Link to="/keywords-generator">Keywords</Link>
       </HeaderBtn>
       <HeaderBtn
        onClick={() => {
         if (pathname === "/") {
          scrollRefs.handleScrollToInfo();
          setIsOpen(false);
         } else {
          navigate("/", { state: { scrollTo: "features" } });
         }
        }}
        className={activeSection === "info" ? "active" : ""}
       >
        Features
       </HeaderBtn>
       <HeaderBtn
        onClick={() => {
         if (pathname === "/") {
          scrollRefs.handleScrollToPricing();
          setIsOpen(false);
         } else {
          navigate("/", { state: { scrollTo: "pricing" } });
         }
        }}
        className={activeSection === "pricing" ? "active" : ""}
       >
        Pricing
       </HeaderBtn>
       <HeaderBtn
        onClick={() => {
         if (handleScrollToFooter) {
          handleScrollToFooter();
         } else {
          scrollRefs.handleScrollToFooter();
         }
         setIsOpen(false);
        }}
        className={activeSection === "footer" ? "active" : ""}
       >
        Contacts
       </HeaderBtn>
      </HeaderBtns>
      {isAuthenticated ? (
       <Logout />
      ) : (
       <OutlinedBtn onClick={() => setShowLogin((prev) => !prev)}>
        Log In
       </OutlinedBtn>
      )}
      {showLogin && (
       <LoginHeader close={() => setShowLogin(false)} wrapperRef={wrapperRef} />
      )}
     </TabletHeader>
    )}
   </HeaderContainer>
  </BackgroundContainer>
 );
};

export default Header;
