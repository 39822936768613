import React from "react";
import {
 AboutUsContainer,
 AboutUsTitle,
 AboutUsInfo,
 AboutUsTileH2,
} from "./AboutUs.styled";
import { BackgroundContainer } from "../Common.styled";

const AboutUs = () => {
 return (
  <BackgroundContainer>
   <AboutUsContainer>
    <AboutUsTitle>
     Seoll-e: Your Trusted AI Writer for Top-Notch SEO Content
    </AboutUsTitle>
    <AboutUsTileH2>
     Enhancing Your SEO Efforts with AI-Powered Writing
    </AboutUsTileH2>
    <AboutUsInfo>
     In today's digital marketing world, blending AI and SEO has brought new
     opportunities for content creators. Our website, focusing on practical
     AI-content creation, is here to help you improve your online presence. We
     specialize in SEO content writing and AI-driven blog posts, backed by our
     efficient AI writer and text generator, to craft content that's both
     engaging and optimized for search engines.
    </AboutUsInfo>
    <AboutUsTileH2>Practical AI Solutions for SEO Content</AboutUsTileH2>
    <AboutUsInfo>
     Our AI-driven SEO content services use cutting-edge technology to deliver
     SEO-friendly content. Be it website content, blog posts, or informative
     articles, our AI writer ensures every piece is optimized for Google
     rankings. The AI writer is a key tool in integrating keywords naturally,
     helping your content read well and rank high.
    </AboutUsInfo>
    <AboutUsTileH2>Stay Ahead with Innovative SEO Tools</AboutUsTileH2>
    <AboutUsInfo>
     We pride ourselves on our innovative approach. Our AI tools, including the
     AI writer and keyword generator, ensure your content aligns with the latest
     SEO trends. This includes creating not just text but also SEO-optimized
     images and summaries to boost your website's search engine performance.
    </AboutUsInfo>

    <AboutUsTileH2>Content with a Human Touch</AboutUsTileH2>
    <AboutUsInfo>
     Authenticity is crucial in content creation. Our AI writer is designed to
     produce content that feels genuine and engaging. Each piece undergoes a
     quality check for its human-like appeal, ensuring your SEO content is both
     optimized and relatable to your audience.
    </AboutUsInfo>

    <AboutUsTileH2>Easy Integration with Your Website</AboutUsTileH2>
    <AboutUsInfo>
     Our services are designed for easy integration with various website
     platforms like WIX and WordPress. This ensures a hassle-free addition of
     AI-generated content to your site, simplifying your content management
     process.
    </AboutUsInfo>

    <AboutUsTileH2>Affordable SEO Services</AboutUsTileH2>
    <AboutUsInfo>
     Our subscription model, complete with a 14-day free trial, offers an
     affordable way to access our SEO and content writing services. We cater to
     a wide range of SEO needs, from blog posts to web content writing.
    </AboutUsInfo>

    <AboutUsTileH2>Your Partner for SEO Growth</AboutUsTileH2>
    <AboutUsInfo>
     As your SEO content partner, we're here to help you navigate the SEO
     landscape. Our platform, including our AI writer and text generator, is
     designed to boost your online presence, aiming for top Google rankings. We
     focus on creating SEO content that's not just effective but also engaging
     and relevant to your audience.
    </AboutUsInfo>

    <AboutUsTileH2>Realizing the Potential of AI in SEO</AboutUsTileH2>
    <AboutUsInfo>
     The AI writer is more than just a tool; it's a gateway to realizing the
     full potential of your SEO strategy. By utilizing our AI writer, you're set
     to create content that not only ranks well on Google but also appeals to
     your target audience. It's a simple yet powerful way to elevate your
     content strategy.
    </AboutUsInfo>
   </AboutUsContainer>
  </BackgroundContainer>
 );
};

export default AboutUs;
