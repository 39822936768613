import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 isLoading: false,
 isAuthenticated: false,
 error: "",
 credits: "",
 name: "",
 email_me: true,
 freeTrial: false,
};

const userSlice = createSlice({
 name: "user",
 initialState,
 reducers: {
  clearError: (state) => {
   state.error = "";
  },
  setCredits: (state, action) => {
   state.credits = action.payload;
  },
  setName: (state, action) => {
   state.name = action.payload;
  },
  setEmailMe: (state, action) => {
   state.email_me = action.payload;
  },
  setFreeTrial: (state, action) => {
   state.freeTrial = action.payload;
  },
  failSetCredits: (state, action) => {
   state.error = action.payload;
  },
  loadingData: (state) => {
   state.isLoading = true;
  },
  loadingFinished: (state) => {
   state.isLoading = false;
  },
  logoutUser: (state) => {
   state.isAuthenticated = false;
  },
  checkAuthenticated: (state) => {
   state.isLoading = true;
   state.error = null;
  },
  checkAuthenticatedSuccess: (state) => {
   state.isLoading = false;
   state.isAuthenticated = true;
   state.error = null;
  },

  login: (state) => {
   state.isLoading = true;
   state.error = null;
  },
  loginSuccess: (state) => {
   state.isLoading = false;
   state.isAuthenticated = true;
   state.error = null;
  },

  registrationUser: (state) => {
   state.isLoading = true;
   state.error = null;
  },
  registrationUserSuccess: (state) => {
   state.isLoading = false;
   state.error = null;
  },
  userFailure: (state, action) => {
   state.isLoading = false;
   state.isAuthenticated = false;
   state.error = action.payload;
  },
  failure: (state, action) => {
   state.isLoading = false;
   state.error = action.payload;
  },
 },
});

export const {
 registrationUser,
 registrationUserSuccess,
 login,
 loginSuccess,
 checkAuthenticated,
 checkAuthenticatedSuccess,
 logoutUser,
 loadingData,
 loadingFinished,
 failure,
 userFailure,
 setCredits,
 setFreeTrial,
 setName,
 setEmailMe,
 failSetCredits,
 clearError,
} = userSlice.actions;

export default userSlice.reducer;
