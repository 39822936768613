import styled from "styled-components";
import { media } from "../../../utils/mixins";
import { BtnBasic } from "../../Common.styled";

export const CreateAccountText = styled.p`
 max-width: 190px;
 margin-bottom: 17px;

 font-size: 26px;
 line-height: 120%;
 color: ${(props) => props.theme.color};

 &.full-size {
  max-width: none;
  margin-bottom: 6px;

  font-weight: 400;
  font-size: 28px;

  ${media.laptop`
  margin-bottom: 15px;
  font-size: 36px;
  `}
 }
`;

export const SocialBtns = styled.div`
 display: flex;
 gap: 20px;
 flex-direction: column;

 margin-bottom: 46px;

 ${media.laptop`
 flex-direction: row;
 gap: 10px;
 
 margin-bottom: 25px;
 `}
`;

export const SignUpFormStyled = styled.form`
 margin-top: 14px;
 margin-bottom: 29px;
 display: flex;
 flex-direction: column;
 gap: 12px;

 &.full-size {
  gap: 21px;

  ${media.laptop`
  gap: 26px;
  `}

  & ${BtnBasic} {
   min-height: 72px;
   margin-top: 2px;
  }
 }

 & ${BtnBasic} {
  margin-top: 17px;
 }
`;

export const ExtraText = styled.p`
 margin-bottom: 29px;

 color: ${(props) => props.theme.color};
 font-family: ${(props) => props.theme.secondaryFont};
 line-height: 120%;
 font-size: 18px;
 font-weight: 400;

 & span {
  cursor: pointer;

  text-decoration: underline;
  font-family: ${(props) => props.theme.secondaryFont};
  font-weight: 400;
 }

 ${media.laptop`
 margin-bottom: 33px;
 
 font-size: 20px;
 
 `}
`;
