export const faqKeywords = [
 {
  question: "Is the keyword generator free?",
  answer:
   "Yes, the keyword generator is completely free to use. However, for unregistered users, there's a limit, displaying only the top 5 keyword results.",
 },
 {
  question: "What does 'keyword difficulty' mean?",
  answer:
   "Keyword difficulty is a metric indicating the level of competition in ranking for a particular keyword in search results. It helps in understanding how challenging it would be to achieve a high search rank using that keyword.",
 },
 {
  question: "Is there a cost for calculating keyword difficulty?",
  answer:
   "Yes, calculating keyword difficulty is a premium feature. It costs 5 credits for each search to access this valuable metric.",
 },
 {
  question: "How can I save the keywords I find?",
  answer:
   "You can easily save your found keywords by using the 'Export' button. Plus, if you accidentally leave the page, your search results are conveniently saved in your search history for later access.",
 },
 {
  question: "How do I use keywords to generate an article?",
  answer:
   "To use the keywords for generating an article, select the desired keywords and click on the 'Generate blogpost' button. This action will take you to the post generation page where you can further customize the keywords and generate new suggestions for your article.",
 },
 {
  question: "How does the AI text generator specialize in different countries?",
  answer:
   "The AI text generator, specifically designed for SEO, can tailor keyword results to specific countries using the 'Choose countries' button, ensuring relevance to your targeted geographical area.",
 },
];

export const faqGenerate = [
 {
  question:
   "How does the AI-assisted article generation process, featuring an advanced AI writer, function?",
  answer:
   "Just visit the <a href='https://seoll-e.ai/generate'>generation page</a> and enter the title for the AI writer to create an article. The AI text generator will display the article at the bottom of the page, with the first article expanding automatically.",
 },
 {
  question:
   "Can I tailor the style and components of articles using the AI writer?",
  answer:
   "While direct influence on the AI text generator's prompt is limited, you can adjust settings to shape the style and components of your articles, ensuring they meet your SEO needs.",
 },
 {
  question:
   "What settings can I adjust for article generation with the AI text generator?",
  answer:
   "<ul><li>Choose between GPT-3.5 (10 credits/post) and GPT-4.0 (50 credits/post), with GPT-4 offering more languages and better results.</li><li>Select from 102 languages for GPT-4 and 6 for GPT-3.5.</li><li>Set article length from 200 to 3000 words, without affecting the cost.</li></ul>",
 },
 {
  question:
   "How can I add and customize images in my article created by the AI writer?",
  answer:
   "In the image settings, you can enable/disable image generation, choose from 10 image models, adjust proportions, style, color scheme, and decide if you want people in the images created by the AI text generator.",
 },
 {
  question:
   "What options are available for audio versions of articles generated by the AI writer?",
  answer:
   "You can enable or disable automatic audio generation and choose from a variety of voices to match your preference for articles created by the AI text generator.",
 },
 {
  question: "How do I search for a specific post generated by the AI writer?",
  answer:
   "Use the 'Search section' to easily locate the post you need from the AI text generator.",
 },
 {
  question:
   "Is there a charge for image generation in articles created by the AI writer?",
  answer:
   "Yes, image generation costs 5 credits per image in the articles generated by the AI text generator.",
 },
 {
  question:
   "Does generating content blocks with the AI writer incur additional costs?",
  answer:
   "Generating content blocks costs 5 credits per element in the articles created by the AI text generator.",
 },
 {
  question: "Are there charges for post regeneration by the AI writer?",
  answer:
   "Post regeneration is charged the same as initial generation. However, if an error occurs with the AI text generator, the credits are refunded to your account.",
 },
 {
  question: "How do I connect my WordPress site with SEOLL-E's AI writer?",
  answer:
   "To integrate your WordPress site, enter its link, click 'Connect', and follow the instructions on the redirected WordPress page for seamless integration with our AI text generator.",
 },
 {
  question:
   "What steps should I take to integrate my WIX website with the AI writer?",
  answer:
   "Go to 'Account Settings', create a new API Key with access to WIX Blog, note your Account ID, and then add your Website ID from the 'My Site' section's URL for integration with the AI text generator.",
 },
 {
  question:
   "How do I utilize my sitemap for internal links with the AI text generator?",
  answer:
   "Enter your sitemap URL and click 'connect' to optimize internal linking in articles generated by the AI writer, enhancing SEO performance.",
 },
 {
  question: "Is connecting my website to SEOLL-E secure with the AI writer?",
  answer:
   "Yes, it's secure. We don't store sensitive information and nothing is published without your permission when using our AI text generator.",
 },
 {
  question: "What are my options if I run out of credits for the AI writer?",
  answer:
   "If you're out of credits, you can either renew or upgrade your subscription to continue using SEOLL-E's AI text generator.",
 },
 {
  question: "What is the duration of the free trial period for the AI writer?",
  answer:
   "The free trial period for using our AI text generator lasts for 14 days.",
 },
 {
  question:
   "Why do I need to enter payment card details for the AI writer's trial period?",
  answer:
   "Providing card details helps prevent fraud and ensures secure transactions while you explore the capabilities of our AI text generator during the trial.",
 },
];
