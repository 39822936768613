import { useEffect } from "react";
import { Container } from "../components/Common.styled";
import Notiflix from "notiflix";
import { useLocation } from "react-router";

function Loading() {
 const { pathname } = useLocation();

 useEffect(() => {
  if (!pathname.includes("example")) {
   Notiflix.Loading.standard();
  }

  return () => Notiflix.Loading.remove();
 }, [pathname]);
 return <Container></Container>;
}

export default Loading;
