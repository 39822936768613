import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 isLoading: false,
 error: "",
 paymentMethods: [],
 paymentMethodsFetched: false,
 secret: "",
 subscription: {},
 testAccounts: [],
};

const userSlice = createSlice({
 name: "stripe",
 initialState,
 reducers: {
  setPaymentMethods: (state, action) => {
   state.paymentMethods = action.payload;
   state.paymentMethodsFetched = true;
  },
  setSecret: (state, action) => {
   state.secret = action.payload;
  },
  setSubscription: (state, action) => {
   state.subscription = action.payload;
  },
  setTestAccounts: (state, action) => {
   state.testAccounts = action.payload;
  },
 },
});

export const {
 setPaymentMethods,
 setSubscription,
 setSecret,
 setTestAccounts,
} = userSlice.actions;

export default userSlice.reducer;
