import styled from "styled-components";
import { Btn } from "../../Common.styled";

export const FeedbackForm = styled.form`
 border-radius: 5px;
 box-shadow: ${(props) => props.theme.boxShadow};

 background-color: ${(props) => props.theme.background};
 color: ${(props) => props.theme.color};
`;

export const StarRating = styled.div`
 display: flex;
 justify-content: center;

 margin-bottom: 20px;
`;

export const Star = styled.span`
 margin: 0 5px;
 cursor: pointer;

 font-size: 30px;
 color: ${(props) =>
  props.selected ? props.theme.accentColor : props.theme.outline};
`;

export const TextInput = styled.textarea`
 width: 100%;
 min-height: 100px;
 padding: 10px;
 margin-block: 15px;

 background-color: ${(props) => props.theme.lightBcg};
 border: 1px solid ${({ theme }) => theme.color};
 border-radius: 5px;

 color: ${(props) => props.theme.color};
 resize: none;
`;

export const FeedbackText = styled.p`
 text-align: center;
 font-size: 20px;
`;

export const Button = styled(Btn)`
 display: block;

 margin-inline: auto;
 margin-top: 12px;
`;

export const FeedbackTextTitle = styled(FeedbackText)`
 font-size: 30px;
 margin-bottom: 20px;
`;
