import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/user/userSlice";
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import Notiflix from "notiflix";
import {
 TextOverflow,
 User,
 UserContainer,
 UserMenu,
 UserMenuBtn,
 UserName,
} from "./Logout.styled";
import ChangeThemeBtn from "../ChangeThemeBtn/ChangeThemeBtn";
import { Hr } from "../Common.styled";
import { lightTheme } from "../../utils/themes";
import { ThemeContext } from "styled-components";
import {useClickOutside} from '../../utils/useClickOutside';

function Logout() {
 const dispatch = useDispatch();
 const userName = useSelector((state) => state.user.name);
 const [email, setEmail] = useState("");
 const navigate = useNavigate();
 const [showMenu, setShowMenu] = useState(false);
 const { theme } = useContext(ThemeContext);
 const wrapperRef = useRef(null);

 useEffect(() => {
  if (userName) {
   setEmail(userName);
   return;
  }
  const mail = localStorage.getItem("email");
  if (mail) setEmail(mail);
 }, [userName]);

 function logout() {
  Notiflix.Confirm.show(
   "Logout",
   "Are you sure you want to logout?",
   "Yes",
   "Cancel",
   () => {
    localStorage.removeItem("email");
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("wordpressSettings");
    dispatch(logoutUser());
    navigate("/");
   },
   () => {}
  );
 }
 useClickOutside(wrapperRef, () => setShowMenu(false))

 return (
  <>
   <User className="sick-border" onClick={() => setShowMenu((prev) => !prev)}>
    {email.toUpperCase()[0]}
   </User>
   <UserContainer
    className={showMenu ? "active" : ""}
    onClick={(e) => {
     if (e.target === e.currentTarget) {
      setShowMenu(false);
     }
    }}
   >
    <UserMenu className={showMenu ? "active" : ""} ref={wrapperRef}>
     <UserName>
      <TextOverflow>{email}</TextOverflow>
      <ChangeThemeBtn position={"relative"} />
     </UserName>
     <Hr className="user-menu"></Hr>
     <UserMenuBtn
      onClick={() => {
       navigate("/generate");
       setShowMenu(false);
      }}
     >
      Create New Posts
     </UserMenuBtn>

     <UserMenuBtn
      onClick={() => {
       navigate("/keywords-generator");
       setShowMenu(false);
      }}
     >
      Keyword Tool
     </UserMenuBtn>
     <UserMenuBtn
      onClick={() => {
       navigate("/transactions");
       setShowMenu(false);
      }}
     >
      Credits & Transactions
     </UserMenuBtn>
     <UserMenuBtn
      onClick={() => {
       window.open(
        `https://blog.seoll-e.ai?theme=${
         theme.background === lightTheme.background ? "light" : "dark"
        }`,
        "_self"
       );
       setShowMenu(false);
      }}
     >
      Blog
     </UserMenuBtn>
     <UserMenuBtn
      onClick={() => {
       navigate("/settings");
       setShowMenu(false);
      }}
     >
      Integrations
     </UserMenuBtn>

     <UserMenuBtn onClick={logout}>Log Out</UserMenuBtn>
    </UserMenu>
   </UserContainer>
  </>
 );
}

export default Logout;
