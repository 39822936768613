import { useDispatch, useSelector } from "react-redux";
import {
 AccentText,
 Description,
 HeaderBtn,
 HeaderBtnLink,
 HeaderBtns,
} from "../Header.styled";
import CloseIcon from "../../../assets/img/close.svg";
import CloseIconLight from "../../../assets/img/close_light.svg";
import { useLocation, useNavigate } from "react-router";
import { Text } from "../../Common.styled";
import Notiflix from "notiflix";
import { useContext, useState } from "react";
import SignInForm from "../SignInForm";
import SignUpForm from "../SignUpForm/SignUpForm";
import { scrollRefs } from "../../../pages/Home/Home";
import {
 CloseMenuIcon,
 HeaderMenu,
 MobileMenuStyled,
} from "./MobileMenu.styled";
import { useEffect } from "react";
import { logoutUser } from "../../../store/user/userSlice";
import ChangeThemeBtn from "../../ChangeThemeBtn/ChangeThemeBtn";
import { ThemeContext } from "styled-components";
import { lightTheme } from "../../../utils/themes";

function MobileMenu({ close, handleScrollToFooter }) {
 const { pathname } = useLocation();
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const [shouldNavigate, setShouldNavigate] = useState(false);
 const { theme } = useContext(ThemeContext);

 const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

 const [isLogin] = useState(true);

 function handleNavigate(route) {
  navigate(route);
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
  close();
 }

 function handleLogout() {
  Notiflix.Confirm.show(
   "Logout",
   "Are you sure you want to logout?",
   "Yes",
   "Cancel",
   () => {
    localStorage.removeItem("email");
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("wordpressSettings");
    dispatch(logoutUser());
    navigate("/");
    window.scrollTo({
     top: 0,
     left: 0,
     behavior: "smooth",
    });
    close();
   },
   () => {}
  );
 }

 useEffect(() => {
  if (isAuthenticated && shouldNavigate) {
   setShouldNavigate(false);
   navigate("/generate");
   close();
  }
 }, [isAuthenticated, shouldNavigate, navigate, close]);

 return (
  <MobileMenuStyled>
   <CloseMenuIcon
    src={
     theme.background === lightTheme.background ? CloseIcon : CloseIconLight
    }
    onClick={close}
    alt="Close menu icon for SEO content website, indicating menu closure functionality."
    width="60"
    height="44"
    aria-label="Close Menu"
   />
   {isLogin && (
    <HeaderBtns aria-label="Menu Items">
     <HeaderBtn onClick={() => handleNavigate("/")}>Home</HeaderBtn>
     {isAuthenticated && (
      <HeaderBtn onClick={() => handleNavigate("/generate")}>
       Generate
      </HeaderBtn>
     )}
     <HeaderBtnLink
      to={`https://blog.seoll-e.ai?theme=${
       theme.background === lightTheme.background ? "light" : "dark"
      }`}
     >
      Blog
     </HeaderBtnLink>
     <HeaderBtn onClick={() => handleNavigate("/keywords-generator")}>
      Keywords
     </HeaderBtn>
     <HeaderBtn
      onClick={() => {
       pathname === "/"
        ? scrollRefs.handleScrollToInfo()
        : navigate("/", { state: { scrollTo: "features" } });
       close();
      }}
     >
      Features
     </HeaderBtn>
     <HeaderBtn
      onClick={() => {
       pathname === "/"
        ? scrollRefs.handleScrollToPricing()
        : navigate("/", { state: { scrollTo: "pricing" } });
       close();
      }}
     >
      Pricing
     </HeaderBtn>
     <HeaderBtn
      onClick={() => {
       if (handleScrollToFooter) {
        handleScrollToFooter();
       } else {
        scrollRefs.handleScrollToFooter();
       }
       close();
      }}
     >
      Contacts
     </HeaderBtn>
     {isAuthenticated && (
      <HeaderBtn onClick={handleLogout} aria-label="Logout">
       Logout
      </HeaderBtn>
     )}
    </HeaderBtns>
   )}
   {isAuthenticated || (
    <HeaderMenu role="dialog" aria-label="Login or Signup">
     {isLogin ? (
      <SignInForm triggerNavigate={() => setShouldNavigate(true)} />
     ) : (
      <SignUpForm close={close} />
     )}
     <Description>
      <Text>
       By {isLogin ? "signing in to your " : " creating an "} account you agree
       to our{" "}
       <AccentText to="/terms-and-conditions">Terms of Service</AccentText> and
       our <AccentText to="/privacy-policy">Privacy Policy</AccentText>
      </Text>
      <Text>
       {isLogin ? "Don't have an account yet?" : "Already a user?"}{" "}
       <AccentText to="/signup">Sign {isLogin ? " up " : " in "}</AccentText>
      </Text>
     </Description>
    </HeaderMenu>
   )}
   <ChangeThemeBtn
    right="10px"
    top={isAuthenticated ? "" : "265px"}
    bottom={isAuthenticated ? "15px" : ""}
    aria-label="Change Theme Button"
   />
  </MobileMenuStyled>
 );
}

export default MobileMenu;
