import { BtnBasic } from "../components/Common.styled";

function SocialLoginBtn({ icon, text, url }) {
 return (
  <BtnBasic onClick={() => window.open(url, "_self")}>
   {icon}
   {text}
  </BtnBasic>
 );
}

export default SocialLoginBtn;
