import styled from "styled-components";
import { media } from "../../utils/mixins";
import { ArrowContainer, SettingsBtn } from "../TryNow/TryNow.styled";
import { SettingsLabel } from "../../pages/Settings/Settings.styled";

export const SettingSpan = styled.span`
 display: flex;
 gap: 5px;
 align-items: center;

 line-height: 1.8;
 font-size: 14px;
 text-align: left;

 &.extra-space {
  margin-bottom: 10px;
 }
`;
export const SettingsContainer = styled.div`
 display: grid;
 align-items: baseline;
 grid-template-columns: 1fr;
 width: 100%;

 ${media.laptop`
  grid-template-columns: 2fr 3fr;
  column-gap: 44px;
  margin: 0;
  padding: 0;
 `}
`;

export const InputSettingsContainer = styled.div`
 position: relative;

 display: grid;
 align-items: baseline;
 grid-template-columns: 1fr;

 overflow: hidden;

 max-height: 52px;
 margin-bottom: 10px;
 width: 100%;
 padding: 10px 12px;

 border: 1px solid ${(props) => props.theme.outline};
 border-radius: 4px;

 transition: max-height 500ms ease-in-out;

 ${media.laptop`
  margin: 0;
  padding: 0;
 `}

 & ${ArrowContainer} {
  rotate: -90deg;

  ${media.laptop`
    display: none;
  `}
 }

 & ${SettingsBtn} {
  ${media.laptop`
  grid-column: span 2;
  margin-bottom: 19px;

  font-size: 16px;
  line-height: 160%;
  `}
 }

 &.show {
  max-height: 187px;
  & ${ArrowContainer} {
   rotate: 0deg;
  }
 }

 &.showAll {
  max-height: 405px;
  padding-bottom: 60px;
 }

 ${media.laptop`
 display: grid;
 column-gap: 20px;
 justify-items: start;
 grid-auto-flow: row dense;

 max-height: none;
 border: none;

 &.showAll {
  padding-bottom: 27px;
 }
 
  & ${SettingSpan}:nth-child(-n+9) {
    grid-column: 1;
  }

  & ${SettingSpan}:nth-child(n+9) {
    grid-column: 2;
  }
 `}
`;

export const AccentSetting = styled.span`
 text-decoration: underline;
 cursor: pointer;
`;

export const Info = styled.p`
 display: flex;
 grid-column: span 2;
 align-items: center;
 gap: 5px;

 opacity: 0.8;

 font-size: 12px;
`;

export const ShowAll = styled.p`
 display: none;
 position: absolute;
 bottom: 0px;
 cursor: pointer;

 line-height: 1.8;
 color: ${(props) => props.theme.accentColor};
 font-size: 14px;
 text-decoration: underline;

 ${media.laptop`
  display: block;
  
  &.showAll {
    margin-top: 50px;
  }
 `}
`;

export const Column1 = styled.div``;

export const Column2 = styled.div``;

export const ExtraSettings = styled.div`
 overflow: hidden;
 opacity: 1;
 margin-top: -10px;

 & ${SettingsLabel} {
  display: flex;
  font-size: 14px;
 }

 ${media.laptop`
  grid-column: span 2;
  margin-top: 15px;
  opacity: 0;
  max-height: 0;

  &.show {
    opacity: 1;
    max-height: 500px;
   }
 `}
`;
