import styled from "styled-components";
import { GenerateTextView } from "../PostData/PostData.styled";
import { media } from "../../utils/mixins";
import { Btn, OutlinedBtn } from "../Common.styled";
import { ReactComponent as ArrowIcon } from "../../assets/img/arrow.svg";

export const TryNowContainer = styled.div`
 padding: 35px 16px 30px;

 background-color: ${({ theme }) => theme.background};

 color: ${({ theme }) => theme.color};

 ${media.laptop`
 max-width: 1280px;
 
 padding-inline: 10px;
  margin-inline: auto;
  padding-block: 53px 37px;
`}
`;

export const IFrame = styled.iframe`
 overflow: hidden;
 border: none;

 &.disable-copy {
  pointer-events: none;
  user-select: none;
 }
`;

export const TryNowContainerDiv = styled.div`
 width: 100%;
`;

export const SubHeader = styled.h2`
 font-size: 15px;
 font-weight: 400;
 margin-top: 10px;
 text-align: center;
 max-width: 322px;
 margin-inline: auto;

 ${media.laptop`
 font-size: 16px;
 `}
`;

export const TryNowForm = styled.form`
 display: flex;
 flex-direction: column;
 gap: 30px;
 align-items: center;

 ${media.laptop`
 align-items: baseline;
  flex-direction: row;
  gap: 20px;
 `}
`;

export const OutputContainer = styled.div`
 margin-top: 40px;
 width: calc(100% - 32px);
 margin-inline: auto;
 max-width: 1260px;
`;

export const BottomPostContainer = styled.div`
 display: flex;
 flex-direction: row-reverse;
 justify-content: flex-end;
 margin-top: ${(props) => props.marginTop};
 align-items: center;
 gap: 35px;

 &.example {
  margin-top: 15px;
 }

 ${media.laptop`
   margin-top: 15px;
   gap: 5px;
 `}

 & > div {
  display: flex;
  gap: 15px;
 }
`;

export const Transparency = styled.div`
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-image: -webkit-gradient(
  linear,
  left top,
  left bottom,
  color-stop(80%, transparent),
  to(${(props) => props.theme.background})
 );
 background-image: -webkit-linear-gradient(
  top,
  transparent 80%,
  ${(props) => props.theme.background}
 );
 background-image: -moz-linear-gradient(
  top,
  transparent 80%,
  ${(props) => props.theme.background}
 );
 background-image: -o-linear-gradient(
  top,
  transparent 80%,
  ${(props) => props.theme.background}
 );
 background-image: linear-gradient(
  to bottom,
  transparent 80%,
  ${(props) => props.theme.background}
 );

 pointer-events: none;
`;

export const TryNowPost = styled(GenerateTextView)`
 min-height: 300px;
 max-height: 745px;
 margin: 0;

 border-bottom: none;
 border-radius: 4px 4px 0 0;

 transition: max-height 350ms ease-in-out;

 &.disable-copy {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
 }

 &.full {
  max-height: ${(props) => props.postHeight * (window.innerWidth - 20) + 225}px;

  ${media.laptop`
  max-height: ${(props) => props.postHeightDesktop + 170}px;  
  `}

  & ${Transparency} {
   background: transparent;
  }
 }
`;

export const ModalText = styled.p`
 text-align: center;
 font-size: 18px;
 font-weight: 300;
 line-height: 1.6;
 color: ${({ theme }) => theme.color};
`;

export const ModalBtn = styled(Btn)`
 display: block;

 margin-inline: auto;

 color: ${({ theme }) => theme.color};

 background: ${({ theme }) => theme.background};
`;

export const ArrowContainer = styled.button`
 display: grid;
 place-items: center;

 width: 32px;
 height: 32px;

 border: none;
 outline: none;
 border-radius: 4px;
 background-color: ${(props) => props.theme.bcg4};

 cursor: pointer;

 transition: rotate 500ms ease-in-out;

 &.mobile-only {
  ${media.laptop`
   display: none;
  `}
 }
`;

export const Arrow = styled(ArrowIcon)`
 width: 12px;
 height: 12px;
 color: ${(props) => props.theme.color};
`;

export const DefaultSettings = styled.div`
 display: flex;
 flex-direction: column;

 max-height: 52px;
 margin-bottom: 30px;
 margin-top: 40px;

 overflow: hidden;

 border: 1px solid ${(props) => props.theme.outline};
 padding: 10px 12px 16px;
 border-radius: 4px;

 transition: max-height 500ms ease-in-out;

 & ${ArrowContainer} {
  rotate: -90deg;
 }

 &.show {
  max-height: 267px;
  & ${ArrowContainer} {
   rotate: 0deg;
  }
 }

 ${media.laptop`
  display: none;
  `}
`;

export const DefaultSetting = styled.span`
 font-size: 12px;
 line-height: 1.8;
`;

export const SettingsBtn = styled.span`
 display: flex;
 justify-content: space-between;
 align-items: center;

 width: 100%;
 margin-bottom: 13px;

 color: ${(props) => props.theme.color};
 font-size: 16px;
 line-height: 160%;

 &.mb10 {
  margin-bottom: 10px;
 }
`;

export const CheckAIBtn = styled(OutlinedBtn)`
 max-width: 250px;
`;

export const AIContainer = styled.div`
 position: relative;

 display: flex;
 gap: 20px;
 align-items: center;
`;

export const Score = styled.span`
 display: grid;
 place-items: center;

 height: 25px;
 width: 25px;

 background-color: #fdfdfd;
 border-radius: 50%;

 font-size: 12px;
`;

export const ScoreContainer = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;

 height: 30px;
 width: 30px;

 border-radius: 50%;

 background: conic-gradient(
  ${(props) => props.color} ${(props) => props.percent},
  ${(props) => props.theme.outline} ${(props) => props.percent} 100%
 );

 &.desktop-only {
  display: none;
  ${media.laptop`
   display: flex;
   `}
 }
`;

export const ExampleText = styled.p`
 font-size: 20px;
 margin-bottom: 10px;
 padding-inline: 10px;
 margin-inline: auto;
 width: 100%;
 max-width: 1280px;

 color: ${(props) => props.theme.color};
 background-color: ${(props) => props.theme.background};
`;

export const Examples = styled.div`
 overflow: hidden;
 max-width: 1280px;
 padding-inline: 10px;
 margin-inline: auto;

 & ${OutputContainer} {
  width: 100%;
  flex-shrink: 0;
  margin-top: 20px;
 }
`;

export const Pagination = styled.div`
 padding-inline: 10px;
 margin-inline: auto;
 width: 100%;
 max-width: 1280px;

 display: flex;
 gap: 10px;

 & span {
  display: grid;
  place-items: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out;

  &:first-child():hover {
   transform: translateY(#{$arrow-width / 4}) rotate(-30deg);
  }

  &.active {
   background-color: ${(props) => props.theme.accentColor};
   color: ${(props) => props.theme.background};
  }
 }
`;

export const ExamplesRoller = styled.div`
 display: flex;
 gap: 40px;
 transform: translateX(-${(props) => props.num * (window.innerWidth + 20)}px);
 transition: transform 500ms ease-in-out;
 -webkit-transition: transform 500ms ease-in-out,
  -webkit-transform 500ms ease-in-out;

 ${media.laptop`
 transform: translateX(-${(props) => props.num * 1300}px);
 `}
`;

export const ExampleSettings = styled.ul`
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 row-gap: 8px;
 column-gap: 5px;
 padding-left: 10px;
 padding-right: 45px;
 margin-block: 15px;
 font-size: 12px;
 list-style: none;

 ${media.laptop`
 grid-template-columns: repeat(3, 1fr);
 font-size: 16px;
 margin-block: 0 30px; 
 row-gap: 15px;

 `}

 & li {
  display: flex;
  gap: 10px;
  font-family: ${(props) => props.theme.secondaryFont};

  &:before {
   content: "✓";
  }
 }
`;

export const ExampleImg = styled.img`
 width: 100%;
`;

export const Rect = styled.div`
 display: grid;
 place-items: center;
 width: 70px;
 border: 1px solid ${(props) => props.theme.outline};
 border-radius: 4px;
 background-color: ${(props) => props.theme.background};
 background-image: -webkit-gradient(
  linear,
  left top,
  right top,
  color-stop(0, ${(props) => props.color}),
  color-stop(${(props) => props.percent}, ${(props) => props.color}),
  color-stop(${(props) => props.percent}, ${(props) => props.theme.background})
 );
 background-image: -webkit-linear-gradient(
  to right,
  ${(props) => props.color},
  ${(props) => props.color} ${(props) => props.percent},
  ${(props) => props.theme.background} ${(props) => props.percent}
 );
 background-image: -moz-linear-gradient(
  to right,
  ${(props) => props.color},
  ${(props) => props.color} ${(props) => props.percent},
  ${(props) => props.theme.background} ${(props) => props.percent}
 );
 background-image: -o-linear-gradient(
  to right,
  ${(props) => props.color},
  ${(props) => props.color} ${(props) => props.percent},
  ${(props) => props.theme.background} ${(props) => props.percent}
 );
 background-image: linear-gradient(
  to right,
  ${(props) => props.color},
  ${(props) => props.color} ${(props) => props.percent},
  ${(props) => props.theme.background} ${(props) => props.percent}
 );

 &.desktop-only {
  display: none;
  ${media.laptop`
    display: grid;
  `}
 }
`;

export const SeeMore = styled.div`
 display: flex;
 justify-content: center;
 gap: 20px;

 width: 100%;
 padding-block: 10px;

 background-color: ${(props) => props.theme.background};
 border: 1px solid ${(props) => props.theme.outline};
 border-top: none;
 border-radius: 0 0 4px 4px;
 outline: none;

 cursor: pointer;

 font-size: 20px;

 & ${ArrowContainer} {
  transition: transform 250ms ease-in-out;
 }

 &.rotate ${ArrowContainer} {
  transform: rotate(180deg);
 }
`;

export const ArrowPag = styled.span`
 position: relative;
`;

export const ArrowHalf = styled.span`
 width: 2px;
 height: 9px;
 border-radius: 1px;
 background-color: ${(props) => props.theme.accentColor};
 display: inline-block;
 position: absolute;
 transform-origin: 0px;
 opacity: 1;
 transition: transform 0.1s ease-in-out, opacity 0.2s ease-in-out;

 &:first-child {
  transform: translateY(#{$arrow-width / 4}) rotate(-45deg);
 }
`;

export const Semicircle = styled.div`
 display: grid;
 place-items: center;
 width: 85%;
 height: 85%;
 background-color: ${(props) => props.theme.background};
 border-radius: 50%;
 padding-bottom: 16px;

 &.table {
  background-color: ${(props) => props.theme.bcg4};

  &:nth-child(2n) {
   background-color: ${(props) => props.theme.background};
  }
 }
`;

export const SemicircleContainer = styled.div`
 position: relative;
 display: grid;
 place-items: center;
 width: 60px;
 height: 60px;
 border-radius: 50%;
 background-color: ${(props) => props.color};
 transform: translateY(10px);
 background: conic-gradient(
  from 270deg,
  ${(props) => props.color + " " + props.percent},
  ${(props) => props.theme.outline + " " + props.percent}
 );
`;

export const OverflownContainer = styled.div`
 max-height: 40px;
 overflow: hidden;
 transform: translateY(-10px);

 &.desktop-only {
  display: none;
  ${media.laptop`
    display: grid;
    place-items: center;
    width: 100%;
  `}
 }
`;
