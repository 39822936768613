import { StyledLi, StyledOl } from "../../pages/Settings/Settings.styled";
import { InfoImage, InfoRobotContainer } from "./Info.styled";
import RobotWebP from "../../assets/img/robot.webp";
import { Bs1Circle, Bs2Circle, Bs3Circle } from "react-icons/bs";

function InfoRobot() {
 return (
  <InfoRobotContainer>
   <InfoImage
    src={RobotWebP}
    alt="SEO robot graphic, representing advanced AI in SEO content optimization."
    loading="lazy"
    title="seo robot"
    width="375"
    height="460"
   />

   <StyledOl>
    <StyledLi>
     <Bs1Circle fontSize={30} />
     Input topic and keywords for your blogposts
    </StyledLi>
    <StyledLi>
     <Bs2Circle fontSize={30} />
     Select number of articles and posting schedule (e.g. 10 articles/day)
    </StyledLi>
    <StyledLi>
     <Bs3Circle fontSize={30} />
     Watch as SEOLL-E generates and publishes articles to your WordPress, WIX or
     custom website
    </StyledLi>
   </StyledOl>
  </InfoRobotContainer>
 );
}

export default InfoRobot;
