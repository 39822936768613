import { ThemeContext } from "styled-components";
import { useContext, useEffect } from "react";
import Notiflix from "notiflix";

function NotiflixInit() {
 const { theme } = useContext(ThemeContext);

 useEffect(() => {
  Notiflix.Notify.init({
   timeout: 3000,
   messageMaxLength: 350,
   clickToClose: true,
   pauseOnHover: true,
   fontFamily: "IBM Plex Sans",
   fontSize: "16px",
   useIcon: false,
   background: theme.bcg4,
   success: {
    textColor: theme.color,
    background: theme.bcg4,
   },
   failure: {
    background: theme.bcg4,
    textColor: theme.color,
   },
   warning: {
    background: theme.bcg4,
    textColor: theme.color,
   },
   info: {
    background: theme.bcg4,
    textColor: theme.color,
   },
  });

  Notiflix.Report.init({
   fontFamily: "IBM Plex Sans",
   fontSize: "16px",
   useIcon: false,
   borderRadius: "4px",
   info: {
    backOverlayColor: "transparent",
    buttonBackground: theme.bcg4,
    buttonColor: theme.accentColor,
   },
   failure: {
    backOverlayColor: "transparent",
    buttonBackground: theme.bcg4,
    buttonColor: theme.accentColor,
   },
   success: {
    backOverlayColor: "transparent",
    buttonBackground: theme.bcg4,
    buttonColor: theme.accentColor,
   },
  });

  Notiflix.Confirm.init({
   borderRadius: "4px",
   messageMaxLength: 5000,
   okButtonColor: theme.accentColor,
   okButtonBackground: theme.bcg4,
   backgroundColor: theme.bcg4,
   messageColor: theme.color,
   cancelButtonColor: theme.color,
   cancelButtonBackground: theme.bcg4,
   width: "475px",
  });
 }, [theme]);

 return <></>;
}

export default NotiflixInit;
