import axios from "axios";

const instance = axios.create({
 baseURL: "https://app.seoll-e.ai/api/",
 headers: {
  "Content-Type": "application/json",
 },
});

export default instance;
