import axios from "./axios-config";

export const adClick = async (incoming_url, action) => {

try {
  const response = await axios.post(
   "/users/site-call/",
   {
    incoming_url,
    action
   },
  );
  return response.data;

 } catch (error) {
    console.error(error)
 }
};

export const updateAdClick = async (action, email = "") => {
    const id = localStorage.getItem("adClickId");
    const data = action === "registration" ?
    {
      action,
      email
    } : {
      action,
    }
    try {
      const response = await axios.patch(
       `/users/site-call/${id}/`,
       data,
      );
      return response.data;

     } catch (error) {
        console.error(error)
     }
    };