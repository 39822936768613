import React, { forwardRef } from "react";
import { InfoContainer } from "./Info.styled";
import { BackgroundContainer } from "../Common.styled";
import Reviews from "../Reviews/Reviews";
import InfoTiles from "./InfoTiles";
import InfoRobot from "./InfoRobot";
import Videos from "../Videos/Videos";

const Info = forwardRef((_, ref) => {
 return (
  <BackgroundContainer>
   <InfoContainer ref={ref} role="region" aria-labelledby="info-section">
    <Reviews />

    <InfoRobot />
    <Videos />
    <InfoTiles />
   </InfoContainer>
  </BackgroundContainer>
 );
});

export default Info;
