import styled from "styled-components";
import { media } from "../../utils/mixins";

export const LoginTitle = styled.h2`
 font-family: "Roboto", sans-serif;
 font-weight: 400;
 font-size: 20px;
 color: ${({ theme }) => theme.color};

 transition: color 500ms ease-in-out;

 ${media.largeMobile`
   font-size: 22px;
  `}

 ${media.desktop`
 font-size: 28px;
  `}
`;

export const LoginInput = styled.input`
 width: 95%;

 padding: 10px 14px;

 border-radius: 10px;
 outline: none;
 border: 1px solid ${({ theme }) => theme.lightestBcg};

 background-color: ${({ theme }) => theme.lightBcg};

 color: ${({ theme }) => theme.color};
 font-size: 14px;

 transition: box-shadow 250ms ease-in-out, background-color 500ms ease-in-out,
  color 500ms ease-in-out, border 500ms ease-in-out;

 &:focus {
  box-shadow: inset 1px 1px ${({ theme }) => theme.lightestBcg};
 }

 &::placeholder {
  color: ${({ theme }) => theme.color};
  font-size: 14;

  ${media.largeMobile`
    font-size: 16px;
  `}
 }

 ${media.largeMobile`
    font-size: 16px;
  `}

 ${media.tablet`
    width: 400px;
  `}
`;

export const LoginInputContainer = styled.div`
 position: relative;
 margin-bottom: 10px;
 width: 100%;
 transition: height 500ms ease-in-out, opacity 200ms ease-in-out;

 &.hidden {
  height: 0;
  opacity: 0;
  pointer-events: none;
 }
`;

export const LoginInputError = styled.p`
 color: red;
 font-size: 10px;
 height: 0;
 margin-bottom: 10px;
`;

export const LoginContainer = styled.div`
 position: fixed;
 top: 50%;
 left: 50%;
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 10px;
 width: 90%;
 transform: translate(-50%, -50%);
 padding: 25px;
 border-radius: 8px;
 box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
 background-color: ${({ theme }) => theme.background};
 color: ${({ theme }) => theme.color};
 transition-duration: 500ms;
 transition-timing-function: ease-in-out;
 transition-property: box-shadow, background-color, color;
 ${media.largeMobile`
  gap: 15px;
  `}
 ${media.tablet`
  width: 70vw;
  min-height: 0;
  padding-top: 25px;
  padding-bottom: 40px;
  max-width: 500px;
  `}
 ${media.desktop`
  width: 70vw;
  padding-top: 25px;
  gap: 20px;
  max-width: 500px;
  `}
`;
