import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { setupStore } from "./store";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
 <Provider store={store}>
  <BrowserRouter>
   <App />
  </BrowserRouter>
 </Provider>
);
