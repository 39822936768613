import {
 BtnBasic,
 Hr,
 InputBasic,
 LabelBasic,
 SmallBtn,
 Text,
} from "../../Common.styled";
import {
 AccentText,
 AppleIcon,
 Description,
 SocialIcon,
} from "../Header.styled";
import GoogleIcon from "../../../assets/img/google.svg";
import { validateEmail, validatePassword } from "../../../utils/validation";
import Notiflix from "notiflix";
import { useDispatch } from "react-redux";
import { registrationNewUser } from "../../../store/user/userActions";
import { useState } from "react";
import { useNavigate } from "react-router";
import {
 CreateAccountText,
 ExtraText,
 SignUpFormStyled,
 SocialBtns,
} from "./SignUpForm.styled";
import SquareCheckbox from "../../../bricks/SquareCheckbox/SquareCheckbox";

function SignUpForm({ fullSize = false }) {
 const dispatch = useDispatch();
 const [checked, setChecked] = useState(false);
 const navigate = useNavigate();

 function createAccount(e) {
  e.preventDefault();

  const {
   elements: { email, password, name },
  } = e.target;

  const emailErr = validateEmail(email.value);
  if (emailErr) {
   Notiflix.Notify.failure(emailErr);
   return;
  }

  const passwordErr = validatePassword(password.value);
  if (passwordErr) {
   Notiflix.Notify.failure(passwordErr);
   return;
  }

  dispatch(
   registrationNewUser({
    email: email.value,
    password: password.value,
    name: name.value,
   })
  );
 }

 return (
  <div>
   <CreateAccountText className={fullSize ? "full-size" : ""}>
    Create an account
   </CreateAccountText>
   {fullSize && (
    <ExtraText>
     Already a user? Login{" "}
     <span onClick={() => navigate("/", { state: { openLogin: true } })}>
      here
     </span>
    </ExtraText>
   )}
   <SocialBtns>
    <SmallBtn
     onClick={() =>
      window.open(
       "https://seo-tool.herokuapp.com/accounts/google/login/",
       "_self"
      )
     }
     className={fullSize ? "full-size" : ""}
    >
     <SocialIcon
      src={GoogleIcon}
      alt="Sign up with Google icon for easy account creation on our SEO content services platform."
      width="32"
      height="32"
      title="sign in with google"
     />
     Sign up with Google
    </SmallBtn>
    <SmallBtn
     onClick={() =>
      window.open(
       "https://seo-tool.herokuapp.com/accounts/apple/login/",
       "_self"
      )
     }
     className={fullSize ? "full-size" : ""}
    >
     <AppleIcon />
     Sign up with Apple
    </SmallBtn>
   </SocialBtns>
   {fullSize || <Hr></Hr>}
   <SignUpFormStyled
    onSubmit={createAccount}
    className={fullSize ? "full-size" : ""}
    aria-label="Sign Up Form"
    autoComplete="off"
   >
    <LabelBasic className={fullSize ? "full-size" : ""}>
     First name
     <InputBasic type="text" name="name" className="gray" required />
    </LabelBasic>
    <LabelBasic className={fullSize ? "full-size" : ""}>
     Email
     <InputBasic
      type="email"
      name="email"
      className="gray"
      required
      autoComplete="username"
     />
    </LabelBasic>
    <LabelBasic
     className={fullSize ? "full-size" : ""}
     autoComplete="new-password"
    >
     Password
     <InputBasic type="password" name="password" className="gray" required />
    </LabelBasic>
    {fullSize && (
     <Description className={fullSize ? "full-size" : ""}>
      <label>
       <SquareCheckbox
        onChange={() => setChecked((prev) => !prev)}
        checked={checked}
        aria-label="Agree to Terms of Service and Privacy Policy"
       />
       <Text>
        By creating an account you agree to our{" "}
        <AccentText onClick={() => navigate("/terms-and-conditions")}>
         Terms of Service
        </AccentText>{" "}
        and our{" "}
        <AccentText onClick={() => navigate("/privacy-policy")}>
         Privacy Policy
        </AccentText>
       </Text>
      </label>
     </Description>
    )}
    <BtnBasic
     className={checked ? "large-font h72 outlined" : "large-font h72"}
     type="submit"
     disabled={!checked}
     aria-disabled={!checked}
    >
     Create account
    </BtnBasic>
   </SignUpFormStyled>
  </div>
 );
}

export default SignUpForm;
