import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 showForm: false,
 askForFeedback: true,
};

const feedbackSlice = createSlice({
 name: "feedback",
 initialState,
 reducers: {
  showForm: (state) => {
   const previousDate = localStorage.getItem("feedback");
   let counter = localStorage.getItem("counter");
   if (!counter) counter = 0;
   if (
    !previousDate ||
    (previousDate && new Date(previousDate) - new Date() > 3600000)
   ) {
    if (counter < 3) {
     localStorage.setItem("counter", Number(counter) + 1);
     localStorage.setItem("feedback", new Date().toString());
     if (state.askForFeedback) {
      state.showForm = true;
     }
    } else {
     localStorage.setItem("counter", counter - 1);
    }
   }
  },
  hideForm: (state) => {
   state.showForm = false;
  },
  dontAskFeedback: (state) => {
   state.askForFeedback = false;
  },
 },
});

export const { showForm, hideForm, dontAskFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
