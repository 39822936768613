export const lightTheme = {
 background: "#FDFDFD",
 color: "#060B19",
 colorSecondary: "#5A5A5A",
 accentColor: "#000DFF",
 secondaryBcg: "#EAEAEA",
 tertiaryBcg: "#F3F3F3",
 bcg4: "#F5F5F5",
 outline: "#CCCCCC",

 //change theme btn
 switchPosition: "translateX(-52%)",
 sunColor: "white",
 moonColor: "lightgray",
 iconAnimation: "rotate(360deg)",

 mainFont: "'IBM Plex Mono', monospace",
 secondaryFont: "'IBM Plex Sans', sans-serif",
};

export const darkTheme = {
 background: "#1E1E1E",
 color: "#F0F0F0",
 colorSecondary: "#B0B0B0",
 accentColor: "#E5E4E2",
 secondaryBcg: "#2F2F2F",
 tertiaryBcg: "#252525",
 bcg4: "#333333",
 outline: "#666666",

 //change theme btn
 switchPosition: "translateX(52%)",
 sunColor: "lightgray",
 moonColor: "#1E1E1E",
 iconAnimation: "0",

 mainFont: "'IBM Plex Mono', monospace",
 secondaryFont: "'IBM Plex Sans', sans-serif",
};
