import { useNavigate } from "react-router";
import {
 CloseIcon,
 ModalContainer,
 ModalOverflow,
 OutlinedBtn,
} from "../Common.styled";
import { ModalText } from "../TryNow/TryNow.styled";

function CopyModal({ close }) {
 const navigate = useNavigate();

 return (
  <ModalOverflow
   onClick={(e) => {
    if (e.target === e.currentTarget) close();
   }}
  >
   <ModalContainer>
    <ModalText>Like the output?</ModalText>
    <ModalText>
     Sign up and you’ll be able to copy-paste html or text, integrate and
     produce content at scale!
    </ModalText>
    <OutlinedBtn className="small-width" onClick={() => navigate("/signup")}>
     Sign up
    </OutlinedBtn>
    <CloseIcon onClick={() => close()} />
   </ModalContainer>
  </ModalOverflow>
 );
}

export default CopyModal;
