import { allLanguages } from "../../data/languages";
import {
 FlexIcon,
 HeartIcon,
 InfoTile,
 InfoTileAccent,
 InfoTilesContainer,
 ModelsIcon,
 StarIcon,
 TranslateIcon,
 WPIcon,
} from "./Info.styled";

function InfoTiles() {
 return (
  <InfoTilesContainer>
   <InfoTile role="region" aria-labelledby="Automagic">
    <StarIcon aria-hidden="true" />
    <InfoTileAccent>Automagic</InfoTileAccent> Simply enter the desired number
    of articles and the posting period, and let us do the rest. Content will be
    posted to your website like clockwork.
   </InfoTile>
   <InfoTile role="region" aria-labelledby="Tonal Flexibility">
    <FlexIcon aria-hidden="true" />
    <InfoTileAccent>Tonal Flexibility</InfoTileAccent> Choose your preferred
    tone of voice for the text and style for the images. Personalize your
    content to match your brand.
   </InfoTile>
   <InfoTile role="region" aria-labelledby="Multilingual Support">
    <TranslateIcon aria-hidden="true" />
    <InfoTileAccent>Multilingual Support</InfoTileAccent> Broad language
    support, with {allLanguages.length} languages currently available.
   </InfoTile>
   <InfoTile role="region" aria-labelledby="Dynamic Content Options">
    <ModelsIcon aria-hidden="true" />
    <InfoTileAccent>Dynamic Content Options</InfoTileAccent> Elevate your posts
    with new elements: FAQs, comparative analyses, book references, and audio
    transformations for a richer narrative experience.
   </InfoTile>
   <InfoTile role="region" aria-labelledby="Budget-Friendly">
    <HeartIcon aria-hidden="true" />
    <InfoTileAccent>Budget-Friendly</InfoTileAccent> Seriously affordable, with
    prices starting from just $0.1 per article. This includes an image, HTML,
    simple text, or direct posting to your website.
   </InfoTile>
   <InfoTile role="region" aria-labelledby="Integration">
    <WPIcon aria-hidden="true" />
    <InfoTileAccent>Integration</InfoTileAccent> Currently supports WordPress,
    WIX and sitemap. API and other platform integrations coming soon. Manual
    copy-paste of HTML or TXT is always an available option.
   </InfoTile>
  </InfoTilesContainer>
 );
}

export default InfoTiles;
