import { BackgroundContainer } from "../Common.styled";
import { FAQContainer, FAQTitle } from "./FAQ.styled";

function FAQ({ faq = [] }) {
 return (
  <BackgroundContainer>
   <FAQContainer>
    <FAQTitle>Frequently Asked Questions</FAQTitle>
    {faq.map((el) => (
     <div key={el.question}>
      <h3 dangerouslySetInnerHTML={{ __html: el.question }}></h3>
      <p dangerouslySetInnerHTML={{ __html: el.answer }}></p>
     </div>
    ))}
    <div>
     <h3>Have More Questions?</h3>
     <p>
      If you didn't find the answer you were looking for in our FAQ, don't
      hesitate to reach out to us. The Seoll-e team is always here to assist you
      with any additional queries or feedback. Your success in SEO and content
      creation is our priority.
     </p>
    </div>
    <p>
     Warm regards, <br />
     The Seoll-e Team
    </p>
   </FAQContainer>
  </BackgroundContainer>
 );
}

export default FAQ;
