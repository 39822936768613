import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 query: "",
 config: {
  topic: true,
  keywords: true,
  info: true,
 },
};

const searchSlice = createSlice({
 name: "search",
 initialState,
 reducers: {
  setSearchQuery: (state, action) => {
   state.query = action.payload;
  },
  setSearchConfig: (state, action) => {
   state.config = { ...action.payload };
  },
  resetSearchQuery: (state) => {
   state.query = "";
   state.config = {
    topic: true,
    keywords: true,
    info: true,
   };
  },
 },
});

export const { setSearchQuery, setSearchConfig, resetSearchQuery } =
 searchSlice.actions;

export default searchSlice.reducer;
