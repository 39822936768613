import { css } from "styled-components";

const sizes = {
 mobile: 375,
 largeMobile: 480,
 tablet: 768,
 laptop: 1280,
 desktop: 1500,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
 acc[label] = (...args) => css`
  @media (min-width: ${sizes[label]}px) {
   ${css(...args)};
  }
 `;
 return acc;
}, {});
