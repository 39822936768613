import Notiflix from "notiflix";
import axios from "../../utils/api/axios-config";
import {
 getImageSettingsSuccess,
 getAudioSettingsSuccess,
 getSettingsError,
 getSettingsStart,
 getSitemapSettingsSuccess,
 getTextSettingsSuccess,
 getWixSettingsSuccess,
 getWordpressSettingsSuccess,
} from "./settingsSlice";

//get

export const getTextSettings = () => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 try {
  const response = await axios.get("posts/text-settings/", {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
   },
  });
  dispatch(getTextSettingsSuccess(response.data));
 } catch (e) {
  dispatch(getSettingsError(e.message));
 }
};

export const getSitemapSettings = () => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 try {
  const response = await axios.get("posts/sitemap/", {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
   },
  });
  dispatch(getSitemapSettingsSuccess(response.data));
 } catch (e) {
  dispatch(getSettingsError(e.message));
 }
};

export const getWordpressSettings = () => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 try {
  const response = await axios.get("posts/wordpress-settings/", {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
   },
  });
  localStorage.setItem("wordpressSettings", JSON.stringify(response.data));
  dispatch(getWordpressSettingsSuccess(response.data));
 } catch (e) {
  dispatch(getSettingsError(e.message));
 }
};

export const getWixSettings = () => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 try {
  const response = await axios.get("posts/wix-settings/", {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
   },
  });
  dispatch(getWixSettingsSuccess(response.data));
 } catch (e) {
  dispatch(getSettingsError(e.message));
 }
};

export const getImgSettings = () => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 try {
  const response = await axios.get("posts/image-settings/", {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
   },
  });
  dispatch(getImageSettingsSuccess(response.data));
 } catch (e) {
  dispatch(getSettingsError(e.message));
 }
};

export const getAudioSettings = () => async (dispatch) => {
    dispatch(getSettingsStart());
    const token = localStorage.getItem("access");
    try {
     const response = await axios.get("posts/audio-settings/", {
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${token}`,
      },
     });
     dispatch(getAudioSettingsSuccess(response.data));
    } catch (e) {
     dispatch(getSettingsError(e.message));
    }
   };

//put

export const putTextSettings = (settings) => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 if (token) {
  try {
   const response = await axios.put("posts/text-settings/", settings, {
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${token}`,
    },
   });
   dispatch(getTextSettingsSuccess(response.data));
  } catch (e) {
   dispatch(getSettingsError(e.message));
  }
 }
};

export const putSitemapSettings = (settings) => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 if (token) {
  try {
   const response = await axios.patch("posts/sitemap/", settings, {
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${token}`,
    },
   });
   dispatch(getSitemapSettingsSuccess(response.data));
  } catch (e) {
   Notiflix.Notify.failure("Sitemap not found");
   dispatch(getSettingsError(e.detail));
  }
 }
};

export const postSitemapSettings = (settings) => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 if (token) {
  try {
   const response = await axios.post("posts/sitemap/", settings, {
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${token}`,
    },
   });
   dispatch(getSitemapSettingsSuccess(response.data));
  } catch (e) {
   Notiflix.Notify.failure("Sitemap not found");
   dispatch(getSettingsError(e.message));
  }
 }
};

export const putImgSettings = (settings) => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 if (token) {
  try {
   const response = await axios.put("posts/image-settings/", settings, {
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${token}`,
    },
   });
   dispatch(getImageSettingsSuccess(response.data));
  } catch (e) {
   dispatch(getSettingsError(e.message));
  }
 }
};

export const putAudioSettings = (settings) => async (dispatch) => {
    dispatch(getSettingsStart());
    const token = localStorage.getItem("access");
    if (token) {
     try {
      const response = await axios.put("posts/audio-settings/", settings, {
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
       },
      });
      dispatch(getAudioSettingsSuccess(response.data));
     } catch (e) {
      dispatch(getSettingsError(e.message));
     }
    }
   };

export const putWordpressSettings =
 (settings, showNotification = true) =>
 async (dispatch) => {
  dispatch(getSettingsStart());

  const token = localStorage.getItem("access");

  if (token) {
   const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
   };

   let message = {
    connection: "",
    update: "",
   };

   try {
    let enable = settings.enable;
    let is_ok = settings.way === 2;
    let connection;

    if (
     settings.way === 1 &&
     settings.wp_url &&
     settings.wp_password &&
     settings.wp_username
    ) {
     connection = await axios.post(
      "posts/wordpress/check-connection/",
      settings,
      { headers }
     );
     enable = connection.data.success ? settings.enable : 0;
     is_ok = settings.wp_url ? connection.data.success : false;
     message.connection = "Connected successfully";
    }
    try {
     const data = {
      ...settings,
      is_ok,
      enable,
      authors: settings.authors ? settings.authors : "load",
     };
     if (enable === undefined) {
      delete data.enable;
     }
     const response = await axios.put("posts/wordpress-settings/", data, {
      headers,
     });
     dispatch(getWordpressSettingsSuccess(response.data));
     message.update = "Settings updated successfully";
    } catch (e) {
     dispatch(
      getWordpressSettingsSuccess({
       ...settings,
       is_ok,
       enable,
      })
     );
     if (showNotification && settings.way === 1) {
      message.update = e.response.data.wp_url[0];
      dispatch(getSettingsError(e.response.data.wp_url[0]));
     }
    }
    if (showNotification && settings.way === 1 && connection) {
     message.connection = connection.data.message;
    }
   } catch (e) {
    if (showNotification && settings.way === 1) {
     message.connection = e.message;
    }
    dispatch(
     getWordpressSettingsSuccess({ ...settings, enable: 0, is_ok: false })
    );
    dispatch(getSettingsError());
   }

   if (showNotification) {
    if (message.connection) {
     Notiflix.Notify.info(message.connection);
    } else {
     Notiflix.Notify.info(message.update);
    }
   }
  }
 };

export const putWixSettings = (settings) => async (dispatch) => {
 dispatch(getSettingsStart());
 const token = localStorage.getItem("access");
 if (token) {
  try {
   await axios.put("posts/wix-settings/", settings, {
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${token}`,
    },
   });
   dispatch(getWixSettingsSuccess(settings));
  } catch (e) {
   dispatch(getSettingsError(e.message));
  }
 }
};
