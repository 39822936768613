import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 isLoading: false,
 error: "",
 imageSettings: {},
 audioSettings: {},
 textSettings: {},
 wordpressSettings: {},
 wixSettings: {},
 sitemapSettings: {},
};

const settingsSlice = createSlice({
 name: "settings",
 initialState,
 reducers: {
  getSettingsStart: (state) => {
   state.isLoading = true;
  },
  getImageSettingsSuccess: (state, action) => {
   state.isLoading = false;
   state.imageSettings = { ...state.imageSettings, ...action.payload };
   state.error = "";
  },
  getAudioSettingsSuccess: (state, action) => {
    state.isLoading = false;
    state.audioSettings = { ...state.audioSettings, ...action.payload };
    state.error = "";
   },
  
  getTextSettingsSuccess: (state, action) => {
   state.isLoading = false;
   state.textSettings = { ...state.textSettings, ...action.payload };
   state.error = "";
  },
  getSitemapSettingsSuccess: (state, action) => {
   state.isLoading = false;
   state.sitemapSettings = { ...state.sitemapSettings, ...action.payload };
   state.error = "";
  },
  getWordpressSettingsSuccess: (state, action) => {
   state.isLoading = false;
   state.wordpressSettings = { ...state.wordpressSettings, ...action.payload };
   state.error = "";
  },
  getWixSettingsSuccess: (state, action) => {
   state.isLoading = false;
   state.wixSettings = { ...state.wixSettings, ...action.payload };
   state.error = "";
  },
  getSettingsError: (state, action) => {
   state.isLoading = false;
   state.error = action.payload;
  },
 },
});

export const {
 getSettingsStart,
 getTextSettingsSuccess,
 getImageSettingsSuccess,
 getAudioSettingsSuccess,
 getWordpressSettingsSuccess,
 getWixSettingsSuccess,
 getSitemapSettingsSuccess,
 getSettingsError,
} = settingsSlice.actions;

export default settingsSlice.reducer;
