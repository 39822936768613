import { videos } from "../../data/mainPageVideos";
import {
 IFrameContainer,
 PlayIcon,
 VideosContainer,
 VideosDescription,
 Youtube,
} from "./Videos.styled";

function Videos() {
 const randomVideo = videos[[Math.floor(Math.random() * videos.length)]];

 return (
  <VideosContainer>
   <VideosDescription>
    <PlayIcon />
    <span>{randomVideo.title}</span>
    {randomVideo.description}
   </VideosDescription>
   <IFrameContainer>
    <Youtube
     src={randomVideo.link}
     title="YouTube video player"
     frameborder="0"
     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
     allowfullscreen
    ></Youtube>
   </IFrameContainer>
  </VideosContainer>
 );
}

export default Videos;
