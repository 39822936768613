import { useState } from "react";
import {
 CloseIcon,
 ModalContainer,
 ModalOverflow,
 OutlinedBtn,
} from "../../Common.styled";
import {
 FeedbackForm,
 FeedbackText,
 FeedbackTextTitle,
 Star,
 StarRating,
 TextInput,
} from "./Feedback.styled";
import Notiflix from "notiflix";
import { sendFeedback } from "../../../utils/api/emails";
import { useDispatch, useSelector } from "react-redux";
import { hideForm } from "../../../store/feedback/feedbackSlice";

function Feedback() {
 const [rating, setRating] = useState(0);
 const [feedback, setFeedback] = useState("");
 const dispatch = useDispatch();
 const showForm = useSelector((state) => state.feedback.showForm);

 const handleClose = () => dispatch(hideForm());

 const handleSubmit = async (e) => {
  e.preventDefault();
  await sendFeedback({ message: feedback, rating });
  handleClose();
  Notiflix.Report.success(
   "Thank you for your feedback!",
   "You will receive additional credits soon"
  );
 };

 return (
  <>
   {showForm && (
    <ModalOverflow
     onClick={(e) => {
      if (e.target === e.currentTarget) handleClose();
     }}
    >
     <ModalContainer>
      <FeedbackForm onSubmit={handleSubmit}>
       <FeedbackTextTitle>Help us get better!</FeedbackTextTitle>
       <FeedbackText>
        Enjoyed our app? Share your experience! Fill out our quick feedback form
        and earn 100 free credits.
       </FeedbackText>
       <StarRating>
        {[...Array(5)].map((_, i) => (
         <Star key={i} selected={i < rating} onClick={() => setRating(i + 1)}>
          ★
         </Star>
        ))}
       </StarRating>
       <TextInput
        placeholder="Your feedback goes here..."
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
       />
       <OutlinedBtn type="submit">Submit</OutlinedBtn>
      </FeedbackForm>
      <CloseIcon onClick={handleClose} />
     </ModalContainer>
    </ModalOverflow>
   )}
  </>
 );
}

export default Feedback;
